import { ParagraphText } from '@thryvlabs/maverick'

import NavBar from '../../components/nav-bar/nav-bar'
import { SyncedColumn, SyncedRow } from '../../style/clover-style'
import { Container } from '../../style/containers-style'

const CloverSynced = () => (
  <>
    <NavBar title="Clover" />
    <Container pb="9rem">
      <SyncedRow>
        <SyncedColumn>
          <ParagraphText variant="lg">
            Your Clover app is installed. If you would like to uninstall it,
            please navigate to your Clover account. For assistance and guidance,
            please reference our Clover guide{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://learn.thryv.com/hc/en-us/articles/18110403438093-Clover"
            >
              here
            </a>
            .
          </ParagraphText>
        </SyncedColumn>
      </SyncedRow>
    </Container>
  </>
)

export default CloverSynced
