import { useNavigate } from 'react-router-dom'

import hubSpotLogo from '../../assets/hubSpot-logo.svg'
import LandingPage from '../../components/landing-page/landing-page'
import { appIconStyle } from '../../style/app-icon-style'

const HubSpot = () => {
  const appName = 'HubSpot'
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/hubspot-settings')
  }
  const hubSpotSections = [
    {
      header: 'What will this application need access to?',
      statements: ['Your User details', 'Client details', 'Estimate details']
    },

    {
      header: 'What will this application do?',
      statements: [
        'Sync current Clients between Thryv and HubSpot.',
        'Sync future Clients between Thryv and HubSpot.',
        'Sync current Estimates between Thryv and HubSpot.',
        'Sync future Estimates between Thryv and HubSpot.'
      ]
    }
  ]

  return (
    <LandingPage
      sections={hubSpotSections}
      onSubmit={handleClick}
      appName={appName}
      appIconSource={hubSpotLogo}
      appIconStyle={appIconStyle}
      isMobileStyling={true}
    />
  )
}

export default HubSpot
