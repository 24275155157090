import { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import instagramLogo from '../../assets/instagram-logo.png'
import LandingPage from '../../components/landing-page/landing-page'
import { appIconStyle } from '../../style/app-icon-style'
import { authClient } from '../../util/auth-client'

export function Instagram() {
  const { user } = useAuth0()
  const navigate = useNavigate()
  const axiosClient = authClient()

  const handleClick = e => {
    e.preventDefault()
    navigate('/instagram-next')
  }

  //Checking if users already has the application installed
  useEffect(() => {
    async function getIntegrationStatus() {
      try {
        const { data } = await axiosClient(
          `/api/instagram/integration-status/${user.businessId}`
        )
        if (data.hasInstagramIntegration) {
          navigate('/instagram-next-uninstall')
        }
      } catch (err) {
        toast.error(
          'Something went wrong while checking your installation status. If the problem persists please contact Thryv support.'
        )
      }
    }
    getIntegrationStatus()
  }, [])

  const instagramSections = [
    {
      header: 'What will this application do?',
      statements: [
        'Link your selected Instagram Business Pages to your Thryv account.',
        'Receive and respond to messages received from your Instagram Business Page.',
        'Receive status updates for messages sent and received.'
      ]
    }
  ]

  return (
    <>
      <ToastContainer />

      <LandingPage
        sections={instagramSections}
        onSubmit={handleClick}
        appName="Instagram"
        appIconSource={instagramLogo}
        appIconStyle={appIconStyle}
      />
    </>
  )
}
