import { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import TwilioSettings from './TwilioSettings'
import TwilioSpamFilter from './TwilioSpamFilter'
import UninstallSuccessModal from '../../components/modals/uninstall-success-modal'
import NavBar from '../../components/nav-bar/nav-bar'
import { authClient } from '../../util/auth-client'

const UNINSTALL_MESSAGE = `Uninstalling the Twilio App will delete all existing queued 
entries and they will be unrecoverable. Please clear your queue first if you still have 
notifications you would like to approve.`
const BUTTON_TEXT = `Spam Filter`

const TwilioNext = () => {
  const [isUninstalled, setIsUninstalled] = useState(false)
  const [showTwilioSettings, setShowTwilioSettings] = useState(true)
  const [showSpamFilter, setShowSpamFilter] = useState(false)
  const [showSettingsButton, setShowSettingsButton] = useState(false)
  const [showSpamFilterButton, setShowSpamFilterButton] = useState(false)

  const { state } = useLocation()
  const axiosClient = authClient()

  const { twilioUser = {} } = state || {}
  const thryvId = twilioUser.thryvId

  const goToSettingsPage = () => {
    setShowTwilioSettings(true)
    setShowSpamFilter(false)
    setShowSpamFilterButton(true)
    setShowSettingsButton(false)
  }

  const goToSpamFilterPage = () => {
    setShowSpamFilter(true)
    setShowTwilioSettings(false)
    setShowSettingsButton(true)
    setShowSpamFilterButton(false)
  }

  const uninstallTwilio = async () => {
    try {
      await axiosClient({
        method: 'DELETE',
        url: `/api/twilio/users/${thryvId}`
      })
      setIsUninstalled(true)
    } catch (err) {
      toast.error(`
        Something went wrong while uninstalling Twilio. 
        Please contact Thryv Support if the issue persists.
      `)
    }
  }

  // only display the spam filter page for users who have completed the integration
  // and selected "Yes" for "Would you like to enable the Spam Filter?"
  useEffect(() => {
    if (twilioUser.userHasIntegration && twilioUser.quarantine === 1) {
      setShowSpamFilter(true)
      setShowSettingsButton(true)
      setShowTwilioSettings(false)
    }
  }, [])

  return (
    <>
      <ToastContainer />
      <NavBar
        title={'Twilio'}
        showButton={
          twilioUser.userHasIntegration ||
          showSpamFilter ||
          showSpamFilterButton
        }
        handleUninstall={uninstallTwilio}
        handleSettingsClick={goToSettingsPage}
        handleSpamFilterClick={goToSpamFilterPage}
        showSettingsButton={showSettingsButton}
        showSpamFilterButton={showSpamFilterButton}
        customMessage={UNINSTALL_MESSAGE}
        buttonText={BUTTON_TEXT}
      />
      {showTwilioSettings && (
        <TwilioSettings
          twilioUser={twilioUser}
          setShowSettingsButton={setShowSettingsButton}
          setShowTwilioSettings={setShowTwilioSettings}
          showSpamFilter={showSpamFilter}
          setShowSpamFilter={setShowSpamFilter}
          setShowSpamFilterButton={setShowSpamFilterButton}
        />
      )}
      {showSpamFilter && <TwilioSpamFilter twilioUser={twilioUser} />}
      <UninstallSuccessModal app="Twilio" shouldShow={isUninstalled} />
    </>
  )
}

export default TwilioNext
