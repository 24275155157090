import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Button, Form, Header, Input } from '@thryvlabs/maverick'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import NavBar from '../../components/nav-bar/nav-bar'
import {
  ErrorText,
  FlexCol,
  FormContainer,
  InputContainer,
  Wrapper
} from '../../style/clover-style'
import { authClient } from '../../util/auth-client'

const CloverRegister = () => {
  const { user } = useAuth0()
  const navigate = useNavigate()
  const axiosClient = authClient()

  const [merchantName, setMerchantName] = useState('')
  const [merchantId, setMerchantId] = useState('')
  const [merchantNameError, setMerchantNameError] = useState('')
  const [merchantIdError, setMerchantIdError] = useState('')

  const handleSignUp = async () => {
    setMerchantNameError('')
    setMerchantIdError('')

    if (!validateInput()) {
      return
    }

    try {
      await axiosClient({
        method: 'post',
        url: '/api/clover/register-merchant',
        data: {
          merchantName,
          merchantId,
          thryvId: user.businessId
        }
      })
      navigate('/clover-registered')
    } catch (error) {
      toast.error(
        'An error occurred registering Clover. Please contact Thryv Support if the issue persists.'
      )
    }
  }

  const validateInput = () => {
    let valid = true
    if (!merchantName) {
      setMerchantNameError('Please provide your Merchant Name')
      valid = false
    }

    if (!merchantId) {
      setMerchantIdError('Please provide your Merchant ID')
      valid = false
    }

    return valid
  }

  return (
    <>
      <NavBar title="Clover" />
      <Wrapper>
        <ToastContainer />
        <Header fontWeight="normal" variant="h3">
          Want to sign up for a Thryv Clover integration?
        </Header>
        <FormContainer>
          <Form>
            {() => (
              <FlexCol>
                <InputContainer>
                  <Input
                    type="text"
                    withLabel
                    name="merchant name"
                    placeholder="Merchant Name"
                    labelType="floating"
                    variant="input-isolated"
                    value={merchantName}
                    onChange={e => setMerchantName(e.target.value)}
                  />
                </InputContainer>
                {!!merchantNameError && (
                  <ErrorText>{merchantNameError}</ErrorText>
                )}
                <InputContainer>
                  <Input
                    type="text"
                    withLabel
                    name="merchant id"
                    placeholder="Merchant ID"
                    labelType="floating"
                    variant="input-isolated"
                    value={merchantId}
                    onChange={e => setMerchantId(e.target.value)}
                  />
                </InputContainer>
                {!!merchantIdError && <ErrorText>{merchantIdError}</ErrorText>}
              </FlexCol>
            )}
          </Form>
          <Button variant="primary" onClick={handleSignUp}>
            Sign Up
          </Button>
        </FormContainer>
      </Wrapper>
    </>
  )
}

export default CloverRegister
