import styled from '@emotion/styled'

// TwilioSettings.js
export const SettingsContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding-bottom: 12px;
  padding-top: 12px;
  margin: 12px;
  max-width: 100%;
  flex: 0 0 66.66667%;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 768px) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
`
export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;

  @media (max-width: 768px) {
    width: 95%;
  }
`
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  & button {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
`
export const TwilioParagraphSection = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;

  .section-title {
    font-weight: bold;
  }
`
export const ListContainer = styled.div`
  width: 75%;
  padding-left: 1.25rem;

  @media (max-width: 768px) {
    width: 95%;
    padding-left: 0;
  }
`
export const UnorderedList = styled.ul`
  padding-left: 1.25rem;
  list-style-type: disc;
`

// TwilioSpamFilter.js
export const SubmitButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`
export const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
`
export const MediumContainer = styled.div`
  width: 75%;

  @media (max-width: 768px) {
    width: 95%;
  }
`
export const MenuContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: ${props => `${props.widthPercent}%`};

  & h6 {
    color: #808080;
    font-size: 14px;
  }
`

// tokens-inputs.js
export const TokensInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & h1 {
    font-size: 36px;
    font-weight: 600;
    color: #292a2e;
  }

  & input {
    width: 250px;
  }

  & > div {
    padding-bottom: 1.5rem;
  }

  & button {
    margin-bottom: 2rem;
  }
`
export const InputRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
  width: 50%;

  .label-text {
    white-space: nowrap;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    width: 95%;
    flex-direction: column;
    align-items: center;
  }
`

// update-tokens-modal.js, edit-email-modal.js, edit-name-modal.js
export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  button {
    text-transform: capitalize !important;
  }

  & button[type='button'] {
    text-transform: capitalize !important;
  }
`
export const ModalTextBox = styled.div`
  padding: 0.5rem 1rem;
`
export const ModalInputContainer = styled.div`
  padding: 1rem;

  & input {
    width: 100%;
  }
`
export const TitleInputContainer = styled.div`
  padding: 1rem 2rem;

  & input {
    width: 105%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`
export const InputContainer = styled.div`
  padding-bottom: 1rem;
  padding-top: 1rem;
`

// spam-filter-table.js
export const FieldContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: ${props => props.width};
`
export const TextBox = styled.span`
  font-size: 14px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
`
export const MessageScrollBox = styled.div`
  height: 120px;
  width: 80%;
  border: 1px solid #ccc;
  overflow: auto;

  & span {
    font-size: 12px;
  }
`
export const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`

// spam-filter-table mobile
export const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin: 2vw;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`

export const CardHeader = styled.div`
  background-color: #f7f7f7;
  padding: 2vh;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h2 {
    margin: 0;
    color: #333;
  }
`

export const CardContent = styled.div`
  padding: 2vh;
`

export const CardField = styled.div`
  display: flex;
  gap: 5px;
  padding: 0.5vh;
`
