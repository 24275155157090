function compareTimes(time1, modifier1, time2, modifier2) {
  let [hours1, minutes1] = time1.split(':').map(Number)
  let [hours2, minutes2] = time2.split(':').map(Number)

  if (modifier1 === 'PM' && hours1 !== 12) {
    hours1 += 12
  } else if (modifier1 === 'AM' && hours1 === 12) {
    hours1 = 0
  }

  if (modifier2 === 'PM' && hours2 !== 12) {
    hours2 += 12
  } else if (modifier2 === 'AM' && hours2 === 12) {
    hours2 = 0
  }

  if (hours1 === hours2) {
    return minutes1 < minutes2
  } else {
    return hours1 < hours2
  }
}

export { compareTimes }
