import { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import googleLogo from '../../assets/google-logo.png'
import LandingPage from '../../components/landing-page/landing-page'
import { appIconStyle } from '../../style/app-icon-style'
import { authClient } from '../../util/auth-client'

const { REACT_APP_URL } = process.env

export default function GBM() {
  const { user } = useAuth0()
  const navigate = useNavigate()
  const axiosClient = authClient()

  const handleClick = e => {
    e.preventDefault()
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/businesscommunications https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https%3A//www.googleapis.com/auth/business.manage&access_type=offline&prompt=consent&include_granted_scopes=true&response_type=code&redirect_uri=${REACT_APP_URL}/gbm-next&client_id=${process.env.REACT_APP_GBM_CLIENT_ID}`
  }

  useEffect(() => {
    async function getInstalledStatus() {
      const url = `/api/gbm/installed-status/${user.businessId}`
      try {
        const { data } = await axiosClient(url)
        if (data.isInstalled) {
          navigate('/gbm-uninstall')
        }
      } catch (err) {
        toast.error(
          `An error occurred checking the application's installation status. Please contact Thryv Support if this issue persists.`
        )
      }
    }
    getInstalledStatus()
  }, [])
  const googleSections = [
    {
      header: 'What will this application do?',
      statements: [
        'Validate that your Google Business Page is linked to your Thryv account.',
        'Inject Google Messenger messages into your Thryv account.'
      ]
    }
  ]
  return (
    <>
      <ToastContainer />
      <LandingPage
        sections={googleSections}
        onSubmit={handleClick}
        appName="Google"
        appIconSource={googleLogo}
        appIconStyle={appIconStyle}
      />
    </>
  )
}
