import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { IntegryJS } from '@integry/sdk'
import { ParagraphText } from '@thryvlabs/maverick'
import { toast, ToastContainer } from 'react-toastify'

import { Col, Row, WrapperContainer } from '../../style/integry-settings-style'
import { authClient } from '../../util/auth-client'
import NavBar from '../nav-bar/nav-bar'

const IntegrySettings = ({ appName, appKey, appId }) => {
  const [integry, setIntegry] = useState()
  const [authorizationId, setAuthorizationId] = useState('')
  const [showButton, setShowButton] = useState(true)
  const [hash, setHash] = useState()
  const {
    user: { businessId: thryvId, staffUID }
  } = useAuth0()
  const axiosClient = authClient()

  const { REACT_APP_ENV } = process.env

  const appNameFormatted = appName.toLowerCase().split(' ').join('_')
  const baseURL = '/api/integry'

  const payload = {
    integration: appNameFormatted,
    thryvId
  }

  function initializeIntegry() {
    integry.init({
      containerId: 'x-integry-container',
      renderMode: IntegryJS.RenderModes.INLINE,
      appId
    })

    integry.eventEmitter.on('did-add-authorization', async data => {
      setAuthorizationId(data.authorizationId)
      setShowButton(true)

      try {
        await axiosClient({
          method: 'post',
          url: `${baseURL}/subscribe`,
          data: payload
        })
      } catch (err) {
        toast.error(
          `An error occured while trying to subscribe to ${appName}. Please contact the support team.`
        )
      }
    })

    integry.eventEmitter.on('did-app-load', data => {
      if (data.authorizationId) {
        setAuthorizationId(data.authorizationId)
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    })

    integry.eventEmitter.on('did-remove-authorization', async () => {
      try {
        await axiosClient({
          method: 'delete',
          url: `${baseURL}/unsubscribe/${thryvId}/${appNameFormatted}`
        })
      } catch (err) {
        toast.error(
          `An error occured while trying to unsubscribe from ${appName}. Please contact the support team.`
        )
      }
    })
  }

  function handleUninstall() {
    integry.removeAuthorization({ authorizationId })
    setShowButton(false)
  }

  useEffect(() => {
    async function getHash() {
      try {
        const { data } = await axiosClient(`${baseURL}/hash/${staffUID}`)
        setHash(data.hash)
      } catch (err) {
        toast.error('Something went wrong. Please contact the support team.')
      }
    }
    getHash()
  }, [])

  useEffect(() => {
    if (hash) {
      setIntegry(
        new IntegryJS({
          appKey,
          userId: staffUID,
          hash,
          xIntegryConfig: {
            appAuth: {
              apiKey: thryvId,
              extras: {
                directory: REACT_APP_ENV === 'production' ? '7' : '1'
              }
            }
          },
          options: {
            tags: ['Published']
          }
        })
      )
    }
  }, [hash])

  useEffect(() => {
    if (integry) {
      initializeIntegry()
    }
  }, [integry])

  return (
    <WrapperContainer>
      <NavBar
        title={appName}
        showButton={showButton}
        handleUninstall={handleUninstall}
      />
      <Row>
        <Col>
          <div className="App-embed" id="x-integry-container" />
        </Col>
      </Row>
      <Row>
        <Col className="settings-footer">
          <ParagraphText variant="reg">
            Finished configuring your integration?
          </ParagraphText>
          <ParagraphText variant="reg">
            You can close this window at any time and return to Thryv.
          </ParagraphText>
        </Col>
      </Row>
      <ToastContainer />
    </WrapperContainer>
  )
}

export default IntegrySettings
