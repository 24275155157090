import { useState, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { ParagraphText as Text, Icon, Button } from '@thryvlabs/maverick'
import { toast, ToastContainer } from 'react-toastify'

import gmailLogo from '../../assets/gmail-logo.png'
import thryvLogo from '../../assets/thryvappSM.png'
import { LoaderSpinner } from '../../components/loader-spinner'
import { gmailIconStyle } from '../../style/app-icon-style'
import { ButtonContainer } from '../../style/gmail-style'
import {
  LogoContainer,
  HeaderContainer,
  AppLogo,
  MainContainer,
  ThryvLogo
} from '../../style/landing-page-style'
import { authClient } from '../../util/auth-client'

const baseURL = '/api/gmail'

const GMailAddon = () => {
  const { user } = useAuth0()
  const axiosClient = authClient()

  const [installOrUninstall, setInstallOrUninstall] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function getUserStatus() {
      try {
        const { data } = await axiosClient(
          `${baseURL}/users/${user.businessId}`
        )
        if (data.userExists) {
          setInstallOrUninstall('uninstall')
        } else {
          setInstallOrUninstall('install')
        }
      } catch (err) {
        toast.error(
          'Failed to retrieve your installation status. Please contact Thryv Support if this issue persists.'
        )
      }
    }
    getUserStatus()
  }, [])

  const handleOnClick = async () => {
    setIsLoading(true)

    let axiosClientObj
    if (installOrUninstall === 'install') {
      axiosClientObj = {
        method: 'post',
        url: `${baseURL}/install`,
        data: { thryvId: user.businessId }
      }
    } else {
      axiosClientObj = {
        method: 'delete',
        url: `${baseURL}/uninstall/${user.businessId}`
      }
    }

    try {
      const { data } = await axiosClient(axiosClientObj)
      window.location.href = data.href
    } catch (err) {
      setIsLoading(false)
      toast.error(
        `Unable to reach GMail's page to ${installOrUninstall} the app. Please contact Thryv Support if this issue persists.`
      )
    }
  }

  return (
    <>
      <ToastContainer />
      {isLoading && <LoaderSpinner />}
      <MainContainer>
        <HeaderContainer>
          <LogoContainer>
            <ThryvLogo src={thryvLogo} data-testid="thryv-logo" />
            <Icon type="solid" variant="plus" />
            <AppLogo
              src={gmailLogo}
              data-testid="app-logo"
              appIconStyle={gmailIconStyle}
            />
          </LogoContainer>
          <Text variant="lg">
            You are choosing to {installOrUninstall} the Thryv Gmail Addon.
          </Text>
          <Text variant="lg">
            After clicking {installOrUninstall} you will be redirected to the
            Google Workspace Marketplace to complete the {installOrUninstall}.
          </Text>
        </HeaderContainer>
        <ButtonContainer>
          <Button
            variant="primary"
            onClick={handleOnClick}
            data-testid="button"
            disabled={!installOrUninstall}
          >
            {installOrUninstall.toUpperCase()}
          </Button>
        </ButtonContainer>
      </MainContainer>
    </>
  )
}
export default GMailAddon
