import { useAuth0 } from '@auth0/auth0-react'
import { Button, Header, Icon, ParagraphText } from '@thryvlabs/maverick'

import {
  ContactHeaderContainer,
  ContactPageGridContainer,
  ContactPageGridItem,
  ContactSupportPage,
  IconStyle
} from '../../style/gbm-style'

const GBMNoSelection = () => {
  const { user } = useAuth0()

  const emailHref = `mailto:support@thryv.com?&subject=GMB Ownership Request&body=ThryvID: ${user.businessId}`
  const phoneHref = 'tel:8443396334'

  return (
    <ContactSupportPage>
      <ContactHeaderContainer>
        <Header fontWeight="semibold" variant="h1">
          Contact Thryv Support
        </Header>
      </ContactHeaderContainer>
      <ContactPageGridContainer>
        <ContactPageGridItem>
          <IconStyle>
            <Icon
              type="regular"
              variant="phone"
              height="36"
              width="36"
              data-testid="phone-icon"
            />
          </IconStyle>
          <Button
            variant="text"
            level={1}
            className="phone-number"
            onClick={() => (window.location.href = phoneHref)}
          >
            844-339-6334
          </Button>
        </ContactPageGridItem>
        <ContactPageGridItem>
          <IconStyle>
            <Icon
              type="regular"
              variant="envelope"
              height="36"
              width="36"
              data-testid="email-icon"
            />
          </IconStyle>
          <Button
            variant="primary"
            onClick={() => (window.location.href = emailHref)}
          >
            Send Email
          </Button>
          <ParagraphText variant="lg">
            * This email response could take up to 48 hours! *
          </ParagraphText>
        </ContactPageGridItem>
      </ContactPageGridContainer>
    </ContactSupportPage>
  )
}

export default GBMNoSelection
