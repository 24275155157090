import { useState } from 'react'

import { Input, Modal, ModalTitle } from '@thryvlabs/maverick'

import {
  TitleInputContainer,
  InputContainer,
  ModalContainer
} from '../../style/twilio-style'

export default function EditNameModal({ closeModal, onSave }) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const isNameValid = firstName && lastName

  return (
    <ModalContainer>
      <Modal
        variant="default"
        data-testid="edit-name-modal"
        altBtn={<></>}
        btnActionText="Save"
        btnAction={() => onSave({ firstName, lastName })}
        btnActionDisabled={!isNameValid}
        onClose={closeModal}
        openOnLoad
        action
        footerCancel
        hideX
        footer
      >
        <TitleInputContainer>
          <ModalTitle variant="subtitle" size="md">
            Edit Client
          </ModalTitle>
          <InputContainer>
            <Input
              withLabel
              labelType="floating"
              name="first-name"
              data-testid="first-name-input"
              type="text"
              value={firstName}
              onChange={event => setFirstName(event.target.value)}
              placeholder="First Name"
              variant="default"
              errorMessage="Field Required"
              isValid={firstName}
            />
            <Input
              withLabel
              labelType="floating"
              name="last-name"
              data-testid="last-name-input"
              type="text"
              value={lastName}
              onChange={event => setLastName(event.target.value)}
              placeholder="Last Name"
              variant="default"
              errorMessage="Field Required"
              isValid={lastName}
            />
          </InputContainer>
        </TitleInputContainer>
      </Modal>
    </ModalContainer>
  )
}
