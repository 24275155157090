import { useCallback, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { LoaderSpinner } from '../loader-spinner'

const UnauthenticatedApp = () => {
  const { loginWithRedirect } = useAuth0()

  const login = useCallback(async () => {
    const { search, pathname } = window.location
    const returnTo = `${pathname}${search}`

    await loginWithRedirect({ appState: { returnTo } })
  }, [loginWithRedirect])

  useEffect(() => {
    login()
  }, [login])

  return <LoaderSpinner />
}

export default UnauthenticatedApp
