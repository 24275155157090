import styled from '@emotion/styled'

export const InstagramJumbotron = styled.div`
  clear: both;
  padding-top: 0.5rem;
  text-align: center;
  margin-bottom: 1.25rem;
  margin-left: auto;
  margin-right: auto;

  & .login-name {
    font-weight: bold;
  }

  & .text-block {
    margin-top: 1rem;
  }
`
export const ProfileContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.3em;
`
export const InstructionsContainer = styled.div`
  margin-top: 3rem;
  display: inline-block;
  margin-bottom: 2rem;

  @media (min-width: 320px) {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
  }
  @media (min-width: 992px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
  }
  @media (min-width: 1200px) {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
  }
`
export const LoginContainer = styled.div`
  text-align: center;
`
export const OptionsBar = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  & .action-button {
    min-width: 8rem;
    height: 3rem;
    margin: 5rem;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    text-align: center;

    & .action-button {
      margin-top: 2rem;
      margin-bottom: 1rem;
      margin-left: auto;
      margin-right: auto;
      width: 5rem;
    }
  }
`
export const UninstallContainer = styled.div`
  display: grid;
  width: 100%;
  padding: 5rem 15px 5px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;

  & .uninstall-msg {
    margin-bottom: 2rem;
  }

  & .uninstall-thryv-guy {
    width: auto;
    max-height: 400px;
    margin: auto;
  }

  @media (max-width: 768px) {
    padding-top: 1rem;
  }
`
export const AccountTableContainer = styled.div`
  max-width: 60%;
  width: 100%;
  padding: 0 15px;
  margin: auto;

  @media (min-width: 576px) {
    max-width: 90%;
    padding: 0;
  }
  @media (min-width: 992px) {
    max-width: 90%;
    padding: 0;
  }
  @media (min-width: 1200px) {
    max-width: 70%;
    padding: 0;
  }
`
export const TableHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .column-name {
    font-size: 14px;
    flex-grow: 1;
  }
`
export const StyledText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-left: ${props => props.ml || '0'};
  margin-right: ${props => props.mr || '0'};
`

// mobile components
export const MobileContainer = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`
export const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin: 2vw;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`
export const CardHeader = styled.div`
  background-color: #f7f7f7;
  padding: 2vh;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h2 {
    margin: 0;
    color: #333;
  }
`
export const CardContent = styled.div`
  padding: 2vh;
`
export const CardField = styled.div`
  display: flex;
  gap: 5px;
  padding: 0.5vh;
`
export const TextBox = styled.span`
  font-size: 0.85rem;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    align-content: center;
  }
`
export const Logo = styled.img`
  height: 80px;
  padding: 0 1rem;
`
