import IntegrySettings from '../../components/integry/integry-settings'
import { appIdConfig } from '../../util/integry-app-id'

const GoogleSheetsSettings = () => {
  const appKey = process.env.REACT_APP_INTEGRY_APP_KEY
  const appId = appIdConfig.googleSheets

  return (
    <IntegrySettings appName="Google Sheets" appKey={appKey} appId={appId} />
  )
}
export default GoogleSheetsSettings
