import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import shopifyLogo from '../../assets/shopify-logo.png'
import LandingPage from '../../components/landing-page/landing-page'
import { LoaderSpinner } from '../../components/loader-spinner'
import { appIconStyle } from '../../style/app-icon-style'
import { authClient } from '../../util/auth-client'

const { REACT_APP_ENV } = process.env
const baseURL = '/api/shopify'

const Shopify = () => {
  const [checkingInstallStatus, setCheckingInstallStatus] = useState(false)
  const {
    user: { businessId: thryvId }
  } = useAuth0()
  const navigate = useNavigate()
  const axiosClient = authClient()

  const params = new URLSearchParams(window.location.search)
  const shopifyUsername = params.get('shop')
  const shopifyAccessToken = params.get('shop_access_token')

  useEffect(() => {
    async function getInstalledStatus() {
      setCheckingInstallStatus(true)
      try {
        const installationStatusURL = `${baseURL}/installation-status/${thryvId}`

        const {
          data: { isInstalled }
        } = await axiosClient(installationStatusURL)
        setCheckingInstallStatus(false)
        if (isInstalled === true) {
          navigate('/shopify-settings?status=existing')
        }
      } catch (err) {
        setCheckingInstallStatus(false)
        toast.error(
          `An error occurred checking Shopify's installation status. Please contact Thryv Support if this issue persists.`
        )
      }
    }
    getInstalledStatus()
  }, [])

  const handleClick = async e => {
    e.preventDefault()
    !(async () => {
      try {
        const payload = {
          thryvId,
          shopifyAccessToken,
          shopifyUsername,
          environment: REACT_APP_ENV
        }
        await axiosClient({
          method: 'post',
          url: `${baseURL}/install`,
          data: payload
        })

        navigate('/shopify-settings?status=new')
      } catch (err) {
        toast.error(
          'An error occurred while installing Shopify. Please contact Thryv support if the issue persists.'
        )
      }
    })()
  }

  const shopifySections = [
    {
      header: 'What will this application need access to?',
      statements: ['Customers.', 'Payments']
    },
    {
      header: 'What will this application do?',
      statements: [
        'Sync customers from Shopify to Thryv.',
        'Sync full payments from Shopify to Thryv.'
      ]
    }
  ]

  return (
    <>
      {checkingInstallStatus && <LoaderSpinner />}
      <ToastContainer />
      <LandingPage
        sections={shopifySections}
        onSubmit={handleClick}
        appName={'Shopify'}
        appIconSource={shopifyLogo}
        appIconStyle={appIconStyle}
      />
    </>
  )
}

export default Shopify
