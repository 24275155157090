import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Button, Header } from '@thryvlabs/maverick'
import { ToastContainer, toast } from 'react-toastify'

import UninstallSuccessModal from '../../components/modals/uninstall-success-modal'
import NavBar from '../../components/nav-bar/nav-bar'
import {
  GBMSelectionContainer,
  GBMSelectionButtonContainer,
  MainContainer
} from '../../style/gbm-style'
import { authClient } from '../../util/auth-client'

const { REACT_APP_THRYV_URL } = process.env

const GBMUninstall = () => {
  const [modalShow, setModalShow] = useState(false)
  const { user } = useAuth0()
  const axiosClient = authClient()

  const uninstallApp = async () => {
    try {
      await axiosClient({
        method: 'delete',
        url: `/api/gbm/uninstall/${user.businessId}`
      })
      setModalShow(true)
    } catch (err) {
      toast.error(
        'An error occurred while uninstalling Google Business Messages. Please contact Thryv Support if the issue persists.'
      )
    }
  }

  const redirectToThryv = () => {
    window.location.href = `${REACT_APP_THRYV_URL}/app`
  }

  return (
    <>
      <ToastContainer />
      <NavBar
        mobileFontSize={'5vw'}
        title={`Google Business Messages`}
        showButton={false}
      />
      <MainContainer>
        <GBMSelectionContainer>
          <Header fontWeight="medium" variant="h2">
            The application has already been installed. Would you like to
            uninstall?
          </Header>
          <GBMSelectionButtonContainer>
            <Button
              variant="primary"
              onClick={uninstallApp}
              data-testid="uninstall-button"
            >
              Uninstall
            </Button>
            <Button
              variant="primary"
              onClick={redirectToThryv}
              data-testid="redirect-button"
            >
              Go Back to Thryv
            </Button>
          </GBMSelectionButtonContainer>
          <UninstallSuccessModal
            app="Google Business Messages"
            shouldShow={modalShow}
          />
        </GBMSelectionContainer>
      </MainContainer>
    </>
  )
}

export default GBMUninstall
