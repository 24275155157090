import { Header } from '@thryvlabs/maverick'

import thryvExcitedGuy from '../../assets/thryvguy_excited.png'
import NavBar from '../../components/nav-bar/nav-bar'
import { RegisteredWrapper, Image } from '../../style/clover-style'

const CloverRegistered = () => (
  <>
    <NavBar title="Clover" />
    <RegisteredWrapper>
      <Header variant="h1" fontWeight="semibold">
        Congrats! You have registered for the Thryv Clover App. We are preparing
        your app and will send a notification to your Thryv inbox when it is
        ready for the next step. You may now close this screen.
      </Header>
      <Image src={thryvExcitedGuy} alt="Thryv Guy" />
    </RegisteredWrapper>
  </>
)

export default CloverRegistered
