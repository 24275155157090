import styled from '@emotion/styled'

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 10%;

  & .import-switch button[role='switch'][aria-checked='true'] {
    background-color: #ff5000;
  }

  & .import-switch button[role='switch']:disabled {
    background-color: #cccccc;
  }
`
