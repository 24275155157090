import { useNavigate } from 'react-router-dom'

import copperLogo from '../../assets/copperLogo.png'
import LandingPage from '../../components/landing-page/landing-page'
import { appIconStyle } from '../../style/app-icon-style'

const Copper = () => {
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/copper-settings')
  }
  const copperSections = [
    {
      header: 'What will this application need access to?',
      statements: ['Your User details', 'Client details', 'Leads details']
    },

    {
      header: 'What will this application do?',
      statements: [
        'Sync current Clients between Thryv and Copper',
        'Sync future Clients between Thryv and Copper',
        'Sync current Leads between Thryv and Copper',
        'Sync future Leads between Thryv and Copper'
      ]
    }
  ]

  return (
    <LandingPage
      sections={copperSections}
      onSubmit={handleClick}
      appName="Copper"
      appIconSource={copperLogo}
      appIconStyle={appIconStyle}
      isMobileStyling={true}
    />
  )
}

export default Copper
