import { useNavigate } from 'react-router-dom'

import insightlyLogo from '../../assets/insightly-logo.png'
import LandingPage from '../../components/landing-page/landing-page'
import { appIconStyle } from '../../style/app-icon-style'

const Insightly = () => {
  const appName = 'Insightly'
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/insightly-settings')
  }
  const insightlySections = [
    {
      header: 'What will this application need access to?',
      statements: ['Your User details', 'Client details']
    },

    {
      header: 'What will this application do?',
      statements: [
        'Sync current Clients between Thryv and Insightly CRM.',
        'Sync future Clients between Thryv and Insightly CRM.',
        'Sync Insightly CRM Leads to Thryv Clients.'
      ]
    }
  ]

  return (
    <LandingPage
      sections={insightlySections}
      onSubmit={handleClick}
      appName={appName}
      appIconSource={insightlyLogo}
      appIconStyle={appIconStyle}
      isMobileStyling={true}
    />
  )
}

export default Insightly
