import {
  Header,
  Table,
  Button,
  TableMenu,
  TableContent,
  TableRow
} from '@thryvlabs/maverick'

import {
  ErrorTableContainer,
  ErrorTableRow,
  TwoColumnTable,
  NoErrorsContianer,
  TableCell
} from '../../../style/myob-style'

function ErrorsTable({ errorsList, checkErrors }) {
  const tableColumns = [
    { key: 1, columnName: 'Platform' },
    { key: 2, columnName: 'Error' }
  ]

  return (
    <>
      <ErrorTableContainer>
        <ErrorTableRow>
          <div></div>
          <Header fontWeight="bold" variant="h1">
            Error List
          </Header>
          <Button
            variant="primary"
            onClick={() => {
              checkErrors()
            }}
          >
            Refresh
          </Button>
        </ErrorTableRow>
        <Table>
          <TableMenu>
            <TwoColumnTable>
              {tableColumns.map(column => (
                <div key={column.key}>
                  <h5>{column.columnName}</h5>
                </div>
              ))}
            </TwoColumnTable>
          </TableMenu>
          <TableContent>
            {errorsList?.length > 0 ? (
              <>
                {errorsList.map((error, index) => (
                  <TableRow key={index} variant="static">
                    <TwoColumnTable>
                      <TableCell>{error.platform}</TableCell>
                      <TableCell>{error.error}</TableCell>
                    </TwoColumnTable>
                  </TableRow>
                ))}
              </>
            ) : (
              <NoErrorsContianer>
                <Header fontWeight="light" variant="h5">
                  There are no errors to display.
                </Header>
              </NoErrorsContianer>
            )}
          </TableContent>
        </Table>
      </ErrorTableContainer>
    </>
  )
}

export default ErrorsTable
