import IntegrySettings from '../../components/integry/integry-settings'
import { appIdConfig } from '../../util/integry-app-id'

const HubSpotSettings = () => {
  return (
    <IntegrySettings
      appName="HubSpot"
      appKey={process.env.REACT_APP_INTEGRY_APP_KEY}
      appId={appIdConfig.hubSpot}
    />
  )
}
export default HubSpotSettings
