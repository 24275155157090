import { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import cloverLogo from '../../assets/clover.png'
import LandingPage from '../../components/landing-page/landing-page'
import { cloverIconStyle } from '../../style/app-icon-style'
import { authClient } from '../../util/auth-client'

const Clover = () => {
  const { user } = useAuth0()
  const navigate = useNavigate()
  const axiosClient = authClient()

  useEffect(() => {
    async function getImportStatus() {
      try {
        const { data } = await axiosClient(
          `/api/clover/users/import-status/${user.businessId}`
        )
        if (data.isImportDone) {
          navigate('/clover-synced')
        }
      } catch (err) {
        toast.error(
          'An error occurred while getting your import status. Please refresh the page, and contact Thryv Support if the issue persists.'
        )
      }
    }
    getImportStatus()
  }, [])

  const handleClick = e => {
    e.preventDefault()
    navigate('/clover-register')
  }
  const cloverSections = [
    {
      header: 'What will this application do?',
      statements: [
        'Sync current Clients between Thryv and Clover.',
        'Sync future Clients between Thryv and Clover.',
        'Sync current Payments between Thryv and Clover.',
        'Sync future Payments between Thryv and Clover.'
      ]
    }
  ]

  return (
    <>
      <LandingPage
        sections={cloverSections}
        onSubmit={handleClick}
        appName="Clover"
        appIconSource={cloverLogo}
        appIconStyle={cloverIconStyle}
      />
      <ToastContainer />
    </>
  )
}

export default Clover
