/* eslint-disable no-useless-escape */

import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  CopyToClipboard,
  Header,
  ParagraphText,
  Section
} from '@thryvlabs/maverick'
import { useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import InstallSuccessModal from '../../components/modals/install-success-modal'
import NavBar from '../../components/nav-bar/nav-bar'
import {
  WebChatCodeReferenceContainer,
  WebChatHeaderMessageContainer,
  WebChatNextContainer,
  WebChatOptionsContainer
} from '../../style/web-chat-style'
import { authClient } from '../../util/auth-client'
import { generateScript } from '../../util/webchat-script'

const { REACT_APP_THRYV_URL } = process.env

export default function WebChatNext() {
  const [modalShow, setModalShow] = useState(false)

  const {
    user: { businessId: thryvId }
  } = useAuth0()
  const axiosClient = authClient()
  const {
    state: { script }
  } = useLocation()

  const alertWebTeam = async () => {
    try {
      await axiosClient({
        method: 'post',
        url: `/api/web-chat/internal`,
        data: { thryvId }
      })
      setModalShow(true)
    } catch (error) {
      toast.error(
        'Something went wrong integrating WebChat. Please contact Thryv Support if this issue persists.'
      )
    }
  }

  const returnToThryv = async () => {
    try {
      const body = {
        thryvId,
        script
      }
      await axiosClient({
        method: 'post',
        url: '/api/web-chat/external',
        data: body
      })
      window.location.replace(`${REACT_APP_THRYV_URL}/app`)
    } catch (error) {
      toast.error(
        'Something went wrong integrating WebChat. Please contact Thryv Support if this issue persists.'
      )
    }
  }

  return (
    <div>
      <ToastContainer />
      <InstallSuccessModal
        app="WebChat"
        shouldShow={modalShow}
        closeModal={() => setModalShow(false)}
        integrationComplete={true}
      />
      <NavBar title={`WebChat`} showButton={false} />
      <WebChatHeaderMessageContainer>
        <Header variant="h1" fontWeight="normal">
          Please select whether your website is managed by Thryv or by an
          external company.
        </Header>
      </WebChatHeaderMessageContainer>
      <WebChatNextContainer>
        <WebChatOptionsContainer>
          <Section title="For Internal Site" titleVariant="primary">
            <ParagraphText variant="lg">
              If you have a website managed by Thryv, click the button below. We
              will open a ticket with our web team to add WebChat to your
              website and make sure everything is running smoothly!
            </ParagraphText>
            <Button
              data-testid="alert-button"
              variant="primary"
              onClick={alertWebTeam}
              className="internal-site-btn"
            >
              Alert Web Team
            </Button>
          </Section>
          <Section title="For External Site" titleVariant="primary">
            <ParagraphText variant="lg">
              If your site is not managed by Thryv you will need to add the
              script tag below to your site in order to load the WebChat widget.
              Please add the script to the end of your header for optimal
              functionality. If you ever need to access the script tag again
              just relaunch the WebChat app and you will find the script tag
              displayed on the page. When you are ready click the button below
              to return to your Thryv.
            </ParagraphText>
            <WebChatCodeReferenceContainer>
              <CopyToClipboard text={generateScript(script)} />
            </WebChatCodeReferenceContainer>
            <Button
              data-testid="goback-button"
              variant="primary"
              onClick={returnToThryv}
            >
              Return to Thryv
            </Button>
          </Section>
        </WebChatOptionsContainer>
      </WebChatNextContainer>
    </div>
  )
}
