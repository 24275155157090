export const appIconStyle = {
  height: '80px'
}

export const gmailIconStyle = {
  height: '40px',
  width: '160px'
}

export const ringcentralIconStyle = {
  ...appIconStyle,
  width: '200px'
}

export const signaturesIconStyle = {
  height: '125px'
}

export const cloverIconStyle = {
  width: '200px',
  height: 'auto'
}
