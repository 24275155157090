import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding-top: 1.5rem;
  text-align: center;
  margin-top: 45px;

  & p {
    font-weight: bold;
  }

  & button {
    width: 200px;
    height: 50px;
    font-size: 1rem;
  }
`
