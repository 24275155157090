import { useNavigate } from 'react-router-dom'

import mailchimpLogo from '../../assets/Mailchimp-Logo.png'
import LandingPage from '../../components/landing-page/landing-page'
import { appIconStyle } from '../../style/app-icon-style'

const MailChimp = () => {
  const appName = 'Mailchimp'
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/mailchimp-settings')
  }
  const mailchimpSections = [
    {
      header: 'What will this application need access to?',
      statements: ['Your Mailchimp audiences']
    },

    {
      header: 'What will this application do?',
      statements: [
        'Sync current Contacts from Thryv to Mailchimp.',
        'Sync current Contacts from Mailchimp to Thryv.',
        'Ongoing two-way sync of contacts between Thryv and Mailchimp.'
      ]
    }
  ]

  return (
    <LandingPage
      sections={mailchimpSections}
      onSubmit={handleClick}
      appName={appName}
      appIconSource={mailchimpLogo}
      appIconStyle={appIconStyle}
      isMobileStyling={true}
    />
  )
}

export default MailChimp
