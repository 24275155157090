/* eslint-disable no-useless-escape */

import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Avatar,
  Button,
  CopyToClipboard,
  Header,
  Icon,
  Modal,
  ParagraphText,
  Section
} from '@thryvlabs/maverick'
import { SketchPicker } from 'react-color'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import { LoaderSpinner } from '../../components/loader-spinner'
import InstallSuccessModal from '../../components/modals/install-success-modal'
import UninstallSuccessModal from '../../components/modals/uninstall-success-modal'
import UpdateSuccessModal from '../../components/modals/update-success-modal'
import NavBar from '../../components/nav-bar/nav-bar'
import {
  BusinessMessage,
  ChatButtonContainer,
  ChatInnerContainer,
  ChatContainer,
  ChatHeader,
  ChatMessage,
  ClientMessage,
  ColorBox,
  ColorPickerContainer,
  ColorSettingsContainer,
  FooterContainer,
  FooterButtonContainer,
  PopoverWrapper,
  SectionButtonContainer,
  TextArea,
  TextAreaContainer,
  WebChatHeaderMessageContainer,
  WebChatMainContainer,
  WebChatSettingsContainer
} from '../../style/web-chat-style'
import { authClient } from '../../util/auth-client'
import { generateScript } from '../../util/webchat-script'

const { REACT_APP_THRYV_URL } = process.env

export default function WebChat() {
  const [isInstalled, setIsInstalled] = useState(false)
  const [textColor, setTextColor] = useState()
  const [buttonColor, setButtonColor] = useState()
  const [headerColor, setHeaderColor] = useState()
  const [integrationId, setIntegrationId] = useState()
  const [showHeaderColorPicker, setShowHeaderColorPicker] = useState(false)
  const [showTextColorPicker, setShowTextColorPicker] = useState(false)
  const [showButtonColorPicker, setShowButtonColorPicker] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [showUpdateSuccessModal, setShowUpdateSuccessModal] = useState(false)
  const [showUninstallSuccessModal, setShowUninstallSuccessModal] =
    useState(false)
  const [showInstallSuccessModal, setShowInstallSuccessModal] = useState(false)
  const [script, setScript] = useState(null)

  const {
    user: { businessId: thryvId, dirCode }
  } = useAuth0()
  const navigate = useNavigate()
  const axiosClient = authClient()

  useEffect(() => {
    const getChatSettings = async () => {
      try {
        const {
          data: {
            user: {
              brand_color,
              conversation_color,
              action_color,
              integration_id
            } = {}
          }
        } = await axiosClient({
          url: `/api/web-chat/user/${thryvId}`
        })
        if (brand_color) {
          setHeaderColor(brand_color)
          setTextColor(conversation_color)
          setButtonColor(action_color)
          setIntegrationId(integration_id)
          setIsInstalled(true)
        }
      } catch (error) {
        toast.error(
          `An error occurred getting your web chat settings. Please contact Thryv Support if this issue persists.`
        )
      }
    }
    getChatSettings()
  }, [])

  useEffect(() => {
    const getColorSetting = async () => {
      try {
        const {
          data: { brandColor }
        } = await axiosClient(
          `/api/web-chat/color-setting/${thryvId}/${dirCode}`
        )
        setHeaderColor(brandColor)
      } catch (error) {
        toast.error(
          `An error occurred retrieving your saved color settings. Please contact Thryv Support if this issue persists.`
        )
      }
    }
    getColorSetting()
  }, [])

  const installColorSettings = async () => {
    try {
      setShowLoader(true)
      const body = {
        actionColor: buttonColor,
        brandColor: headerColor,
        conversationColor: textColor,
        thryvId
      }
      const {
        data: { script }
      } = await axiosClient({
        method: 'post',
        url: '/api/web-chat/install',
        data: body
      })
      setScript(script)
      setShowLoader(false)
      setShowInstallSuccessModal(true)
    } catch (error) {
      setShowLoader(false)
      toast.error(
        'An error occurred while installing WebChat. Please contact Thryv Support if this issue persists.'
      )
    }
  }

  const updateColorSetting = async () => {
    try {
      setShowLoader(true)
      const body = {
        actionColor: buttonColor,
        brandColor: headerColor,
        conversationColor: textColor,
        thryvId
      }
      await axiosClient({
        method: 'patch',
        url: '/api/web-chat/update',
        data: body
      })
      setShowUpdateSuccessModal(true)
      setShowLoader(false)
    } catch (error) {
      setShowLoader(false)
      toast.error(
        'An error occurred while updating WebChat. Please contact Thryv Support if this issue persists.'
      )
    }
  }

  const uninstallSetting = async () => {
    setShowLoader(true)
    try {
      await axiosClient({
        method: 'delete',
        url: `/api/web-chat/uninstall/${thryvId}`
      })
      setShowUninstallSuccessModal(true)
      setShowLoader(false)
    } catch (error) {
      setShowLoader(false)
      toast.error(
        'An error occurred while uninstalling WebChat. Please contact Thryv Support if this issue persists.'
      )
    }
  }

  const navigateToThryv = () => {
    window.location.href = `${REACT_APP_THRYV_URL}/app`
  }

  const userPickedColors = !headerColor || !buttonColor || !textColor

  const requiredFieldMessage = userPickedColors && (
    <div>
      <ParagraphText variant="lg" color="utility-error-danger">
        You must select your color settings before proceeding with the{' '}
        {isInstalled ? 'update.' : 'installation.'}
      </ParagraphText>
    </div>
  )
  return (
    <div>
      <ToastContainer />
      <NavBar title={`WebChat`} showButton={false} />
      {showLoader && <LoaderSpinner />}
      <UninstallSuccessModal
        app="WebChat"
        shouldShow={showUninstallSuccessModal}
      />
      {showUpdateSuccessModal && (
        <UpdateSuccessModal
          closeModal={() => setShowUpdateSuccessModal(false)}
        />
      )}
      <InstallSuccessModal
        app="WebChat"
        shouldShow={showInstallSuccessModal}
        closeModal={() => {
          setShowInstallSuccessModal(false)
          navigate('/webMessengerNext', {
            state: {
              script
            }
          })
        }}
      />

      <WebChatHeaderMessageContainer>
        <Header variant="h1" fontWeight="normal">
          Choose the color combination below that suits your website
        </Header>
        <ParagraphText variant="lg">
          Don&apos;t worry, if you don&apos;t like your initial selection this
          can always be updated!
        </ParagraphText>
      </WebChatHeaderMessageContainer>
      <WebChatMainContainer>
        <WebChatSettingsContainer>
          <ChatContainer>
            <ChatInnerContainer>
              <ChatHeader backgroundColor={headerColor}>
                <Icon
                  height="16"
                  width="16"
                  type="regular"
                  variant="arrowLeft"
                  color="white"
                />
                Thryv Chat
                <Icon
                  height="16"
                  width="16"
                  type="regular"
                  variant="x"
                  color="white"
                />
              </ChatHeader>
              <ChatMessage>
                <BusinessMessage textColor={textColor}>
                  Hello and thank you for using Thryv.
                </BusinessMessage>
                <Avatar
                  variant="name"
                  size="medium"
                  name={{
                    firstName: 'Thryv',
                    lastName: 'User'
                  }}
                  staticBackground
                  backgroundColor="#FF5000"
                  className="avatar"
                />
              </ChatMessage>
              <ChatMessage>
                <Avatar
                  variant="name"
                  size="medium"
                  name={{
                    firstName: 'Thryv',
                    lastName: 'Client'
                  }}
                  staticBackground
                  backgroundColor="#99c0e3"
                  className="avatar"
                />
                <ClientMessage>
                  I would like to schedule an introductory demo call if
                  possible.
                </ClientMessage>
              </ChatMessage>
              <ChatMessage>
                <BusinessMessage textColor={textColor}>
                  Absolutely, please use the &apos;Book&apos; button below to do
                  so.
                </BusinessMessage>
                <Avatar
                  variant="name"
                  size="medium"
                  name={{
                    firstName: 'Thryv',
                    lastName: 'User'
                  }}
                  staticBackground
                  backgroundColor="#FF5000"
                  className="avatar"
                />
              </ChatMessage>
              <ChatMessage>
                <BusinessMessage
                  textColor={textColor}
                  buttonColor={buttonColor}
                >
                  Introductory Phone
                  <ChatButtonContainer>
                    <Button variant="primary">Book</Button>
                  </ChatButtonContainer>
                </BusinessMessage>
              </ChatMessage>
              <ChatMessage>
                <Avatar
                  variant="name"
                  size="medium"
                  name={{
                    firstName: 'Thryv',
                    lastName: 'Client'
                  }}
                  staticBackground
                  backgroundColor="#99c0e3"
                  className="avatar"
                />
                <ClientMessage>Thank you!</ClientMessage>
              </ChatMessage>
              <TextAreaContainer>
                <TextArea limit={500} placeholder="Type your message" />
              </TextAreaContainer>
            </ChatInnerContainer>
          </ChatContainer>
          <ColorSettingsContainer>
            <Section subSize="md" title="Color Settings" titleVariant="primary">
              <Section
                subSize="md"
                subtitle="Customize Messenger Header Color"
                title="Header Color"
                titleVariant="primary"
              >
                <SectionButtonContainer boxColor={headerColor}>
                  <Button
                    data-testid="header-color-button"
                    variant="secondary"
                    onClick={() => setShowHeaderColorPicker(state => !state)}
                  >
                    Pick Header Color
                  </Button>
                  <ColorBox color={headerColor} />
                </SectionButtonContainer>
                <div>
                  {showHeaderColorPicker && (
                    <PopoverWrapper>
                      <ColorPickerContainer
                        onClick={() => setShowHeaderColorPicker(false)}
                      />
                      <SketchPicker
                        color={headerColor}
                        onChange={color => setHeaderColor(color.hex)}
                      />
                    </PopoverWrapper>
                  )}
                </div>
              </Section>
              <Section
                subSize="md"
                subtitle="Customize Messenger Text Color"
                title="Text Color"
                titleVariant="primary"
              >
                <SectionButtonContainer boxColor={textColor}>
                  <Button
                    data-testid="text-color-button"
                    variant="secondary"
                    onClick={() => setShowTextColorPicker(state => !state)}
                  >
                    Pick Text Color
                  </Button>
                  <ColorBox color={textColor} />
                </SectionButtonContainer>
                <div>
                  {showTextColorPicker && (
                    <PopoverWrapper>
                      <ColorPickerContainer
                        onClick={() => setShowTextColorPicker(false)}
                      />
                      <SketchPicker
                        color={textColor}
                        onChange={color => setTextColor(color.hex)}
                      />
                    </PopoverWrapper>
                  )}
                </div>
              </Section>
              <Section
                subSize="md"
                subtitle="Customize Messenger Button Color"
                title="Button Color"
                titleVariant="primary"
              >
                <SectionButtonContainer boxColor={buttonColor}>
                  <Button
                    data-testid="button-color-button"
                    variant="secondary"
                    onClick={() => setShowButtonColorPicker(state => !state)}
                  >
                    Pick Button Color
                  </Button>
                  <ColorBox color={buttonColor} />
                </SectionButtonContainer>
                <div>
                  {showButtonColorPicker && (
                    <PopoverWrapper>
                      <ColorPickerContainer
                        onClick={() => setShowButtonColorPicker(false)}
                      />
                      <SketchPicker
                        color={buttonColor}
                        onChange={color => setButtonColor(color.hex)}
                      />
                    </PopoverWrapper>
                  )}
                </div>
              </Section>
            </Section>
          </ColorSettingsContainer>
        </WebChatSettingsContainer>
        <FooterContainer>
          {isInstalled ? (
            <>
              {requiredFieldMessage}
              <FooterButtonContainer>
                <Button
                  data-testid="update-button"
                  variant="primary"
                  onClick={updateColorSetting}
                  disabled={userPickedColors}
                >
                  Update
                </Button>
                <Button
                  data-testid="uninstall-button"
                  variant="primary"
                  onClick={uninstallSetting}
                >
                  Uninstall
                </Button>
                <Button
                  data-testid="goback-button"
                  variant="primary"
                  onClick={navigateToThryv}
                >
                  Go Back to Thryv
                </Button>
                <Modal
                  data-testid="copy-script-modal"
                  variant="default"
                  btnText="Copy Script"
                  btnType="primary"
                  title="Script"
                >
                  <ParagraphText variant="reg">
                    If your site is not managed by Thryv you will need to add
                    the script tag below to your site in order to load the
                    WebChat widget. Please add the script to the end of your
                    header for optimal functionality.
                  </ParagraphText>
                  <CopyToClipboard
                    data-testid="copy-script-button"
                    text={generateScript(integrationId)}
                  />
                </Modal>
              </FooterButtonContainer>
            </>
          ) : (
            <>
              {requiredFieldMessage}
              <Button
                data-testid="install-button"
                variant="primary"
                onClick={installColorSettings}
                disabled={userPickedColors}
              >
                Install
              </Button>
            </>
          )}
        </FooterContainer>
      </WebChatMainContainer>
    </div>
  )
}
