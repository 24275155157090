import { datadogRum } from '@datadog/browser-rum'
const {
  REACT_APP_DATADOG_APPLICATION_ID,
  REACT_APP_DATADOG_CLIENT_ID,
  REACT_APP_DATADOG_SERVICE
} = process.env

export const InitDatadogRum = ({ name, email, thryvId }) => {
  datadogRum.init({
    applicationId: REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: REACT_APP_DATADOG_CLIENT_ID,
    site: 'us5.datadoghq.com',
    service: REACT_APP_DATADOG_SERVICE,
    env: 'dev',
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
  })
  datadogRum.setUser({ id: thryvId, name, email })
}
