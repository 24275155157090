import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { toast, ToastContainer } from 'react-toastify'

import { LoaderSpinner } from '../../components/loader-spinner'
import UninstallApp from '../../components/meta/uninstall-app'
import UninstallSuccessModal from '../../components/modals/uninstall-success-modal'
import NavBar from '../../components/nav-bar/nav-bar'
import { authClient } from '../../util/auth-client'

const { REACT_APP_THRYV_URL } = process.env

function MessengerNextUninstall() {
  const [modalShow, setModalShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuth0()
  const axiosClient = authClient()

  async function uninstallMessengerApp() {
    setIsLoading(true)
    try {
      const { data } = await axiosClient({
        method: 'delete',
        url: `/api/fbm/uninstall/${user.businessId}`
      })
      setIsLoading(false)
      if (data.error) {
        toast.error(data.error)
      } else {
        setModalShow(true)
        setIsLoading(false)
      }
    } catch (error) {
      toast.error(
        'Failed to uninstall Facebook Messenger integration. Please contact Thryv Support if this issue persists.'
      )
    }
  }

  const returnToThryv = () => {
    window.location.href = `${REACT_APP_THRYV_URL}/app`
  }

  return (
    <>
      <ToastContainer />
      <NavBar title={'Facebook Messenger'} />
      <UninstallApp
        uninstallApp={uninstallMessengerApp}
        navigateBackToThryv={returnToThryv}
        appName="Facebook Messenger"
      />
      <UninstallSuccessModal app="Facebook Messenger" shouldShow={modalShow} />
      {isLoading && <LoaderSpinner />}
    </>
  )
}

export default MessengerNextUninstall
