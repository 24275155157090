import { Header, Button } from '@thryvlabs/maverick'

import SettingsItem from './SettingsItem'
import {
  HeaderContainer,
  SettingsContainer,
  ButtonGroup
} from '../../../style/myob-style'

const OngoingSettings = ({
  settingsOptions,
  handleSettingsChange,
  backSettings,
  firstVisitFlow,
  reauthButton,
  reauthSettings,
  submitSettings
}) => {
  const myobSettingsOptions = [
    {
      value: settingsOptions.ongoingClientsFromThryv,
      setValue: () => handleSettingsChange('ongoingClientsFromThryv'),
      header: 'Import ongoing clients from Thryv to MYOB AccountRight?'
    },
    {
      value: settingsOptions.ongoingInvoicesFromThryv,
      setValue: () => handleSettingsChange('ongoingInvoicesFromThryv'),
      header: 'Import ongoing invoices from Thryv to MYOB AccountRight?'
    },
    {
      value: settingsOptions.ongoingPaymentsFromThryv,
      setValue: () => handleSettingsChange('ongoingPaymentsFromThryv'),
      header: 'Import ongoing payments from Thryv to MYOB AccountRight?'
    },
    {
      value: settingsOptions.ongoingClientsFromCodat,
      setValue: () => handleSettingsChange('ongoingClientsFromCodat'),
      header: 'Import ongoing clients from MYOB AccountRight to Thryv?'
    },
    {
      value: settingsOptions.ongoingInvoicesFromCodat,
      setValue: () => handleSettingsChange('ongoingInvoicesFromCodat'),
      header: 'Import ongoing invoices from MYOB AccountRight to Thryv?'
    },
    {
      value: settingsOptions.ongoingPaymentsFromCodat,
      setValue: () => handleSettingsChange('ongoingPaymentsFromCodat'),
      header: 'Import ongoing payments from MYOB AccountRight to Thryv?'
    }
  ]

  return (
    <>
      <SettingsContainer>
        <HeaderContainer>
          <Header fontWeight="bold" variant="h1">
            Ongoing Import Options
          </Header>
        </HeaderContainer>
        <div>
          {myobSettingsOptions.map(item => (
            <SettingsItem
              key={item.header}
              header={item.header}
              itemValue={item.value}
              onChangeItem={item.setValue}
            />
          ))}
          <ButtonGroup>
            {firstVisitFlow && (
              <Button variant="secondary" onClick={() => backSettings()}>
                Back
              </Button>
            )}
            {!firstVisitFlow && reauthButton && (
              <Button variant="secondary" onClick={() => reauthSettings()}>
                Re-Authenticate
              </Button>
            )}
            {firstVisitFlow && (
              <Button variant="primary" onClick={() => submitSettings()}>
                Submit Settings
              </Button>
            )}
            {!firstVisitFlow && !reauthButton && (
              <Button variant="primary" onClick={() => submitSettings()}>
                Update
              </Button>
            )}
          </ButtonGroup>
        </div>
      </SettingsContainer>
    </>
  )
}

export default OngoingSettings
