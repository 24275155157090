import { Auth0Provider } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
const Auth0ProviderWithRedirectCallback = ({
  children,
  audience,
  ...props
}) => {
  const navigate = useNavigate()

  const onRedirectCallback = appState => {
    navigate((appState && appState.returnTo) || '/')
  }

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      {...props}
      authorizationParams={{
        audience,
        redirect_uri: window.location.origin
      }}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithRedirectCallback
