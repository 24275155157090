import {
  LoadingSpinner,
  SpinnerContainer
} from './../style/loader-spinner-style'

export const LoaderSpinner = () => {
  return (
    <SpinnerContainer data-testid="loader-spinner">
      <LoadingSpinner />
    </SpinnerContainer>
  )
}
