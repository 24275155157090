import { Button, Select, ParagraphText, Header } from '@thryvlabs/maverick'

import {
  CountryTzContainer,
  ErrorMessage,
  SelectionContainer,
  HeaderContainer
} from '../../../style/gbm-style'
import { COUNTRIES } from '../constants'

function CountryTz({
  nextStep,
  selectedCountry,
  setSelectedCountry,
  availableTimeZones,
  selectedTimeZone,
  setSelectedTimeZone
}) {
  return (
    <CountryTzContainer>
      <HeaderContainer>
        <Header fontWeight="bold" variant="h3">
          GBM Country & Time Zone Selection
        </Header>
        <ParagraphText variant="sm">Step 1 of 3</ParagraphText>
      </HeaderContainer>
      <SelectionContainer>
        <Header fontWeight="bold" variant="h5">
          Country
        </Header>
        <ParagraphText variant="reg">
          Please select the country your business is in:
        </ParagraphText>
        <Select
          options={COUNTRIES}
          width="md"
          selectLabel="Country"
          selectedOption={selectedCountry}
          setSelectedOption={setSelectedCountry}
        />
        {!selectedCountry.name && (
          <ErrorMessage>Country selection is required</ErrorMessage>
        )}
      </SelectionContainer>
      <SelectionContainer>
        <Header fontWeight="bold" variant="h5">
          Time Zone
        </Header>
        <ParagraphText variant="reg">
          Please select the time zone your business is in:
        </ParagraphText>
        <Select
          options={availableTimeZones}
          width="md"
          selectLabel="Time zone"
          selectedOption={selectedTimeZone}
          setSelectedOption={setSelectedTimeZone}
        />
        {!selectedTimeZone?.name && (
          <ErrorMessage>Time zone selection is required</ErrorMessage>
        )}
      </SelectionContainer>
      <Button
        variant="primary"
        onClick={nextStep}
        disabled={!selectedCountry.name || !selectedTimeZone.name}
        data-testid="next-step-button"
      >
        Next
      </Button>
    </CountryTzContainer>
  )
}

export default CountryTz
