import { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

const InitializeTealium = () => {
  const { user, isAuthenticated } = useAuth0()
  const utag_data = window.utag_data || {}
  const { search, pathname } = window.location
  const app_name = `${pathname}${search}`
  const formatted_app_name = app_name.slice(1)

  useEffect(() => {
    if (isAuthenticated) {
      const tealium_data = {
        user_id: user?.staffUID,
        email: user?.email,
        app_name: formatted_app_name
      }
      utag_data['user_prop'] = tealium_data
      utag_data['account_prop'] = { account_id: user?.businessId }
    }
  }, [isAuthenticated])

  return null
}

export default InitializeTealium
