import { ParagraphText, Input, Button, Header } from '@thryvlabs/maverick'

import {
  ButtonNavContainer,
  ErrorMessage,
  HeaderContainer,
  MessagesContainer,
  MessageInputContainer
} from '../../../style/gbm-style'

function Messages({
  prevStep,
  hoursErrors,
  installGoogleMessenger,
  onlineMessage,
  offlineMessage,
  handleOnlineMessage,
  handleOfflineMessage
}) {
  return (
    <MessagesContainer>
      <HeaderContainer>
        <Header fontWeight="bold" variant="h3">
          GBM Automated Messages
        </Header>
        <ParagraphText variant="sm">Step 3 of 3</ParagraphText>
      </HeaderContainer>
      <MessageInputContainer>
        <Header fontWeight="bold" variant="h5">
          Online Message
        </Header>
        <ParagraphText variant="lg">
          An automated message sent to an individual who initiates a
          conversation with your business during business hours.
        </ParagraphText>
        <Input
          placeholder="online message"
          variant="default"
          type="text"
          data-testid="online-message-input"
          onChange={handleOnlineMessage}
          value={onlineMessage}
        />
        {!onlineMessage && (
          <ErrorMessage>Welcome message is required</ErrorMessage>
        )}
      </MessageInputContainer>
      <MessageInputContainer>
        <Header fontWeight="bold" variant="h5">
          Offline Message
        </Header>
        <ParagraphText variant="lg">
          An automated message sent to an individual who initiates a
          conversation with your business outside of business hours.
        </ParagraphText>
        <Input
          placeholder="offline message"
          variant="default"
          type="text"
          data-testid="offline-message-input"
          onChange={handleOfflineMessage}
          value={offlineMessage}
        />
        {!offlineMessage && (
          <ErrorMessage>Offline message is required</ErrorMessage>
        )}
      </MessageInputContainer>
      <ButtonNavContainer>
        <Button
          variant="primary"
          onClick={prevStep}
          data-testid="previous-step-button"
        >
          Previous
        </Button>
        <Button
          variant="primary"
          onClick={installGoogleMessenger}
          disabled={
            !hoursErrors.oneDayOpen ||
            !hoursErrors.noOpenAfterClose ||
            !offlineMessage ||
            !onlineMessage
          }
          data-testid="next-step-button"
        >
          Install
        </Button>
      </ButtonNavContainer>
    </MessagesContainer>
  )
}

export default Messages
