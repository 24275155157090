import styled from '@emotion/styled'

export const BulletList = styled.li`
  color: red;
  margin-left: 1rem;
  margin-right: 4rem;
`

export const HeaderContainer = styled.div`
  clear: both;
  padding-top: 50px;
  padding-bottom: 20px;
  text-align: center;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    padding-top: 2vh;
  }
`

export const SettingsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  & > div {
    text-align: center;
    flex: 0 0 83.333%;
    max-width: 83.333%;
  }

  @media (max-width: 768px) {
    max-width: 95%;
    width: 95%;
    margin-bottom: 2vh;
  }
`

export const SettingsRowContainer = styled.div`
  display: flex;
  gap: 5vw;
  justify-content: space-between;
  text-align: left;
  margin-left: 10vw;

  @media (max-width: 768px) {
    margin-left: 0px;
  }
`

export const DateSettingsRowContainer = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  justify-content: center;
  text-align: left;
  margin-left: 10vw;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-left: 1vw;
  }
`

export const ButtonGroup = styled.div`
  display: inline-block;

  & button {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
`

export const ModalContainer = styled.div`
  text-align: center;
`
export const ErrorTableContainer = styled.div`
  margin: 5vh 10vw 5vh;

  @media (max-width: 768px) {
    margin: 1vh 2vw 1vh;
  }
`

export const ErrorTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4vh;
`

export const TwoColumnTable = styled.div`
  display: grid;
  grid-template-columns: 3fr 7fr;
  overflow-x: auto;
  grid-gap: 14px;
  width: 100%;
`

export const NoErrorsContianer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5vh;
`

export const TableCell = styled.div`
  white-space: nowrap;
  overflow: scroll;
  @media (max-width: 768px) {
    white-space: wrap;
  }
  align-self: end;
`
