import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { toast, ToastContainer } from 'react-toastify'

import yextLogo from '../../assets/YEXT_BIG.png'
import LandingPage from '../../components/landing-page/landing-page'
import { LoaderSpinner } from '../../components/loader-spinner'
import YextErrorsModal from '../../components/modals/yext-errors-modal'
import { appIconStyle } from '../../style/app-icon-style'
import { authClient } from '../../util/auth-client'

const Yext = () => {
  const [showErrorsModal, setShowErrorsModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const { user } = useAuth0()
  const axiosClient = authClient()

  const knownErrors = [
    'Error please make an account with Yext before continuing',
    "No Yext Id's",
    "Can't Query Business"
  ]

  const handleClick = async e => {
    e.preventDefault()
    setLoading(true)

    try {
      const {
        data: { data: redirectUrl }
      } = await axiosClient({
        method: 'post',
        url: `/api/yext/connect`,
        data: { thryvId: user.businessId }
      })
      setLoading(false)
      window.location.href = redirectUrl
    } catch (error) {
      setLoading(false)
      const errorMessage = error?.response?.data?.data
      if (knownErrors.includes(errorMessage)) {
        setShowErrorsModal(true)
      } else {
        toast.error(
          `An error occurred while connecting to Yext Advanced. Please contact Thryv Support if the issue persists.`
        )
      }
    }
  }

  return (
    <>
      {showErrorsModal && (
        <YextErrorsModal closeModal={() => setShowErrorsModal(false)} />
      )}
      {loading && <LoaderSpinner />}
      <LandingPage
        onSubmit={handleClick}
        appName="Yext"
        appIconSource={yextLogo}
        appIconStyle={appIconStyle}
      />
      <ToastContainer />
    </>
  )
}

export default Yext
