import styled from '@emotion/styled'

//WebChat.js
export const WebChatHeaderMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  text-align: center;
  background-color: #f8f9fb;

  @media (max-width: 768px) {
    width: 95%;
    margin: auto;
  }
`
export const WebChatMainContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  & button {
    width: 150px;
  }
`
export const FooterButtonContainer = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
`
export const WebChatSettingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
export const ColorSettingsContainer = styled.div`
  width: 50%;
  max-width: 700px;
  padding: 20px;

  @media (max-width: 768px) {
    width: 95%;
  }
`
export const ChatContainer = styled.div`
  padding: 20px;
`
export const ChatMessage = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: center;

  & .avatar {
    flex: none;
  }
`
export const BusinessMessage = styled.div`
  background-color: ${props => props.textColor || '#e2ffe8'};
  border: none;
  font-size: 15px;
  border-radius: 25px;
  margin-right: 0.5rem;
  padding: 0.75rem;

  & button {
    background-color: ${props => props.buttonColor};
    border: none;
    color: white;
    border-radius: 50px;
  }
`
export const ClientMessage = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  border: none;
  font-size: 15px;
  border-radius: 25px;
  margin-left: 0.5rem;
  padding: 0.75rem;
`
export const ChatInnerContainer = styled.div`
  border: none;
`
export const ChatButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`
export const TextAreaContainer = styled.div`
  margin-bottom: 1rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
`
export const TextArea = styled.textarea`
  display: block;
  width: 100%;
  padding: 0.375rem 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 5px solid #f0f0f0;
  font-size: 12px;
  border-radius: 100px;
  height: 40px;
`
export const PopoverWrapper = styled.div`
  position: absolute;
  z-index: 2;
`
export const ColorPickerContainer = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`
export const ChatHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  color: white;
  border-radius: 25px 25px 0px 0px;
  background-color: ${props => props.backgroundColor};
`
export const SectionButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`
export const ColorBox = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${props => props.color || 'transparent'};
`

// WebChatNext.js
export const WebChatNextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
export const WebChatOptionsContainer = styled.div`
  width: 70%;
  display: flex;
  gap: 30px;

  & .internal-site-btn {
    margin-top: 1rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 95%;
  }
`
export const WebChatCodeReferenceContainer = styled.div`
  margin: 1rem 0 1rem;
  padding: 20px;
  width: 100%;
  background: #f8f9fb;
`
