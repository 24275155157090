import styled from '@emotion/styled'

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
  text-align: center;
`
export const Column = styled.div`
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 83.333%;
  max-width: 70%;

  p {
    font-weight: bold;
    margin-top: 200px;
  }
`
