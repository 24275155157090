import { useNavigate } from 'react-router-dom'

import typeformLogo from '../../assets/typeform-logo.png'
import LandingPage from '../../components/landing-page/landing-page'
import { appIconStyle } from '../../style/app-icon-style'

const Typeform = () => {
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/typeform-settings')
  }
  const typeformSections = [
    {
      header: 'What will this application need access to?',
      statements: [
        'Your user details',
        'Your form details',
        'Your form submissions'
      ]
    },

    {
      header: 'What will this application do?',
      statements: [
        'Add new Contacts in Thryv.',
        'Update existing Clients in Thryv.',
        'Inject the Typeform into the Inbox of Thryv as well as the conversation history on the client card.'
      ]
    }
  ]

  return (
    <LandingPage
      sections={typeformSections}
      onSubmit={handleClick}
      appName="Typeform"
      appIconSource={typeformLogo}
      appIconStyle={appIconStyle}
      isMobileStyling={true}
    />
  )
}

export default Typeform
