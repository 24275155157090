import { Button, Icon, Modal, ParagraphText, Header } from '@thryvlabs/maverick'

import {
  IconContainer,
  ModalSectionContainer,
  TextContainer
} from '../../style/update-success-modal'

export default function YextErrorsModal({ closeModal }) {
  return (
    <Modal
      variant="default"
      altBtn={<></>}
      onClose={closeModal}
      openOnLoad
      hideX
      action
      tabIndex={0}
    >
      <ModalSectionContainer>
        <IconContainer>
          <Icon variant="circleExclamation" type="solid" color="red" />
        </IconContainer>
        <Header fontWeight="normal" variant="h3">
          Configuration Required
        </Header>
      </ModalSectionContainer>
      <ModalSectionContainer>
        <TextContainer>
          <ParagraphText variant="lg">
            Yext Advanced requires a Yext Account that has been configured with
            Thryv. If you require assistance with your Yext account and Thryv
            Center, we have a wealth of information in our Support Articles,
            simply search for &quot;Listings Management&quot; for applicable
            topics{' '}
            <a target="_blank" rel="noreferrer" href="https://learn.thryv.com">
              here.
            </a>
            <br />
            <br />
            If you need further assistance please contact Thryv Support.
          </ParagraphText>
        </TextContainer>
      </ModalSectionContainer>
      <ModalSectionContainer className="bottom-padding">
        <Button variant="primary" onClick={closeModal}>
          Close
        </Button>
      </ModalSectionContainer>
    </Modal>
  )
}
