import {
  Button,
  Icon,
  Modal,
  ModalTitle,
  ParagraphText
} from '@thryvlabs/maverick/'

import { ModalContainer } from '../../style/install-success-modal-style'
import {
  IconContainer,
  ModalSectionContainer
} from '../../style/update-success-modal'

const { REACT_APP_THRYV_URL } = process.env

export default function InstallSuccessModal({
  app,
  shouldShow,
  closeModal,
  integrationComplete
}) {
  const isRingCentralOrTwilio = app === 'RingCentral' || app === 'Twilio'
  const isWebChat = app === 'WebChat'
  const isXero = app === 'Xero'
  const isMyob = app === 'MYOB Accounting'

  const returnToThryv = () => {
    closeModal()
    window.location.replace(`${REACT_APP_THRYV_URL}/app`)
  }

  const btnActionText = {
    Clover: 'Back to Thryv',
    'Facebook Messenger': 'Back to Thryv',
    'Google Business Messages': 'Back to Thryv',
    'Instagram Messenger': 'Back to Thryv',
    RingCentral: 'OK',
    Twilio: 'OK',
    WebChat: integrationComplete ? 'Back to Thryv' : 'OK',
    Xero: 'Back to Thryv',
    'MYOB Accounting': 'OK'
  }
  const btnAction = {
    Clover: returnToThryv,
    'Facebook Messenger': returnToThryv,
    'Google Business Messages': returnToThryv,
    'Instagram Messenger': returnToThryv,
    RingCentral: closeModal,
    Twilio: closeModal,
    WebChat: integrationComplete ? returnToThryv : closeModal,
    Xero: returnToThryv,
    'MYOB Accounting': closeModal
  }
  const urls = {
    RingCentral:
      'https://learn.thryv.com/hc/en-us/articles/360061219971-Ring-Central',
    Twilio: 'https://learn.thryv.com/hc/en-us/articles/360060738071-Twilio'
  }

  return (
    <>
      {shouldShow && (
        <Modal
          variant="default"
          openOnLoad
          action
          altBtn={<></>}
          hideX
          disableBackdropClick
        >
          <ModalContainer>
            <ModalSectionContainer>
              <IconContainer>
                <Icon variant="circleExclamation" type="solid" color="green" />
              </IconContainer>
              <ModalTitle variant="subtitle" size="lg">
                Success!
              </ModalTitle>
            </ModalSectionContainer>
            {isRingCentralOrTwilio && (
              <ParagraphText variant="reg">
                You have successfully installed the {app} app for Thryv! Send
                yourself a test call by dialing your {app} number to see how it
                works. If you have questions you can refer to this{' '}
                <a target="_blank" rel="nofollow noreferrer" href={urls[app]}>
                  guide
                </a>{' '}
                or contact our Thryv Support team! &#127881;
              </ParagraphText>
            )}
            {isXero && (
              <>
                <ParagraphText variant="reg">
                  Congratulations! Your account is now connected. We are busy
                  syncing your data in the background. You are not required to
                  do anything further. You will be notified by email once the
                  sync is complete.
                </ParagraphText>
                <ParagraphText variant="reg">
                  You may close this page at any time, or press the button below
                  to return to Thryv.
                </ParagraphText>
              </>
            )}
            {isWebChat && !integrationComplete && (
              <ParagraphText variant="reg">
                We&apos;re almost there! We have successfully integrated WebChat
                with your Thryv. We just need to take one more step to get the
                app up and running. Click the button below to add the WebChat
                widget to your website and finalize the integration process.
              </ParagraphText>
            )}
            {isWebChat && integrationComplete && (
              <ParagraphText variant="reg">
                We have alerted our Web Team who will begin adding the WebChat
                widget to your personal site soon. An email will be sent with
                confirmation when the process is complete. You may close this
                page at any time, or press the button below to return to Thryv.
              </ParagraphText>
            )}
            {isMyob && !integrationComplete && (
              <ParagraphText variant="reg">
                You have successfully installed the MYOB Accounting app for
                Thryv! &#127881;
              </ParagraphText>
            )}
            {isMyob && integrationComplete && (
              <ParagraphText variant="reg">
                You have successfully reauthenticated the MYOB Accounting app
                for Thryv! &#127881;
              </ParagraphText>
            )}
            {!isWebChat && !isRingCentralOrTwilio && !isXero && !isMyob && (
              <ParagraphText variant="reg">
                {app} has been installed successfully. You may close this page
                at any time, or press the button below to return to Thryv.
              </ParagraphText>
            )}
            <ModalSectionContainer className="bottom-padding">
              <Button variant="primary" onClick={btnAction[app]}>
                {btnActionText[app]}
              </Button>
            </ModalSectionContainer>
          </ModalContainer>
        </Modal>
      )}
    </>
  )
}
