import { useState } from 'react'

import { Header, Icon, Radio } from '@thryvlabs/maverick'

import {
  TextBox,
  Card,
  CardHeader,
  CardContent,
  CardField
} from '../../../style/twilio-style'

export default function SpamFilterCard({
  spam,
  handleActionChange,
  handleRowFieldEdit,
  accessToken
}) {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const emptyField = 'N/A'

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  const {
    row_id,
    firstName,
    lastName,
    uuid,
    email,
    phoneNumber,
    type,
    timestamp,
    vmLink,
    status
  } = spam

  return (
    <Card key={row_id}>
      <CardHeader onClick={handleToggleCollapse}>
        <Header fontWeight="normal" variant="h5">
          {type === 'Call' ? 'Call' : 'Voicemail'}{' '}
          {firstName || lastName
            ? `from ${firstName || ''} ${lastName || ''}`
            : ''}
        </Header>
        <Icon
          type="solid"
          variant={isCollapsed ? 'chevronDown' : 'chevronUp'}
          color="#000000"
        />
      </CardHeader>
      {!isCollapsed && (
        <CardContent>
          <CardField>
            <TextBox>First Name:</TextBox>{' '}
            <TextBox>{firstName ? `${firstName}` : emptyField}</TextBox>
            <Icon
              type="regular"
              variant="edit"
              height="21"
              width="21"
              color="#FF5000"
              onClick={() => {
                handleRowFieldEdit(uuid, 'firstName')
              }}
            />
          </CardField>
          <CardField>
            <TextBox>Last Name:</TextBox>{' '}
            <TextBox>{lastName ? `${lastName}` : emptyField}</TextBox>
            <Icon
              type="regular"
              variant="edit"
              height="21"
              width="21"
              color="#FF5000"
              onClick={() => {
                handleRowFieldEdit(uuid, 'lastName')
              }}
            />
          </CardField>
          <CardField>
            <TextBox>Email:</TextBox>
            <TextBox>{email || emptyField}</TextBox>{' '}
            <Icon
              type="regular"
              variant="edit"
              height="21"
              width="21"
              color="#FF5000"
              onClick={() => {
                handleRowFieldEdit(uuid, 'email')
              }}
            />
          </CardField>
          <CardField>
            <TextBox>Phone Number:</TextBox>
            <TextBox>{phoneNumber || emptyField}</TextBox>
          </CardField>
          <CardField>
            <TextBox>Type:</TextBox>
            <TextBox>{type || emptyField}</TextBox>
          </CardField>
          <CardField>
            <TextBox>Date:</TextBox>
            <TextBox>
              {new Date(timestamp).toLocaleString() || emptyField}
            </TextBox>
          </CardField>
          <CardField>
            <TextBox>VM Link:</TextBox>
            {vmLink && accessToken ? (
              <TextBox>
                <a href={`${vmLink}?access_token=${accessToken}`}>VM Link</a>
              </TextBox>
            ) : (
              'N/A'
            )}
          </CardField>
          <CardField>
            <TextBox>Action:</TextBox>
          </CardField>
          <CardField>
            <Radio
              hasLabel
              id={uuid}
              value="delete"
              label="Delete"
              toggled={status === 'delete'}
              handleOnChange={handleActionChange}
            />
            <Radio
              hasLabel
              id={uuid}
              value="release"
              label="Release"
              toggled={status === 'release'}
              handleOnChange={handleActionChange}
            />
          </CardField>
        </CardContent>
      )}
    </Card>
  )
}
