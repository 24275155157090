import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Header,
  Button,
  Table,
  TableMenu,
  TableContent,
  ParagraphText
} from '@thryvlabs/maverick'
import { toast, ToastContainer } from 'react-toastify'

import FieldEditModal from './FieldEditModal'
import SpamFilterCard from './SpamFilterCard'
import SpamFilterRow from './SpamFilterRow'
import {
  SpamFilterContainer,
  SpamHeaderRow,
  EightColumnTable,
  NoCallsContianer,
  SpamFooterRow
} from '../../../style/ringcentral-style'
import { authClient } from '../../../util/auth-client'
import useWindowDimensions from '../../../util/use-window-dimensions'

function RingCentralSpamFilter({ accessToken }) {
  const [spamList, setSpamList] = useState([])
  const [fieldToEdit, setFieldToEdit] = useState('')
  const [selectedRow, setSelectedRow] = useState({
    thryvId: '',
    rowId: '',
    firstName: '',
    lastName: '',
    email: ''
  })
  const [displayEditModal, setDisplayEditModal] = useState(false)
  const [allRequiredFields, setAllRequiredFields] = useState(false)

  const {
    user: { businessId: thryvId }
  } = useAuth0()
  const axiosClient = authClient()

  const ringCentralServiceURL = `/api/ring-central`

  const tableColumns = [
    { key: 1, columnName: 'First Name' },
    { key: 2, columnName: 'Last Name' },
    { key: 3, columnName: 'Email' },
    { key: 4, columnName: 'Phone Number' },
    { key: 5, columnName: 'Type' },
    { key: 6, columnName: 'Date' },
    { key: 7, columnName: 'VM Link' },
    { key: 8, columnName: 'Action' }
  ]

  const { width: windowWidth } = useWindowDimensions()

  const getSpamList = async () => {
    try {
      const { data } = await axiosClient(
        `${ringCentralServiceURL}/quarantine/${thryvId}`
      )
      if (data?.quarantine) {
        const addStatus = data.quarantine.map(quarantine => ({
          ...quarantine,
          status: 'delete'
        }))
        setSpamList(addStatus)
      } else {
        setSpamList([])
      }
    } catch {
      toast.error(
        'Error retrieving spam list. If the problem persists please contact Thryv support.'
      )
    }
  }

  const spamEntriesHaveRequiredFields = () => {
    return spamList.every(
      entry => entry.firstName && entry.lastName && entry.email
    )
  }

  const resetEdit = () => {
    setFieldToEdit('')
    setSelectedRow({
      thryvId: '',
      rowId: '',
      firstName: '',
      lastName: '',
      email: ''
    })
  }

  useEffect(() => {
    getSpamList()
  }, [])

  useEffect(() => {
    const validFields = spamEntriesHaveRequiredFields()
    setAllRequiredFields(validFields && spamList?.length)
  }, [spamList])

  const handleActionChange = event => {
    setSpamList(prevSpamList => {
      const updatedSpamList = prevSpamList.map(spamItem => {
        if (spamItem.uuid === event.target.id) {
          return { ...spamItem, status: event.target.value }
        } else {
          return spamItem
        }
      })
      return updatedSpamList
    })
  }

  const handleRowFieldEdit = (uuid, field) => {
    const rowToEdit = spamList.find(row => row.uuid === uuid)
    setFieldToEdit(field)
    setSelectedRow({
      thryvId: rowToEdit.thryvid,
      rowId: rowToEdit.row_id,
      firstName: rowToEdit.firstName,
      lastName: rowToEdit.lastName,
      email: rowToEdit.email
    })
    setDisplayEditModal(true)
  }

  const cancelEdit = () => {
    setDisplayEditModal(false)
    resetEdit()
  }

  const saveEdit = async newValue => {
    setDisplayEditModal(false)
    let payload = {
      rowId: selectedRow.rowId,
      thryvId: selectedRow.thryvId,
      [fieldToEdit]: newValue
    }
    try {
      await axiosClient({
        method: 'patch',
        url: `${ringCentralServiceURL}/quarantine`,
        data: payload
      })
      setSpamList(prevSpamList => {
        const updatedSpamList = prevSpamList.map(spamItem => {
          if (spamItem.row_id === selectedRow.rowId) {
            return { ...spamItem, [fieldToEdit]: newValue }
          } else {
            return spamItem
          }
        })
        return updatedSpamList
      })
      resetEdit()
    } catch (error) {
      toast.error(
        'Error updating spam entry. If the problem persists please contact Thryv support.'
      )
    }
  }

  const deleteOrReleaseContacts = async () => {
    const updatedContacts = spamList.reduce((updatedContacts, contact) => {
      updatedContacts.push({
        thryv_id: contact.thryvid,
        status: contact.status,
        uu_id: contact.uuid
      })
      return updatedContacts
    }, [])

    const payload = {
      thryvId,
      quarantines: updatedContacts
    }

    try {
      await axiosClient({
        method: 'post',
        url: `${ringCentralServiceURL}/quarantine/configure`,
        data: payload
      })
      getSpamList()
    } catch (error) {
      toast.error(
        'Error updating contacts. If the problem persists please contact Thryv support.'
      )
    }
  }

  return (
    <>
      <ToastContainer />
      <FieldEditModal
        displayEditModal={displayEditModal}
        fieldToEdit={fieldToEdit}
        currentValue={selectedRow[fieldToEdit]}
        cancelEdit={cancelEdit}
        saveEdit={saveEdit}
      />
      <SpamFilterContainer>
        <SpamHeaderRow>
          <div></div>
          <Header fontWeight="bold" variant="h1">
            Spam Filter
          </Header>
          <Button variant="primary" onClick={getSpamList}>
            Refresh
          </Button>
        </SpamHeaderRow>
        <Table>
          {windowWidth > 768 && (
            <TableMenu>
              <EightColumnTable>
                {tableColumns.map(column => (
                  <div key={column.key}>
                    <h6>{column.columnName}</h6>
                  </div>
                ))}
              </EightColumnTable>
            </TableMenu>
          )}
          <TableContent>
            {spamList?.length > 0 ? (
              <>
                {spamList.map(spam =>
                  windowWidth > 768 ? (
                    <SpamFilterRow
                      key={spam.row_id}
                      spam={spam}
                      handleActionChange={handleActionChange}
                      handleRowFieldEdit={handleRowFieldEdit}
                      accessToken={accessToken}
                    />
                  ) : (
                    <SpamFilterCard
                      key={spam.row_id}
                      spam={spam}
                      handleActionChange={handleActionChange}
                      handleRowFieldEdit={handleRowFieldEdit}
                      accessToken={accessToken}
                    />
                  )
                )}
              </>
            ) : (
              <NoCallsContianer>
                <Header
                  fontWeight="light"
                  variant={windowWidth > 768 ? 'h5' : 'h7'}
                >
                  No Quarantined Calls or Voicemails
                </Header>
              </NoCallsContianer>
            )}
          </TableContent>
        </Table>
        {!allRequiredFields && (
          <SpamFooterRow>
            <ParagraphText variant="lg" color="utility-error-danger">
              All entries require a valid first name, last name and email before
              they can be deleted or released.
            </ParagraphText>
          </SpamFooterRow>
        )}
        <SpamFooterRow>
          <Button
            variant="primary"
            onClick={deleteOrReleaseContacts}
            disabled={!allRequiredFields}
          >
            {spamList?.length
              ? 'Delete or Release Contacts'
              : 'Delete or Release Contact'}
          </Button>
        </SpamFooterRow>
      </SpamFilterContainer>
    </>
  )
}

export default RingCentralSpamFilter
