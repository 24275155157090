import { Header } from '@thryvlabs/maverick'

import { SwitchButton } from '../../../components/switch-button'
import { SettingsRowContainer } from '../../../style/myob-style'

const SettingsItem = ({ header, disabled, itemValue, onChangeItem }) => {
  return (
    <>
      <SettingsRowContainer>
        <span>
          <Header fontWeight="semibold" variant="h7">
            {header}
          </Header>
        </span>

        <SwitchButton
          defaultValue={itemValue}
          changeFunc={onChangeItem}
          disabled={disabled}
        />
      </SettingsRowContainer>
      <br></br>
    </>
  )
}

export default SettingsItem
