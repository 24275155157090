import { Button, ParagraphText } from '@thryvlabs/maverick'

import GBMImage from '../../assets/gbm_error.png'
import ThryvGuy from '../../assets/thryv_guy.png'
import NavBar from '../../components/nav-bar/nav-bar'
import {
  GBMErrorButtonContainer,
  GBMErrorContainer,
  GBMErrorMessageContainer,
  GBMErrorRowContainer,
  GBMErrorThryvGuy,
  GBMInstructionImage
} from '../../style/gbm-style'

const GBMError = () => {
  const navigateToThryvSettings = () => {
    window.location.href = 'https://go.thryv.com/app/setting'
  }

  const guideLink =
    'https://learn.thryv.com/hc/en-us/articles/360002609131-My-Business-Settings#h_1'

  return (
    <>
      <NavBar
        mobileFontSize={'5vw'}
        title={`Google Business Messages`}
        showButton={false}
      />
      <GBMErrorContainer>
        <GBMErrorRowContainer>
          <GBMErrorMessageContainer>
            <ParagraphText data-testid="gbm-error-message" variant="lg">
              Oops! It looks like your account is missing a Business logo in
              Thryv settings.
              <br />
              <br />A Business logo is required for the Google Business Messages
              app. We display your logo to your customers when they are chatting
              with you.
              <br />
              <br />
              Please go to Business Settings and add your logo.{' '}
              <Button
                variant="text"
                level={1}
                onClick={() => (window.location.href = guideLink)}
                data-testid="guide-link"
              >
                Here is a guide!
              </Button>
              <br />
              <br />
              Once completed, you can install the Google Business Messages app.
            </ParagraphText>
            <GBMErrorThryvGuy
              data-testid="gmb-thryv-guy-img"
              src={ThryvGuy}
              alt="Thryv Guy"
            ></GBMErrorThryvGuy>
          </GBMErrorMessageContainer>
          <GBMInstructionImage
            data-testid="gmb-instruction-image"
            src={GBMImage}
            alt="gbm"
          ></GBMInstructionImage>
        </GBMErrorRowContainer>
        <GBMErrorButtonContainer>
          <Button
            variant="primary"
            data-testid="go-back-thryv-button"
            onClick={navigateToThryvSettings}
          >
            Go to Thryv Business Settings
          </Button>
        </GBMErrorButtonContainer>
      </GBMErrorContainer>
    </>
  )
}

export default GBMError
