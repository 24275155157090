import { Header, Toggle, Select } from '@thryvlabs/maverick'

import { HOURS } from './constants'
import {
  handleOpenClose,
  handleOpenTime,
  handleOpenMeridiem,
  handleCloseTime,
  handleCloseMeridiem
} from './hoursHelpers'
import {
  HoursSelectionContainer,
  HoursSelectionTable,
  HourAndMerideimSelection,
  ErrorMessage
} from '../../style/gbm-style'

const SelectionRow = ({ day, setBusinessHours }) => {
  return (
    <>
      <div>{day.day}</div>
      <Toggle
        label={day.open ? 'Open' : 'Closed'}
        toggleSize="normal"
        onChange={() => {
          handleOpenClose(setBusinessHours, day.day)
        }}
        toggled={day.open}
      />
      {day.open ? (
        <HourAndMerideimSelection>
          <Select
            options={HOURS}
            selectLabel="Opening Hour"
            selectedOption={day.openTime}
            setSelectedOption={newTime => {
              handleOpenTime(newTime, setBusinessHours, day.day)
            }}
            width="md"
          />
          <Select
            options={[
              { name: 'AM', value: 'AM' },
              { name: 'PM', value: 'PM' }
            ]}
            selectLabel="Meridiem"
            selectedOption={day.openMeridiem}
            setSelectedOption={newMeridiem => {
              handleOpenMeridiem(newMeridiem, setBusinessHours, day.day)
            }}
            width="md"
          />
        </HourAndMerideimSelection>
      ) : (
        <div></div>
      )}
      {day.open ? (
        day.openBeforeClose ? (
          <div>To</div>
        ) : (
          <ErrorMessage>Invalid hours: Closing before Opening</ErrorMessage>
        )
      ) : (
        <div></div>
      )}
      {day.open ? (
        <HourAndMerideimSelection>
          <Select
            options={HOURS}
            selectLabel="Closing Hour"
            selectedOption={day.closeTime}
            setSelectedOption={newTime => {
              handleCloseTime(newTime, setBusinessHours, day.day)
            }}
            width="md"
          />
          <Select
            options={[
              { name: 'AM', value: 'AM' },
              { name: 'PM', value: 'PM' }
            ]}
            selectLabel="Meridiem"
            selectedOption={day.closeMeridiem}
            setSelectedOption={newMeridiem => {
              handleCloseMeridiem(newMeridiem, setBusinessHours, day.day)
            }}
            width="md"
          />
        </HourAndMerideimSelection>
      ) : (
        <div></div>
      )}
    </>
  )
}

export default function GBM({ businessHours, setBusinessHours }) {
  return (
    <>
      <HoursSelectionContainer>
        <Header fontWeight="bold" variant="h5">
          Business Hours
        </Header>
        <HoursSelectionTable>
          {businessHours.map(day => (
            <SelectionRow
              key={day.day}
              day={day}
              setBusinessHours={setBusinessHours}
            />
          ))}
        </HoursSelectionTable>
      </HoursSelectionContainer>
    </>
  )
}
