import styled from '@emotion/styled'

export const ModalSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  &.bottom-padding {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`
export const IconContainer = styled.div`
  padding-right: 0.5rem;
`
export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
`
