import { useEffect, useState } from 'react'

import { Modal, ModalTitle, Input } from '@thryvlabs/maverick/'

import {
  EditFieldsModalWrapper,
  InputWrapper,
  NegativeBottomMargin
} from '../../../style/ringcentral-style'

function FieldEditModal({
  displayEditModal,
  fieldToEdit,
  currentValue,
  cancelEdit,
  saveEdit
}) {
  const [newFieldValue, setNewFieldValue] = useState('')
  const [isValidInput, setIsValidInput] = useState(false)

  const fields = {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email'
  }

  useEffect(() => {
    if (displayEditModal) {
      setNewFieldValue('')
      setIsValidInput(false)
    }
  }, [displayEditModal])

  useEffect(() => {
    const isValid = checkValidity()
    setIsValidInput(isValid)
  }, [newFieldValue])

  const checkValidity = () => {
    if (fieldToEdit === 'email') {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
      if (!emailRegex.test(newFieldValue) || newFieldValue.length > 255) {
        return false
      }
    } else {
      const validCharactersRegex = /^[a-zA-z'-]+$/
      if (
        !validCharactersRegex.test(newFieldValue) ||
        newFieldValue.length < 2 ||
        newFieldValue.length > 30
      ) {
        return false
      }
    }
    return true
  }

  const errorMessages = () => {
    if (fieldToEdit === 'email') {
      return 'Please enter a valid email address'
    } else {
      return 'Please enter a valid name using alphabetic characters, hyphens and apostrophes'
    }
  }

  return (
    <>
      {displayEditModal && (
        <Modal
          variant="default"
          openOnLoad
          footer
          action
          btnActionText="Update"
          btnAction={() => {
            saveEdit(newFieldValue)
          }}
          altBtn={<></>}
          hideX
          footerCancel
          disableBackdropClick
          cancelAction={() => cancelEdit()}
          btnActionDisabled={!isValidInput}
        >
          <EditFieldsModalWrapper>
            <NegativeBottomMargin>
              <ModalTitle
                variant="subtitle"
                size="lg"
              >{`Edit ${fields[fieldToEdit]}`}</ModalTitle>
            </NegativeBottomMargin>
            <ModalTitle variant="subtitle" size="md">{`Currently: ${
              currentValue || 'N/A'
            }`}</ModalTitle>
            <InputWrapper>
              <Input
                type="text"
                placeholder={`Updated value for ${fields[fieldToEdit]}`}
                name={fieldToEdit}
                onChange={e => {
                  setNewFieldValue(e.target.value)
                }}
                withLabel
                labelType="static"
                variant="default"
                isValid={isValidInput}
                errorMessage={errorMessages()}
                data-testid="edit-modal-input"
              />
            </InputWrapper>
          </EditFieldsModalWrapper>
        </Modal>
      )}
    </>
  )
}

export default FieldEditModal
