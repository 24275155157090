import styled from '@emotion/styled'

export const Container = styled.div`
  width: 95%;
  padding-right: ${props => props.pr || 'var(--bs-gutter-x, 0.75rem)'};
  padding-left: ${props => props.pl || 'var(--bs-gutter-x, 0.75rem)'};
  padding-top: ${props => props.pt || '0'};
  padding-bottom: ${props => props.pb || '0'};
  margin-right: ${props => props.mr || 'auto'};
  margin-left: ${props => props.ml || 'auto'};
  margin-top: ${props => props.mt || '0'};
  margin-bottom: ${props => props.mb || '0'};
  text-align: ${props =>
    props.textCentered ? 'center !important' : 'initial'};

  @media (min-width: 1800px) {
    width: 80%;
  }
`

export const SmallContainer = styled.div`
  width: 35%;
  padding-right: ${props => props.pr || 'var(--bs-gutter-x, 0.75rem)'};
  padding-left: ${props => props.pl || 'var(--bs-gutter-x, 0.75rem)'};
  padding-top: ${props => props.pt || '0'};
  padding-bottom: ${props => props.pb || '0'};
  margin-right: ${props => props.mr || 'auto'};
  margin-left: ${props => props.ml || 'auto'};
  margin-top: ${props => props.mt || '0'};
  margin-bottom: ${props => props.mb || '0'};
  text-align: ${props =>
    props.textCentered ? 'center !important' : 'initial'};
`

export const MediumContainer = styled.div`
  width: 50%;
  padding-right: ${props => props.pr || 'var(--bs-gutter-x, 0.75rem)'};
  padding-left: ${props => props.pl || 'var(--bs-gutter-x, 0.75rem)'};
  padding-top: ${props => props.pt || '0'};
  padding-bottom: ${props => props.pb || '0'};
  margin-right: ${props => props.mr || 'auto'};
  margin-left: ${props => props.ml || 'auto'};
  margin-top: ${props => props.mt || '0'};
  margin-bottom: ${props => props.mb || '0'};
  text-align: ${props =>
    props.textCentered ? 'center !important' : 'initial'};
`

export const LargeContainer = styled.div`
  width: 60%;
  padding-right: ${props => props.pr || 'var(--bs-gutter-x, 0.75rem)'};
  padding-left: ${props => props.pl || 'var(--bs-gutter-x, 0.75rem)'};
  padding-top: ${props => props.pt || '0'};
  padding-bottom: ${props => props.pb || '0'};
  margin-right: ${props => props.mr || 'auto'};
  margin-left: ${props => props.ml || 'auto'};
  margin-top: ${props => props.mt || '0'};
  margin-bottom: ${props => props.mb || '0'};
  text-align: ${props =>
    props.textCentered ? 'center !important' : 'initial'};
`
