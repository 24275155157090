import { Button, Header } from '@thryvlabs/maverick'
import { useNavigate } from 'react-router-dom'

import NavBar from '../../components/nav-bar/nav-bar'
import {
  GBMSelectionContainer,
  GBMSelectionButtonContainer,
  MainContainer
} from '../../style/gbm-style'

const GBMSelection = () => {
  const navigate = useNavigate()

  const handleYesButton = () => {
    navigate('/google')
  }

  const handleNoButton = () => {
    navigate('/gbm-no-selection')
  }

  return (
    <>
      <NavBar
        mobileFontSize={'5vw'}
        title={`Google Business Messages`}
        showButton={false}
      />
      <MainContainer>
        <GBMSelectionContainer>
          <Header fontWeight="medium" variant="h2">
            Do you have access to your Google Business Profile?
          </Header>
          <GBMSelectionButtonContainer>
            <Button
              variant="primary"
              className="small-button"
              onClick={handleYesButton}
              data-testid="yes-button"
            >
              Yes
            </Button>
            <Button
              variant="primary"
              className="small-button"
              onClick={handleNoButton}
              data-testid="no-button"
            >
              No
            </Button>
          </GBMSelectionButtonContainer>
        </GBMSelectionContainer>
      </MainContainer>
    </>
  )
}

export default GBMSelection
