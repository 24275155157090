import { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useLDClient } from 'launchdarkly-react-client-sdk'

import AuthenticatedApps from './components/AuthenticatedApps/AuthenticatedApps'
import UnauthenticatedApp from './components/AuthenticatedApps/UnauthenticatedApp'
import { LoaderSpinner } from './components/loader-spinner'
import LoginPopup from './pages/LoginPopup'
import InitializeTealium from './util/InitializeTealium'

import 'react-toastify/dist/ReactToastify.css'
import './style/App.css'

function App() {
  const { isLoading, isAuthenticated, error, user } = useAuth0()
  const ldClient = useLDClient()
  useEffect(() => {
    if (isAuthenticated) {
      ldClient.identify({
        kind: 'user',
        key: user.email,
        email: user.email,
        name: user.name,
        businessId: user.businessId
      })
    }
  }, [user, isAuthenticated, ldClient])
  InitializeTealium()

  if (isLoading) return <LoaderSpinner />

  if (error) {
    return <LoginPopup error={error} />
  }
  if (isAuthenticated) return <AuthenticatedApps />

  return <UnauthenticatedApp />
}

export default App
