import styled from '@emotion/styled'

// XeroSettingsOptions.js
export const XeroJumbotron = styled.div`
  clear: both;
  padding-top: 1.5rem;
  text-align: center;
  margin-bottom: 20px;

  .next-page-button {
    width: 200px;
    height: 50px;
    margin-top: 20px;
  }
`
export const CenteredContainer = styled.div`
  display: flex;
  margin: 1rem 0;

  & button {
    margin-top: 1rem;
  }
`
export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  align-items: center;
  padding: 30px;

  .address-text {
    max-width: 500px;
    text-align: justify;
  }
`
export const PostInstallationContainer = styled.div`
  margin: auto;
  width: 70%;
`
export const RadioButtonContainer = styled.div`
  & label {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`
export const ButtonGroupContainer = styled.div`
  align-items: center;
  justify-content: center;

  & button {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

// import-options-table.js
export const RowContainer = styled.div`
  width: 80%;
  margin: auto;

  @media (max-width: 768px) {
    width: 95%;
  }
`
export const ContentBox = styled.div`
  text-align: left;

  .date-input {
    margin-left: 95%;

    @media (max-width: 768px) {
      margin-left: 70%;
    }
  }
`

// XeroSettings.js
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
`

// XeroAccountCreate.js
export const AccountHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
`
export const AccountSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
  width: 75%;
  margin: 2rem auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  text-align: center;

  & h6 {
    margin-top: 3rem;
  }

  @media (max-width: 768px) {
    width: 95%;
  }
`
export const AccountRowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 3rem;
  text-align: left;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: none;
  }
`
export const SelectContainer = styled.div`
  padding-right: 3rem;
  flex: 0 0 16.667%;
  max-width: 16.667%;

  @media (max-width: 768px) {
    padding-left: 3vw;
    margin-left: 10vw;
    flex: 0;
    max-width: 80%;
  }
`
export const AccountCreationMessageContainer = styled.div`
  -webkit-box-flex: 0;
  flex: 0 0 75%;
  max-width: 75%;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
`
export const AccountCreateButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 50px;
  margin-top: 25px;
`

// xero-components.js
export const SelectionDetailsContainer = styled.div`
  flex: 0 0 83.333%;
  max-width: 83.333%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;

  & p {
    margin-left: 3rem;
  }
`
export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  .account-exists-header {
    color: #ff5000;
  }
`
export const CardSection = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  & p {
    text-align: justify;
  }
`
