import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Header } from '@thryvlabs/maverick'
import { ToastContainer, toast } from 'react-toastify'

import ThryvGuyExcited from '../../assets/thryvguy_excited.png'
import InstallSuccessModal from '../../components/modals/install-success-modal'
import NavBar from '../../components/nav-bar/nav-bar'
import { Container } from '../../style/containers-style'
import { LogoContainer } from '../../style/landing-page-style'
import { authClient } from '../../util/auth-client'

const CloverSettings = () => {
  const axiosClient = authClient()
  const { user } = useAuth0()
  const [isInstalled, setIsInstalled] = useState(false)
  const queryParams = new URLSearchParams(window.location.search)
  const merchantId = queryParams.get('merchant_id')
  const employeeId = queryParams.get('employee_id')
  const code = queryParams.get('code')

  const { REACT_APP_THRYV_URL } = process.env

  useEffect(() => {
    async function getAccessTokenSendUserData() {
      try {
        const {
          data: { accessToken }
        } = await getAccessToken(code)
        await sendUserData(accessToken)
        setIsInstalled(true)
      } catch (err) {
        toast.error(
          `An error occurred while getting your access token. Please contact Thryv Support if the issue persists.`
        )
      }
    }
    getAccessTokenSendUserData()
  }, [code, merchantId, employeeId])

  async function getAccessToken(code) {
    return await axiosClient(`/api/clover/access-token/${code}`)
  }

  async function sendUserData(cloverAccessToken) {
    await axiosClient({
      method: 'post',
      url: '/api/clover/send-user-data',
      data: {
        thryvId: user.businessId,
        employeeId,
        merchantId,
        cloverAccessToken
      }
    })
  }

  return (
    <>
      <ToastContainer />
      <NavBar title={'Clover'} />
      <InstallSuccessModal
        app="Clover"
        shouldShow={isInstalled}
        closeModal={() => {
          setIsInstalled(false)
          window.location.href = `${REACT_APP_THRYV_URL}/app`
        }}
      />
      <Container mt="3rem" textCentered>
        <Header variant="h1" fontWeight="bold">
          Please wait while your Clover app is configured.
        </Header>
        <LogoContainer>
          <img
            className="clover-thryv-guy"
            src={ThryvGuyExcited}
            alt="Thryv guy"
          />
        </LogoContainer>
      </Container>
    </>
  )
}

export default CloverSettings
