import styled from '@emotion/styled'

// landing-page.js
export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
`
export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.3rem;
  margin-bottom: 20px;

  .plus-sign {
    margin-top: -20px;
  }

  .clover-thryv-guy {
    margin-top: 2rem;
  }

  ${props =>
    props.isMobileStyling &&
    `@media (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
  `}
`
export const ThryvLogo = styled.img`
  height: 75px;
  width: 75px;
  border-radius: '10%';
`
export const AppLogo = styled.img`
  margin-left: ${props => props.appIconStyle.marginLeft};
  height: ${props => props.appIconStyle.height};
  width: ${props => props.appIconStyle.width};
  margin-top: ${props => props.appIconStyle.marginTop};
  border-radius: 10%;
`
export const HeaderContainer = styled.div`
  clear: both;
  padding: 3rem;
  text-align: center;
  background: #f8f9fb;
  margin-bottom: 20px;
`
export const ContextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
`
export const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 100px;
  padding: 15px;

  & p {
    text-align: justify;
  }
  .icon-style {
    display: inline-block;
    color: red;
    font-size: 25px;
    margin-right: 1rem;
    margin-bottom: 0.25rem;
  }
  .warning-text {
    margin-bottom: -50px;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  & button {
    margin-top: 1rem;
    height: 3.125rem;
    font-size: 1.25rem;
    width: 16vw;

    @media screen and (max-width: 768px) {
      width: 20vw;
    }
    @media screen and (max-width: 600px) {
      width: 33vw;
    }
  }
`

// sections.js
export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
  margin-bottom: 20px;

  & h3 {
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 1rem;
    background: #f8f9fb;
  }
  .card {
    border-radius: 0.5rem;
  }
`
export const StatementsContainer = styled.div`
  display: flex;
  margin-left: 1.5em;
  margin-top: 5px;
  padding: 5px;

  .icon {
    margin-top: 25px;
    min-width: 20px;
  }
  & p {
    font-size: 22px !important;
    margin-left: 8px;
    padding: 25px;
  }
`

// terms-conditions.js
export const CheckBoxContainer = styled.div`
  display: flex;

  & a {
    color: #666;
    margin-left: 5px;
    margin-top: -3px;
    text-decoration: underline;
  }
`
