import { useEffect, useState } from 'react'

import {
  Header,
  Icon,
  ParagraphText,
  Table,
  TableContent,
  TableHeader,
  TableInfo,
  TableMenu,
  TableRow
} from '@thryvlabs/maverick'
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import NavBar from '../../components/nav-bar/nav-bar'
import {
  Card,
  CardContent,
  CardField,
  CardHeader,
  ErrorContainer,
  ErrorHeaderContainer,
  ErrorTableContainer,
  MobileContainer,
  TableCell,
  TableHeaderContainer,
  TextBox
} from '../../style/clover-style'
import { authClient } from '../../util/auth-client'
import useWindowDimensions from '../../util/use-window-dimensions'

function CloverError() {
  const [error, setError] = useState({})

  const { id } = useParams()
  const axiosClient = authClient()

  const { width: windowWidth } = useWindowDimensions()

  useEffect(() => {
    const getImportError = async () => {
      try {
        const { data } = await axiosClient(`/api/clover/import-errors/${id}`)
        setError(data.error)
      } catch (error) {
        toast.error(
          `An error occurred fetching your Clover access token. Please contact Thryv Support if the issue persists.`
        )
      }
    }
    getImportError()
  }, [id])

  return (
    <>
      <ToastContainer />
      <NavBar title={'Clover'} />
      <ErrorContainer>
        <ErrorHeaderContainer>
          {windowWidth > 768 ? (
            <ParagraphText variant="reg">
              There was an issue while configuring your Thryv and Clover
              integration. Please refer to the table below to learn more about
              the error(s) that occurred.
            </ParagraphText>
          ) : (
            <ParagraphText variant="reg">
              There was an issue while configuring your Thryv and Clover
              integration. Please refer to the error(s) listed below to find out
              more.
            </ParagraphText>
          )}
        </ErrorHeaderContainer>
        {windowWidth > 768 ? (
          <ErrorTableContainer>
            <Table>
              <TableHeader title="Errors" />
              <TableInfo
                date={new Date()}
                data={error ? [error] : []}
                label={[error].length > 1 || !error ? 'Errors' : 'Error'}
              />
              <TableMenu>
                <TableHeaderContainer>
                  <TableCell>
                    <Header variant="h6" fontWeight="semibold">
                      Error
                    </Header>
                  </TableCell>
                  <TableCell>
                    <Header variant="h6" fontWeight="semibold">
                      Clover Client ID
                    </Header>
                  </TableCell>
                  <TableCell>
                    <Header variant="h6" fontWeight="semibold">
                      Clover Merchant ID
                    </Header>
                  </TableCell>
                  <TableCell>
                    <Header variant="h6" fontWeight="semibold">
                      Thryv Business ID
                    </Header>
                  </TableCell>
                </TableHeaderContainer>
              </TableMenu>
              <TableContent>
                {!!error && (
                  <TableRow variant="static" key={error?.id}>
                    <TableCell>
                      <span>{error.import_error}</span>
                    </TableCell>
                    <TableCell>
                      <span>{error.clover_client_id}</span>
                    </TableCell>
                    <TableCell>
                      <span>{error.clover_merchant_id}</span>
                    </TableCell>
                    <TableCell>
                      <span>{error.thryv_business_id}</span>
                    </TableCell>
                  </TableRow>
                )}
              </TableContent>
            </Table>
          </ErrorTableContainer>
        ) : (
          <MobileContainer>
            <TableHeader title="Errors" />
            <TableInfo
              date={new Date()}
              data={error ? [error] : []}
              label={[error].length > 1 || !error ? 'Errors' : 'Error'}
            />
            {error &&
              [error].map(e => <CloverErrorMobileCard key={e.id} error={e} />)}
          </MobileContainer>
        )}
      </ErrorContainer>
    </>
  )
}

export default CloverError

function CloverErrorMobileCard({ error }) {
  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <Card>
      <CardHeader onClick={() => setIsCollapsed(!isCollapsed)}>
        <Header variant="h5" fontWeight="normal">
          {error.import_error}
        </Header>
        <Icon
          type="solid"
          variant={isCollapsed ? 'chevronDown' : 'chevronUp'}
          color="#000000"
        />
      </CardHeader>
      {!isCollapsed && (
        <CardContent>
          <CardField>
            <TextBox>Error:</TextBox>
            <TextBox>{error.import_error}</TextBox>
          </CardField>
          <CardField>
            <TextBox>Clover Client ID:</TextBox>
            <TextBox>{error.clover_client_id}</TextBox>
          </CardField>
          <CardField>
            <TextBox>Clover Merchant ID:</TextBox>
            <TextBox>{error.clover_merchant_id}</TextBox>
          </CardField>
          <CardField>
            <TextBox>Thryv Business ID:</TextBox>
            <TextBox>{error.thryv_business_id}</TextBox>
          </CardField>
        </CardContent>
      )}
    </Card>
  )
}
