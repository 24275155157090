import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  ParagraphText,
  Input,
  Button,
  Select,
  Header
} from '@thryvlabs/maverick'
import axios from 'axios'
import dayjs from 'dayjs'
import advanced from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import jwt_decode from 'jwt-decode'
import moment from 'moment-timezone'
import { toast, ToastContainer } from 'react-toastify'

import { COUNTRIES } from './constants'
import GBMHoursSelection from './GBMHoursSelection'
import GBMInstallMobile from './GBMInstallMobile'
import InstallErrorModal from '../../components/gbm/install-error-modal'
import { LoaderSpinner } from '../../components/loader-spinner'
import InstallSuccessModal from '../../components/modals/install-success-modal'
import NavBar from '../../components/nav-bar/nav-bar'
import {
  ErrorMessage,
  FooterContainer,
  GBMInstallButtonContainer,
  GBMProfileContainer,
  GridContainer,
  GridItem,
  PermissionsContainer,
  ProfileImage
} from '../../style/gbm-style'
import { authClient } from '../../util/auth-client'
import { convertTo24 } from '../../util/convertTo24'
import { timeZoneConverter } from '../../util/time-zone-converter'
import useWindowDimensions from '../../util/use-window-dimensions'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

const { REACT_APP_URL, REACT_APP_GBM_CLIENT_ID } = process.env

const baseURL = 'https://www.googleapis.com/auth'

const scopesNeeded = [
  `${baseURL}/userinfo.profile`,
  `${baseURL}/userinfo.email`,
  `${baseURL}/businesscommunications`,
  `${baseURL}/business.manage`,
  'openid'
]

function GBMInstall() {
  const [googleIdToken, setGoogleIdToken] = useState()
  const [responseHasLoaded, setResponseHasLoaded] = useState(false)
  const [picture, setPicture] = useState('')
  const [email, setEmail] = useState('')
  const [onlineMessage, setOnlineMessage] = useState('')
  const [offlineMessage, setOfflineMessage] = useState('')
  const [gbmAccessToken, setGbmAccessToken] = useState('')
  const [gbmRefreshAccessToken, setGbmRefreshAccessToken] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [showInstallErrorModal, setShowInstallErrorModal] = useState(false)
  const [installing, setInstalling] = useState(false)
  const [hasScopePermission, setHasScopePermission] = useState(false)
  const [hoursErrors, setHoursErrors] = useState({
    oneDayOpen: true,
    noOpenAfterClose: true
  })
  const [selectedCountry, setSelectedCountry] = useState({
    name: '',
    value: ''
  })
  const [selectedTimeZone, setSelectedTimeZone] = useState({
    name: '',
    value: ''
  })
  const [availableTimeZones, setAvailableTimeZones] = useState([])
  const [businessHours, setBusinessHours] = useState([
    {
      day: 'Sunday',
      open: false,
      openBeforeClose: true,
      openTime: { name: '', value: '' },
      openMeridiem: { name: '', value: '' },
      closeTime: { name: '', value: '' },
      closeMeridiem: { name: '', value: '' }
    },
    {
      day: 'Monday',
      open: true,
      openBeforeClose: true,
      openTime: { name: '9:00', value: '9:00' },
      openMeridiem: { name: 'AM', value: 'AM' },
      closeTime: { name: '5:00', value: '5:00' },
      closeMeridiem: { name: 'PM', value: 'PM' }
    },
    {
      day: 'Tuesday',
      open: true,
      openBeforeClose: true,
      openTime: { name: '9:00', value: '9:00' },
      openMeridiem: { name: 'AM', value: 'AM' },
      closeTime: { name: '5:00', value: '5:00' },
      closeMeridiem: { name: 'PM', value: 'PM' }
    },
    {
      day: 'Wednesday',
      open: true,
      openBeforeClose: true,
      openTime: { name: '9:00', value: '9:00' },
      openMeridiem: { name: 'AM', value: 'AM' },
      closeTime: { name: '5:00', value: '5:00' },
      closeMeridiem: { name: 'PM', value: 'PM' }
    },
    {
      day: 'Thursday',
      open: true,
      openBeforeClose: true,
      openTime: { name: '9:00', value: '9:00' },
      openMeridiem: { name: 'AM', value: 'AM' },
      closeTime: { name: '5:00', value: '5:00' },
      closeMeridiem: { name: 'PM', value: 'PM' }
    },
    {
      day: 'Friday',
      open: true,
      openBeforeClose: true,
      openTime: { name: '9:00', value: '9:00' },
      openMeridiem: { name: 'AM', value: 'AM' },
      closeTime: { name: '5:00', value: '5:00' },
      closeMeridiem: { name: 'PM', value: 'PM' }
    },
    {
      day: 'Saturday',
      open: false,
      openBeforeClose: true,
      openTime: { name: '', value: '' },
      openMeridiem: { name: '', value: '' },
      closeTime: { name: '', value: '' },
      closeMeridiem: { name: '', value: '' }
    }
  ])

  const { user } = useAuth0()
  const search = window.location.search
  const params = new URLSearchParams(search)
  const encodedAuthCode = encodeURIComponent(params.get('code'))
  const axiosClient = authClient()

  const { width: windowWidth } = useWindowDimensions()

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const {
          data: { idToken, accessToken, refreshToken }
        } = await axiosClient({
          url: `/api/gbm/token/${encodedAuthCode}`
        })
        setGoogleIdToken(idToken)
        setGbmAccessToken(accessToken)
        setGbmRefreshAccessToken(refreshToken)
        setResponseHasLoaded(true)
      } catch (error) {
        toast.error(
          'An error occurred fetching your Google access token. Please contact Thryv Support if this issue persists.'
        )
      }
    }

    fetchToken()
  }, [])

  useEffect(() => {
    const validateScopesAndDecodeToken = async () => {
      try {
        if (gbmAccessToken) {
          const {
            data: { scope: selectedScopes }
          } = await axios.get(
            `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${gbmAccessToken}`
          )
          setHasScopePermission(
            selectedScopes.split(' ').length === scopesNeeded.length
          )
        }
        if (responseHasLoaded) {
          const decodedToken = jwt_decode(googleIdToken)
          setPicture(decodedToken.picture)
          setEmail(decodedToken.email)
        }
      } catch (error) {
        toast.error(
          'An error occurred validating your Google access token. Please contact Thryv Support if this issue persists.'
        )
      }
    }

    validateScopesAndDecodeToken()
  }, [responseHasLoaded, gbmAccessToken])

  useEffect(() => {
    //when a user selects a country this will run, populating the timezone selector with tzs from that country
    if (selectedCountry.name) {
      const zones = moment.tz.zonesForCountry(selectedCountry.value)
      const filteredZones = zones.filter(
        zone => !zone.startsWith('America/North_Dakota')
      )
      const timeZonesData = filteredZones.map(zone => {
        return {
          name: timeZoneConverter(zone),
          value: zone
        }
      })
      const uniqueTimeZones = [
        ...new Map(timeZonesData.map(zone => [zone.name, zone])).values()
      ]
        .filter(zone => zone.name !== '')
        .sort((a, b) => a.name.localeCompare(b.name))
      setAvailableTimeZones(uniqueTimeZones)
    }
  }, [selectedCountry])

  useEffect(() => {
    //this will guess the user's timezone after they select a country and populate the select if it matches available tzs
    const guessedTz = timeZoneConverter(dayjs.tz.guess())
    let correctGuessTz
    if (availableTimeZones.length > 0) {
      correctGuessTz = availableTimeZones.find(
        timeZone => timeZone.name === guessedTz
      )
    }
    if (correctGuessTz) {
      setSelectedTimeZone(correctGuessTz)
    } else {
      setSelectedTimeZone({
        name: '',
        value: ''
      })
    }
  }, [availableTimeZones])

  useEffect(() => {
    const anyOpen = businessHours.find(day => day.open === true)
    const anyOutOfOrder = businessHours.every(
      day => day.openBeforeClose === true
    )
    setHoursErrors({
      oneDayOpen: anyOpen,
      noOpenAfterClose: anyOutOfOrder
    })
  }, [businessHours])

  const handleOnlineMessage = e => {
    setOnlineMessage(e.target.value)
  }

  const handleOfflineMessage = e => {
    setOfflineMessage(e.target.value)
  }

  const installGoogleMessenger = async () => {
    setInstalling(true)
    const hours = []
    businessHours.map(day => {
      if (day.open) {
        const startTime = convertTo24(
          day.openTime.value,
          day.openMeridiem.value
        )
        const closeTime = convertTo24(
          day.closeTime.value,
          day.closeMeridiem.value
        )
        hours.push({
          startTime: {
            hours: startTime[0],
            minutes: startTime[1]
          },
          endTime: {
            hours: closeTime[0],
            minutes: closeTime[1]
          },
          timeZone: selectedTimeZone.value,
          startDay: day.day.toUpperCase(),
          endDay: day.day.toUpperCase()
        })
      }
    })

    if (onlineMessage && offlineMessage) {
      const payload = {
        thryvId: user.businessId,
        gbmAccessToken,
        gbmRefreshAccessToken,
        onlineMessage,
        offlineMessage,
        hours
      }

      try {
        await axiosClient({
          method: 'post',
          url: '/api/gbm/install',
          data: payload
        })
        setInstalling(false)
        setShowModal(true)
      } catch (err) {
        setInstalling(false)
        if (err.response.status === 418) {
          setShowInstallErrorModal(true)
        } else {
          toast.error(
            'An error occurred while installing Google Business Messages. Please contact Thryv Support if this issue persists.'
          )
        }
      }
    }
  }

  const promptScopeSelection = () => {
    window.location.replace(
      `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/businesscommunications https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https%3A//www.googleapis.com/auth/business.manage&access_type=offline&prompt=consent&include_granted_scopes=true&response_type=code&redirect_uri=${REACT_APP_URL}/gbm-next&client_id=${REACT_APP_GBM_CLIENT_ID}`
    )
  }

  if (!responseHasLoaded) {
    return (
      <>
        <LoaderSpinner />
        <ToastContainer />
      </>
    )
  }

  return (
    <>
      <ToastContainer />
      {installing && <LoaderSpinner />}
      {windowWidth > 768 ? (
        <>
          <NavBar title={`Google Business Messages`} showButton={false} />
          {responseHasLoaded && (
            <GBMProfileContainer>
              <ProfileImage data-testid="profile-image" src={picture} />
              <ParagraphText variant="reg">
                You&apos;re logged in as: <span>{email}</span>
              </ParagraphText>
            </GBMProfileContainer>
          )}
          {hasScopePermission ? (
            <>
              <GridContainer>
                <GridItem>
                  <Header fontWeight="bold" variant="h5">
                    Country
                  </Header>
                  <ParagraphText variant="lg">
                    Please select the country your business is in.
                  </ParagraphText>
                </GridItem>
                <GridItem>
                  <Select
                    options={COUNTRIES}
                    width="lg"
                    selectLabel="Country"
                    selectedOption={selectedCountry}
                    setSelectedOption={setSelectedCountry}
                  />
                  {!selectedCountry.name && (
                    <ErrorMessage>Country selection is required</ErrorMessage>
                  )}
                </GridItem>
                <GridItem>
                  <Header fontWeight="bold" variant="h5">
                    Timezone
                  </Header>
                  <ParagraphText variant="lg">
                    Please select the timezone your business is in.
                  </ParagraphText>
                </GridItem>
                <GridItem>
                  <Select
                    options={availableTimeZones}
                    width="lg"
                    selectLabel="Timezone"
                    selectedOption={selectedTimeZone}
                    setSelectedOption={setSelectedTimeZone}
                  />
                  {!selectedTimeZone.name && (
                    <ErrorMessage>Timezone selection is required</ErrorMessage>
                  )}
                </GridItem>
              </GridContainer>
              <GBMHoursSelection
                businessHours={businessHours}
                setBusinessHours={setBusinessHours}
              />
              {!hoursErrors.oneDayOpen && (
                <GridItem>
                  <ErrorMessage>
                    Your business must be open at least one day a week
                  </ErrorMessage>
                </GridItem>
              )}
              <GridContainer>
                <GridItem>
                  <Header fontWeight="bold" variant="h5">
                    Online Message
                  </Header>
                  <ParagraphText variant="lg">
                    An automated message sent to an individual who initiates a
                    conversation with your business during business hours.
                  </ParagraphText>
                </GridItem>
                <GridItem>
                  <Input
                    placeholder="online message"
                    variant="default"
                    type="text"
                    data-testid="online-message-input"
                    onChange={handleOnlineMessage}
                    value={onlineMessage}
                  />
                  {!onlineMessage && (
                    <ErrorMessage>Welcome message is required</ErrorMessage>
                  )}
                </GridItem>
                <GridItem>
                  <Header fontWeight="bold" variant="h5">
                    Offline Message
                  </Header>
                  <ParagraphText variant="lg">
                    An automated message sent to an individual who initiates a
                    conversation with your business outside of business hours.
                  </ParagraphText>
                </GridItem>
                <GridItem>
                  <Input
                    placeholder="offline message"
                    variant="default"
                    type="text"
                    data-testid="offline-message-input"
                    onChange={handleOfflineMessage}
                    value={offlineMessage}
                  />
                  {!offlineMessage && (
                    <ErrorMessage>Offline message is required</ErrorMessage>
                  )}
                </GridItem>
              </GridContainer>
              <FooterContainer>
                <Button
                  variant="primary"
                  onClick={installGoogleMessenger}
                  disabled={
                    !onlineMessage ||
                    !offlineMessage ||
                    !selectedTimeZone.name ||
                    !selectedCountry.name ||
                    !hoursErrors.oneDayOpen ||
                    !hoursErrors.noOpenAfterClose
                  }
                  data-testid="install-button"
                >
                  Install
                </Button>
              </FooterContainer>
            </>
          ) : (
            <>
              <PermissionsContainer>
                <ParagraphText variant="lg">
                  In order for this integration to work correctly, you must
                  select all permissions on the preceding screen. Please take
                  moment to make the appropriate corrections.
                  <br></br>
                  <br></br>
                  Please click the button below to reopen the permissions
                  selection screen and make sure to check all boxes.
                </ParagraphText>
              </PermissionsContainer>
              <GBMInstallButtonContainer>
                <Button
                  data-testid="select-permissions"
                  variant="primary"
                  onClick={promptScopeSelection}
                >
                  Select Permissions
                </Button>
              </GBMInstallButtonContainer>
            </>
          )}
        </>
      ) : hasScopePermission ? (
        <GBMInstallMobile
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          availableTimeZones={availableTimeZones}
          selectedTimeZone={selectedTimeZone}
          setSelectedTimeZone={setSelectedTimeZone}
          businessHours={businessHours}
          setBusinessHours={setBusinessHours}
          hoursErrors={hoursErrors}
          installGoogleMessenger={installGoogleMessenger}
          onlineMessage={onlineMessage}
          offlineMessage={offlineMessage}
          handleOnlineMessage={handleOnlineMessage}
          handleOfflineMessage={handleOfflineMessage}
        />
      ) : (
        <>
          <PermissionsContainer>
            <ParagraphText variant="lg">
              In order for this integration to work correctly, you must select
              all permissions on the preceding screen. Please take moment to
              make the appropriate corrections.
              <br></br>
              <br></br>
              Please click the button below to reopen the permissions selection
              screen and make sure to check all boxes.
            </ParagraphText>
          </PermissionsContainer>
          <GBMInstallButtonContainer>
            <Button
              data-testid="select-permissions"
              variant="primary"
              onClick={promptScopeSelection}
            >
              Select Permissions
            </Button>
          </GBMInstallButtonContainer>
        </>
      )}
      <InstallSuccessModal
        app="Google Business Messages"
        shouldShow={showModal}
        closeModal={() => setShowModal(false)}
      />
      <InstallErrorModal shouldShow={showInstallErrorModal} />
    </>
  )
}

export default GBMInstall
