export const COUNTRIES = [
  { name: 'United States', value: 'US' },
  { name: 'Australia', value: 'AU' },
  { name: 'Canada', value: 'CA' },
  { name: 'New Zealand', value: 'NZ' }
]

export const HOURS = [
  { name: '12:00', value: '12:00' },
  { name: '12:30', value: '12:30' },
  { name: '1:00', value: '1:00' },
  { name: '1:30', value: '1:30' },
  { name: '2:00', value: '2:00' },
  { name: '2:30', value: '2:30' },
  { name: '3:00', value: '3:00' },
  { name: '3:30', value: '3:30' },
  { name: '4:00', value: '4:00' },
  { name: '4:30', value: '4:30' },
  { name: '5:00', value: '5:00' },
  { name: '5:30', value: '5:30' },
  { name: '6:00', value: '6:00' },
  { name: '6:30', value: '6:30' },
  { name: '7:00', value: '7:00' },
  { name: '7:30', value: '7:30' },
  { name: '8:00', value: '8:00' },
  { name: '8:30', value: '8:30' },
  { name: '9:00', value: '9:00' },
  { name: '9:30', value: '9:30' },
  { name: '10:00', value: '10:00' },
  { name: '10:30', value: '10:30' },
  { name: '11:00', value: '11:00' },
  { name: '11:30', value: '11:30' }
]
