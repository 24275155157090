import { useAuth0 } from '@auth0/auth0-react'
import { Button, ParagraphText } from '@thryvlabs/maverick'

import { Container } from '../style/login-popup-style'

const LoginPopup = ({ error }) => {
  const { loginWithPopup } = useAuth0()

  return (
    <Container>
      {error?.message ? (
        <ParagraphText variant="lg">
          There was an error: {error.message}. Please log in to access the
          application.
        </ParagraphText>
      ) : (
        <ParagraphText variant="lg">
          Please sign in to access the application.
        </ParagraphText>
      )}
      <Button
        data-testid="login-button"
        variant="primary"
        onClick={() => loginWithPopup()}
      >
        Login
      </Button>
    </Container>
  )
}

export default LoginPopup
