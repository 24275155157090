import { DatePicker } from '@thryvlabs/maverick'

import { BulletList, DateSettingsRowContainer } from '../../../style/myob-style'

const DateSetting = ({ header, currentDate, setCurrentDate, name }) => {
  return (
    <>
      <DateSettingsRowContainer>
        <BulletList>{header}</BulletList>

        <DatePicker
          currentDate={currentDate}
          handleOnChange={setCurrentDate}
          name={name}
          label="date"
          relative
          data-testid="date-picker"
        />
      </DateSettingsRowContainer>
      <br></br>
    </>
  )
}

export default DateSetting
