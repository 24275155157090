import { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import twilioLogo from '../../assets/twilio-logo-red.width-640.png'
import LandingPage from '../../components/landing-page/landing-page'
import { appIconStyle } from '../../style/app-icon-style'
import { authClient } from '../../util/auth-client'

export default function Twilio() {
  const { user } = useAuth0()
  const navigate = useNavigate()
  const axiosClient = authClient()

  const thryvId = user.businessId

  const twilioSections = [
    {
      header: 'What will this application do?',
      statements: [
        'Create contacts in Thryv from incoming Twilio SMS and Phone calls.',
        'Sync incoming calls from Twilio with Thryv.',
        'Sync incoming SMS from Twilio with Thryv.'
      ]
    }
  ]

  const handleSubmit = async () => {
    try {
      await axiosClient({
        method: 'POST',
        url: '/api/twilio/users',
        data: { thryvId }
      })
      navigate('/twilio-settings', {
        state: {
          twilioUser: {
            thryvId
          }
        }
      })
    } catch (error) {
      toast.error(`
        An error occurred while creating your Twilio user profile.
        Please contact Thryv Support if the issue persists.
      `)
    }
  }

  // Checks if the user has already integrated Twilio with Thryv
  useEffect(() => {
    async function getIntegrationStatus() {
      try {
        const {
          data: {
            twilio_access_token,
            twilio_account_sid,
            twilio_phone_number,
            phone,
            sms,
            quarantine
          }
        } = await axiosClient(`/api/twilio/users/${thryvId}`)
        // a user could have abandoned the Twilio integration status after setting their tokens
        const userHasSubmittedTokens =
          !!twilio_access_token && !!twilio_account_sid && !!twilio_phone_number
        // this checks that they've actually finished the integration
        const userHasIntegration =
          [phone, sms, quarantine].every(setting => setting !== null) &&
          userHasSubmittedTokens
        if (userHasIntegration) {
          navigate('/twilio-settings', {
            state: {
              twilioUser: {
                thryvId,
                twilioAccessToken: twilio_access_token,
                twilioAccountSid: twilio_account_sid,
                twilioPhoneNumber: twilio_phone_number,
                phone,
                sms,
                quarantine,
                userHasIntegration
              }
            }
          })
        }
        if (userHasSubmittedTokens && !userHasIntegration) {
          navigate('/twilio-settings', {
            state: {
              twilioUser: {
                thryvId,
                twilioAccessToken: twilio_access_token,
                twilioAccountSid: twilio_account_sid,
                twilioPhoneNumber: twilio_phone_number
              }
            }
          })
        }
      } catch (err) {
        toast.error(`
          An error occurred checking Twilio's installation status.
          Please contact Thryv Support if this issue persists.
        `)
      }
    }
    getIntegrationStatus()
  }, [])

  return (
    <>
      <ToastContainer />
      <LandingPage
        sections={twilioSections}
        onSubmit={handleSubmit}
        appName="Twilio"
        appIconSource={twilioLogo}
        appIconStyle={appIconStyle}
        isMobileStyling={true}
      />
    </>
  )
}
