import { Header } from '@thryvlabs/maverick'

import { SwitchButton } from '../../../components/switch-button'
import {
  SettingsRowContainer,
  SettingsDetailsContainer,
  WarningText
} from '../../../style/ringcentral-style'

const SettingsItem = ({
  header,
  statement,
  warning,
  itemValue,
  onChangeItem
}) => {
  return (
    <>
      <SettingsRowContainer>
        <span>
          <Header fontWeight="semibold" variant="h7">
            {header}
          </Header>
          <SettingsDetailsContainer>
            <li>{statement}</li>
            {warning && (
              <li>
                <WarningText>{warning}</WarningText>
              </li>
            )}
          </SettingsDetailsContainer>
        </span>

        <SwitchButton defaultValue={itemValue} changeFunc={onChangeItem} />
      </SettingsRowContainer>
      <br></br>
    </>
  )
}

export default SettingsItem
