import xeroLogo from '../../assets/xero-logo.png'
import LandingPage from '../../components/landing-page/landing-page'
import { appIconStyle } from '../../style/app-icon-style'

const { REACT_APP_URL, REACT_APP_XERO_CLIENT_ID } = process.env

export function Xero() {
  const handleClick = e => {
    e.preventDefault()
    window.location.replace(
      `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${REACT_APP_XERO_CLIENT_ID}&redirect_uri=${REACT_APP_URL}/xero-settings&scope=openid profile email accounting.transactions accounting.contacts accounting.settings offline_access`
    )
  }

  const xeroSections = [
    {
      header: 'What will this application need access to?',
      statements: ['Customers', 'Invoices', 'Payments']
    },
    {
      header: 'What will this application do?',
      statements: [
        'Sync current Customers between Thryv and Xero.',
        'Sync historical Invoices and Payments between Thryv and Xero.',
        'Sync future Customers between Thryv and Xero.',
        'Sync future Invoices and Payments between Thryv and Xero.'
      ]
    }
  ]
  return (
    <LandingPage
      sections={xeroSections}
      onSubmit={handleClick}
      appName="Xero"
      appIconSource={xeroLogo}
      appIconStyle={appIconStyle}
    />
  )
}
