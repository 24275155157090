import styled from '@emotion/styled'

export const ShopifyLogo = styled.img`
  margin-top: -20px;
  margin-left: 10px;
  height: 80px;
  width: 80px;
  border-radius: 10%;
`

export const ThryvLogo = styled.img`
  margin-right: 10px;
  height: 75px;
  width: 75px;
  margin-top: -20px;
  border-radius: 10%;
`

export const ShopifySettingsText = styled.div`
  width: 50%;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  & p {
    font-size: 1.25rem;
    margin-top: 2rem;
  }
  & a {
    color: #ff5000;
  }
  & a:hover {
    color: #e64f0a;
  }
`
