import { useState } from 'react'

import CountryTz from './MobileComponents/CountryTz'
import Hours from './MobileComponents/Hours'
import Messages from './MobileComponents/Messages'

function GBMInstallMobile({
  selectedCountry,
  setSelectedCountry,
  availableTimeZones,
  selectedTimeZone,
  setSelectedTimeZone,
  businessHours,
  setBusinessHours,
  hoursErrors,
  installGoogleMessenger,
  onlineMessage,
  offlineMessage,
  handleOnlineMessage,
  handleOfflineMessage
}) {
  const [step, setStep] = useState(1)

  const nextStep = () => {
    setStep(prevStep => prevStep + 1)
  }

  const prevStep = () => {
    setStep(prevStep => prevStep - 1)
  }

  return (
    <>
      {step === 1 && (
        <CountryTz
          nextStep={nextStep}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          availableTimeZones={availableTimeZones}
          selectedTimeZone={selectedTimeZone}
          setSelectedTimeZone={setSelectedTimeZone}
        />
      )}
      {step === 2 && (
        <Hours
          nextStep={nextStep}
          prevStep={prevStep}
          businessHours={businessHours}
          setBusinessHours={setBusinessHours}
          hoursErrors={hoursErrors}
        />
      )}
      {step === 3 && (
        <Messages
          prevStep={prevStep}
          hoursErrors={hoursErrors}
          installGoogleMessenger={installGoogleMessenger}
          onlineMessage={onlineMessage}
          offlineMessage={offlineMessage}
          handleOnlineMessage={handleOnlineMessage}
          handleOfflineMessage={handleOfflineMessage}
        />
      )}
    </>
  )
}

export default GBMInstallMobile
