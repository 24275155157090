import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Button, Header, ParagraphText, Radio } from '@thryvlabs/maverick'
import { useNavigate, useLocation } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import thryvGuy from '../../assets/thryv_guy_alternative.png'
import UninstallSuccessModal from '../../components/modals/uninstall-success-modal'
import NavBar from '../../components/nav-bar/nav-bar'
import ImportOptionsTable from '../../components/xero/import-options-table'
import { Container } from '../../style/containers-style'
import {
  AddressContainer,
  ButtonGroupContainer,
  CenteredContainer,
  PostInstallationContainer,
  RadioButtonContainer,
  XeroJumbotron
} from '../../style/xero-style'
import { authClient } from '../../util/auth-client'

const xeroServiceURL = '/api/xero'
const initErrorMessage =
  'Something went wrong while initializing your Xero account. If the problem persists, please contact Thryv Support.'

const XeroSettingsOptions = () => {
  const [settings, setSettings] = useState({
    historicalSettings: true,
    updated: false,
    installDisplay: true,
    uninstallDisplay: false,
    ongoingDisplay: false,
    updateDisplay: false
  })
  const [historicalImportOptions, setHistoricalImportOptions] = useState({
    xeroClients: true,
    xeroInvoicesPayments: false,
    syncXeroFromDate: '',
    thryvClients: true,
    thryvInvoicesPayments: false,
    syncThryvFromDate: ''
  })
  const [ongoingImportOptions, setOngoingImportOptions] = useState({
    xeroClients: true,
    xeroInvoicesPayments: true,
    thryvClients: true,
    thryvInvoicesPayments: true
  })
  const [sqlTenantId, setsqlTenantId] = useState()
  const [historicalDatesStaticEnabled, setHistoricalDatesStaticEnabled] =
    useState(false)
  const [checkedAddressValue, setCheckedAddressValue] = useState('')
  const [currency, setCurrency] = useState('')
  const [nextDisabled, setNextDisabled] = useState(false)
  const [showUninstallSuccessModal, setShowUninstallSuccessModal] =
    useState(false)

  const { state } = useLocation()
  const { user } = useAuth0()
  const navigate = useNavigate()
  const axiosClient = authClient()

  const { dirCode: directoryId, businessId: thryvId } = user
  const { xeroAuth: { xeroAuthToken, xeroRefreshToken } = {} } = state || {}

  const handleSettingsChange = key => {
    setSettings(prevSettings => ({
      ...prevSettings,
      [key]: !prevSettings[key]
    }))
  }

  const handleOptionChange = (optionType, optionName) => {
    if (optionType === 'historical') {
      setHistoricalImportOptions(prevOptions => ({
        ...prevOptions,
        [optionName]: !prevOptions[optionName]
      }))
    } else if (optionType === 'ongoing') {
      setOngoingImportOptions(prevOptions => ({
        ...prevOptions,
        [optionName]: !prevOptions[optionName]
      }))
    }
  }

  const changeXeroInvoicesDate = e => {
    setHistoricalImportOptions(prevOptions => ({
      ...prevOptions,
      syncXeroFromDate: e.target.value
    }))
  }

  const changeThryvInvoicesDate = e => {
    setHistoricalImportOptions(prevOptions => ({
      ...prevOptions,
      syncThryvFromDate: e.target.value
    }))
  }

  const showNext = () => {
    handleSettingsChange('historicalSettings')
    handleSettingsChange('ongoingDisplay')
  }

  const showPrevious = () => {
    setHistoricalDatesStaticEnabled(true)
    handleSettingsChange('historicalSettings')
    handleSettingsChange('ongoingDisplay')
  }

  const createAccount = () => {
    const payload = {
      tenantId: sqlTenantId,
      currency,
      xeroRefreshToken,
      thryvId,
      checkedHistoricalClients: historicalImportOptions.xeroClients ? '1' : '0',
      checkedHistoricalClientsFromThryv: historicalImportOptions.thryvClients
        ? '1'
        : '0',
      checkedHistoricalInvoicePayment:
        historicalImportOptions.xeroInvoicesPayments ? '1' : '0',
      checkedHistoricalInvoicesPaymentsThryv:
        historicalImportOptions.thryvInvoicesPayments ? '1' : '0',
      checkedOngoingClients: ongoingImportOptions.xeroClients ? '1' : '0',
      checkedOngoingClientsThryv: ongoingImportOptions.thryvClients ? '1' : '0',
      checkedOngoingInvoicesPayments: ongoingImportOptions.xeroInvoicesPayments
        ? '1'
        : '0',
      checkedOngoingInvoicesPaymentsThryv:
        ongoingImportOptions.thryvInvoicesPayments ? '1' : '0',
      checkedAddressValue: checkedAddressValue,
      syncFromDateThryv: historicalImportOptions.syncThryvFromDate,
      syncFromDateXero: historicalImportOptions.syncXeroFromDate
    }
    navigate('/xero-create-account', { state: { payload } })
  }

  const uninstall = async () => {
    try {
      await axiosClient({
        method: 'delete',
        url: `${xeroServiceURL}/uninstall/${thryvId}`
      })
      setShowUninstallSuccessModal(true)
    } catch (err) {
      toast.error(
        'Something went wrong while uninstalling Xero. If the problem persists please contact Thryv Support.'
      )
    }
  }

  const showUpdateScreen = () => {
    handleSettingsChange('updateDisplay')
    handleSettingsChange('uninstallDisplay')
  }

  const update = async () => {
    const payload = {
      tenantId: sqlTenantId,
      currency,
      xeroRefreshToken,
      thryvId,
      ongoingClientsValue: ongoingImportOptions.xeroClients ? '1' : '0',
      ongoingClientsThryvValue: ongoingImportOptions.thryvClients ? '1' : '0',
      ongoingInvoicesPaymentsValue: ongoingImportOptions.xeroInvoicesPayments
        ? '1'
        : '0',
      ongoingInvoicesPaymentsThryvValue:
        ongoingImportOptions.thryvInvoicesPayments ? '1' : '0',
      address: checkedAddressValue,
      syncFromDateThryv: historicalImportOptions.syncThryvFromDate,
      syncFromDateXero: historicalImportOptions.syncXeroFromDate
    }

    try {
      await axiosClient({
        method: 'patch',
        url: `${xeroServiceURL}/update`,
        data: payload
      })
      handleSettingsChange('updated')
    } catch (err) {
      toast.error(
        'Something went wrong while updating Xero. If the problem persists, please contact Thryv Support.'
      )
    }
  }

  useEffect(() => {
    async function getUserInfo() {
      try {
        const { data } = await axiosClient(
          `${xeroServiceURL}/import-status/${thryvId}`
        )
        if (data.isImported) {
          const {
            historicalThryvClients,
            historicalXeroClients,
            historicalInvoicesThryv,
            historicalInvoicesXero,
            ongoingThryvClients,
            ongoingXeroClients,
            ongoingInvoicesThryv,
            ongoingInvoicesXero,
            addressOption,
            syncFromDateThryv,
            syncFromDateXero
          } = data

          const formattedSyncFromDateThryv = syncFromDateThryv.split(' ')[0]
          const formattedSyncFromDateXero = syncFromDateXero.split(' ')[0]

          setHistoricalImportOptions({
            xeroClients: historicalXeroClients === 1,
            xeroInvoicesPayments: historicalInvoicesXero === 1,
            syncXeroFromDate: formattedSyncFromDateXero,
            thryvClients: historicalThryvClients === 1,
            thryvInvoicesPayments: historicalInvoicesThryv === 1,
            syncThryvFromDate: formattedSyncFromDateThryv
          })
          setOngoingImportOptions({
            xeroClients: ongoingXeroClients === 1,
            xeroInvoicesPayments: ongoingInvoicesXero === 1,
            thryvClients: ongoingThryvClients === 1,
            thryvInvoicesPayments: ongoingInvoicesThryv === 1
          })
          setCheckedAddressValue(addressOption.toString())

          handleSettingsChange('installDisplay')
          handleSettingsChange('uninstallDisplay')
        }
      } catch (err) {
        toast.error(initErrorMessage)
      }

      try {
        const { data } = await axiosClient(
          `${xeroServiceURL}/tenant-id/${xeroAuthToken}`
        )
        setsqlTenantId(data.tenantId)
      } catch (err) {
        toast.error(initErrorMessage)
      }

      try {
        const {
          data: { currency }
        } = await axiosClient(
          `${xeroServiceURL}/currency/${thryvId}/${directoryId}`
        )
        setCurrency(currency)
      } catch (err) {
        toast.error(initErrorMessage)
      }
    }
    getUserInfo()
  }, [])

  useEffect(() => {
    if (
      !historicalImportOptions.syncXeroFromDate &&
      historicalImportOptions.xeroInvoicesPayments
    ) {
      setNextDisabled(true)
    } else if (
      !historicalImportOptions.syncThryvFromDate &&
      historicalImportOptions.thryvInvoicesPayments
    ) {
      setNextDisabled(true)
    } else {
      setNextDisabled(false)
    }
  }, [historicalImportOptions, ongoingImportOptions])

  useEffect(() => {
    if (historicalImportOptions.xeroClients === false) {
      setHistoricalImportOptions(prevOptions => ({
        ...prevOptions,
        xeroInvoicesPayments: false,
        syncXeroFromDate: ''
      }))
    }
  }, [historicalImportOptions.xeroClients])

  useEffect(() => {
    if (historicalImportOptions.thryvClients === false) {
      setHistoricalImportOptions(prevOptions => ({
        ...prevOptions,
        thryvInvoicesPayments: false,
        syncThryvFromDate: ''
      }))
    }
  }, [historicalImportOptions.thryvClients])

  useEffect(() => {
    if (!historicalImportOptions.xeroInvoicesPayments) {
      setHistoricalImportOptions(prevOptions => ({
        ...prevOptions,
        syncXeroFromDate: ''
      }))
    }
  }, [historicalImportOptions.xeroInvoicesPayments])

  useEffect(() => {
    if (!historicalImportOptions.thryvInvoicesPayments) {
      setHistoricalImportOptions(prevOptions => ({
        ...prevOptions,
        syncThryvFromDate: ''
      }))
    }
  }, [historicalImportOptions.thryvInvoicesPayments])

  useEffect(() => {
    if (ongoingImportOptions.xeroClients === false) {
      setOngoingImportOptions(prevOptions => ({
        ...prevOptions,
        xeroInvoicesPayments: false
      }))
    }
  }, [ongoingImportOptions.xeroClients])

  useEffect(() => {
    if (ongoingImportOptions.thryvClients === false) {
      setOngoingImportOptions(prevOptions => ({
        ...prevOptions,
        thryvInvoicesPayments: false
      }))
    }
  }, [ongoingImportOptions.thryvClients])

  return (
    <>
      <NavBar
        title="Xero"
        showButton={settings.uninstallDisplay}
        handleUninstall={uninstall}
      />

      <ToastContainer />
      <UninstallSuccessModal
        app="Xero"
        shouldShow={showUninstallSuccessModal}
      />
      <XeroJumbotron>
        {settings.uninstallDisplay && (
          <PostInstallationContainer>
            <Header variant="h5" fontWeight="semibold">
              Our records indicate that you have installed Xero and your import
              has been completed. Please contact Thryv Support if you believe an
              error has occurred.
            </Header>
            <CenteredContainer>
              <img src={thryvGuy} alt="Thryv Guy" />
              <div>
                <ParagraphText variant="reg" color="thryv-black-500">
                  If you would like to update your historical and/or ongoing
                  import options, please click the &lsquo;Update Import
                  Settings&lsquo; button below.
                </ParagraphText>
                <Button variant="primary" onClick={showUpdateScreen}>
                  Update Import Settings
                </Button>
              </div>
            </CenteredContainer>
          </PostInstallationContainer>
        )}
        {(settings.installDisplay || settings.updateDisplay) &&
          settings.historicalSettings && (
            <Container textCentered>
              <ImportOptionsTable
                optionType="Historical"
                historicalImportOptions={historicalImportOptions}
                ongoingImportOptions={ongoingImportOptions}
                changeXeroInvoicesDate={changeXeroInvoicesDate}
                changeThryvInvoicesDate={changeThryvInvoicesDate}
                historicalDatesStaticEnabled={historicalDatesStaticEnabled}
                handleOptionChange={handleOptionChange}
              />
              <Button
                variant="primary"
                onClick={showNext}
                className="next-page-button"
                disabled={nextDisabled}
              >
                Next
              </Button>
            </Container>
          )}
        {(settings.installDisplay || settings.updateDisplay) &&
          settings.ongoingDisplay && (
            <Container textCentered>
              <ImportOptionsTable
                optionType="Ongoing"
                historicalImportOptions={historicalImportOptions}
                ongoingImportOptions={ongoingImportOptions}
                handleOptionChange={handleOptionChange}
              />
              <AddressContainer>
                <Header fontWeight="semibold" variant="h1">
                  Address
                </Header>
                <ParagraphText
                  variant="lg"
                  color="thryv-gray-500"
                  className="address-text"
                >
                  Xero offers the ability to have both a PO BOX and Street
                  Address listed. However, Thryv can only support one Address.
                  Please select the preferred address option, this option will
                  be utilized by the app in the case that both addresses are
                  listed.
                </ParagraphText>
                <RadioButtonContainer>
                  <Radio
                    hasLabel
                    id="P.O. BOX"
                    name="Address"
                    value="P.O. BOX"
                    label="P.O. BOX"
                    toggled={checkedAddressValue === '0'}
                    handleOnChange={() => setCheckedAddressValue('0')}
                  />
                  <Radio
                    hasLabel
                    id="STREET ADDRESS"
                    name="Address"
                    value="STREET ADDRESS"
                    label="STREET ADDRESS"
                    toggled={checkedAddressValue === '1'}
                    handleOnChange={() => setCheckedAddressValue('1')}
                  />
                </RadioButtonContainer>
              </AddressContainer>
              {(settings.installDisplay || settings.updateDisplay) && (
                <ButtonGroupContainer>
                  <Button variant="primary" onClick={showPrevious}>
                    Back
                  </Button>
                  <Button
                    variant="primary"
                    onClick={settings.updateDisplay ? update : createAccount}
                    disabled={checkedAddressValue === ''}
                  >
                    {settings.updateDisplay
                      ? 'Update'
                      : 'Proceed to Create Accounts'}
                  </Button>
                </ButtonGroupContainer>
              )}
              {settings.updated && (
                <ParagraphText
                  variant="lg"
                  color="thryv-gray-500"
                  className="address-text"
                >
                  Options Updated
                </ParagraphText>
              )}
            </Container>
          )}
      </XeroJumbotron>
    </>
  )
}

export default XeroSettingsOptions
