import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Avatar,
  Button,
  Header,
  ParagraphText,
  Table,
  TableContent,
  TableMenu,
  TableRow
} from '@thryvlabs/maverick'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import { LoginSocialFacebook } from 'reactjs-social-login'

import { LoaderSpinner } from '../../components/loader-spinner'
import MetaMobileCard from '../../components/meta/meta-mobile-card'
import InstallSuccessModal from '../../components/modals/install-success-modal'
import NavBar from '../../components/nav-bar/nav-bar'
import {
  AccountTableContainer,
  InstagramJumbotron,
  InstructionsContainer,
  LoginContainer,
  MobileContainer,
  OptionsBar,
  ProfileContainer,
  StyledText,
  TableHeaderContainer
} from '../../style/instagram-style'
import { authClient } from '../../util/auth-client'
import useWindowDimensions from '../../util/use-window-dimensions'

const { REACT_APP_FACEBOOK_ID } = process.env

const AccountRow = ({ id, name, category }) => (
  <>
    <StyledText>{id}</StyledText>
    <StyledText mr="1.75rem">{name}</StyledText>
    <StyledText ml="1rem">{category}</StyledText>
  </>
)

export default function InstagramNext() {
  const [login, setLogin] = useState(false)
  const [facebookUserData, setFacebookUserData] = useState({})
  const [accountInfo, setAccountInfo] = useState([])
  const [picture, setPicture] = useState('')
  const [userDataReceived, setUserDataReceived] = useState(false)
  const [payloadAccessToken, setPayloadAccessToken] = useState()
  const [successModalShow, setSuccessModalShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { user } = useAuth0()
  const axiosClient = authClient()

  const tableMenu = ['Page ID', 'Page Name', 'Category']

  const { width: windowWidth } = useWindowDimensions()

  const facebookLoginScope =
    'email,' +
    'instagram_basic,' +
    'instagram_manage_messages,' +
    'pages_manage_metadata,' +
    'pages_show_list,' +
    'public_profile,' +
    'business_management'

  const messagingScope = facebookLoginScope + ',pages_messaging'

  const getPageAccessToken = async accessToken => {
    try {
      const response = await axios.get(
        `https://graph.facebook.com/me/accounts?access_token=${accessToken}`,
        {
          headers: {
            'Cache-Control': 'no-cache',
            'Access-Control-Allow-Origin': '*'
          }
        }
      )

      setAccountInfo(response.data.data)
      setUserDataReceived(true)
    } catch (error) {
      toast.error(
        'Failed to retrieve access token. Please contact Thryv Support if this issue persists.'
      )
    }
  }

  const installInstagramApp = async () => {
    setIsLoading(true)

    const pageAccessTokens = accountInfo.map(account => ({
      page_access_token: account.access_token,
      page_name: account.name
    }))

    const payload = {
      thryvId: user.businessId,
      payloadAccessToken,
      pageAccessTokens,
      instagramUserId: facebookUserData.userID
    }

    try {
      await axiosClient({
        method: 'post',
        url: '/api/instagram/install',
        data: payload
      })
      setSuccessModalShow(true)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.error(
        'Failed to install Instagram Messenger integration. Please contact Thryv Support if this issue persists.'
      )
    }
  }

  const onLoginResolve = ({ data }) => {
    setFacebookUserData(data)
    getPageAccessToken(data.accessToken)
    setPayloadAccessToken(data.accessToken)
    setPicture(data.picture.data.url)
    setLogin(true)
  }

  const onLoginReject = () => {
    toast.error(
      'There was an error with the login process. Please contact Thryv Support if this issue persists.'
    )
  }

  return (
    <>
      <ToastContainer />
      <NavBar title={'Instagram Messenger'} />
      <InstagramJumbotron>
        {login && (
          <ProfileContainer>
            <Avatar variant="image" imageUrl={picture} size="default" />
            <ParagraphText variant="lg">
              You&apos;re logged in as:{' '}
            </ParagraphText>
            <ParagraphText variant="lg" className="login-name">
              {facebookUserData.name}
            </ParagraphText>
          </ProfileContainer>
        )}

        {login ? (
          <InstructionsContainer>
            <Header variant="h3" fontWeight="normal">
              Below are the pages you have selected to connect with Thryv.
            </Header>
            <ParagraphText variant="lg" className="text-block">
              Don&apos;t see your business page? Click on &apos;Add Pages&apos;
              followed by &apos;Edit Settings&apos; to get started. Please note
              that, due to the connection between Facebook and Instagram, only
              the Facebook page that your Business Instagram is connected to
              will appear below.
            </ParagraphText>
            <ParagraphText variant="reg" className="text-block">
              ** For this integration to work you must ensure that your
              Instagram business page is linked to a Facebook business page.
            </ParagraphText>
          </InstructionsContainer>
        ) : (
          <Header variant="h1" fontWeight="semibold" className="text-block">
            Please use the button below to login to your Facebook Business Page.
          </Header>
        )}
      </InstagramJumbotron>

      {windowWidth > 768 ? (
        <AccountTableContainer>
          {userDataReceived ? (
            <Table>
              <TableMenu>
                <TableHeaderContainer>
                  {tableMenu.map((header, i) => (
                    <Header
                      variant="h6"
                      fontWeight="semibold"
                      key={i}
                      className="column-name text-thryv-steel"
                    >
                      {header}
                    </Header>
                  ))}
                </TableHeaderContainer>
              </TableMenu>
              {userDataReceived && (
                <TableContent>
                  {accountInfo &&
                    accountInfo.map(info => (
                      <TableRow key={info.id} variant="static">
                        <AccountRow
                          id={info.id}
                          name={info.name}
                          category={info.category}
                        />
                        {login && (
                          <LoginSocialFacebook
                            appId={REACT_APP_FACEBOOK_ID}
                            fields="name,email,picture"
                            scope={facebookLoginScope}
                            onResolve={onLoginResolve}
                            onReject={onLoginReject}
                          >
                            <Button variant="text" level={1}>
                              Edit
                            </Button>
                          </LoginSocialFacebook>
                        )}
                      </TableRow>
                    ))}
                </TableContent>
              )}
            </Table>
          ) : (
            <LoginContainer>
              {!login && (
                <LoginSocialFacebook
                  appId={REACT_APP_FACEBOOK_ID}
                  fields="name,email,picture"
                  scope={facebookLoginScope}
                  onResolve={onLoginResolve}
                  onReject={onLoginReject}
                >
                  <Button variant="primary">Login with Facebook</Button>
                </LoginSocialFacebook>
              )}
            </LoginContainer>
          )}
        </AccountTableContainer>
      ) : (
        <MobileContainer>
          {userDataReceived ? (
            <>
              {accountInfo &&
                accountInfo.map(info => (
                  <MetaMobileCard
                    key={info.id}
                    id={info.id}
                    name={info.name}
                    category={info.category}
                    scope={facebookLoginScope}
                    onResolve={onLoginResolve}
                    onReject={onLoginReject}
                  />
                ))}
            </>
          ) : (
            <LoginContainer>
              {!login && (
                <LoginSocialFacebook
                  appId={REACT_APP_FACEBOOK_ID}
                  fields="name,email,picture"
                  scope={facebookLoginScope}
                  onResolve={onLoginResolve}
                  onReject={onLoginReject}
                >
                  <Button variant="primary">Login with Facebook</Button>
                </LoginSocialFacebook>
              )}
            </LoginContainer>
          )}
        </MobileContainer>
      )}

      {userDataReceived && (
        <OptionsBar>
          <LoginSocialFacebook
            appId={REACT_APP_FACEBOOK_ID}
            fields="name,email,picture"
            scope={messagingScope}
            onResolve={onLoginResolve}
            onReject={onLoginReject}
          >
            <Button variant="secondary" className="action-button">
              Add Pages
            </Button>
          </LoginSocialFacebook>
          <Button
            variant="primary"
            onClick={installInstagramApp}
            className="action-button"
          >
            Install
          </Button>
        </OptionsBar>
      )}

      <InstallSuccessModal
        app="Instagram Messenger"
        shouldShow={successModalShow}
        closeModal={() => setSuccessModalShow(false)}
      />

      {isLoading && <LoaderSpinner />}
    </>
  )
}
