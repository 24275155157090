import { Button, Header } from '@thryvlabs/maverick'

import { OptionsBar, UninstallContainer } from '../../style/instagram-style'

function UninstallApp({ uninstallApp, navigateBackToThryv }) {
  return (
    <>
      <UninstallContainer>
        <Header variant="h4" fontWeight="semibold">
          The application has already been installed. Would you like to
          uninstall?
        </Header>
        <OptionsBar>
          <Button
            variant="primary"
            onClick={uninstallApp}
            className="action-button"
          >
            Uninstall
          </Button>
          <Button
            variant="secondary"
            onClick={navigateBackToThryv}
            className="action-button"
          >
            Go Back to Thryv
          </Button>
        </OptionsBar>
      </UninstallContainer>
    </>
  )
}
export default UninstallApp
