import { useState, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Header } from '@thryvlabs/maverick'

import { LoaderSpinner } from '../../components/loader-spinner'
import { MainContainer } from '../../style/google-style'
import { authClient } from '../../util/auth-client'

const { REACT_APP_THRYV_URL } = process.env

export function GoogleAnalytics() {
  const [errorOccured, setErrorOccured] = useState(false)
  const { user } = useAuth0()
  const axiosClient = authClient()

  useEffect(() => {
    async function installGA4() {
      try {
        await axiosClient({
          url: '/api/ga4/install',
          method: 'post',
          data: { thryvId: user.businessId }
        })
        setErrorOccured(false)
        window.location.href = `${REACT_APP_THRYV_URL}/app/tracking_settings`
      } catch (err) {
        setErrorOccured(true)
      }
    }
    installGA4()
  }, [])

  return (
    <MainContainer>
      {errorOccured ? (
        <Header variant="h1" fontWeight="bold">
          Unauthorized User
        </Header>
      ) : (
        <LoaderSpinner />
      )}
    </MainContainer>
  )
}
