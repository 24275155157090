import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { toast, ToastContainer } from 'react-toastify'

import RingCentralSpamFilter from './RingCentralSpamFilter/RingCentralSpamFilter'
import Settings from './Settings/Settings'
import { LoaderSpinner } from '../../components/loader-spinner'
import InstallSuccessModal from '../../components/modals/install-success-modal'
import UninstallSuccessModal from '../../components/modals/uninstall-success-modal'
import NavBar from '../../components/nav-bar/nav-bar'
import { authClient } from '../../util/auth-client'

const UNINSTALL_MESSAGE = `Unsubscribing from the RingCentral App will delete all existing
quarantine entries and they will be unrecoverable. Please clear
your quarantine first if you still have notifications you would
like to approve.`
const BUTTON_TEXT = `Spam Filter`

const SettingsSpamFilterContainer = () => {
  const [shouldShowSuccesModal, setShouldShowSuccessModal] = useState(false)
  const [toggleSettingView, setToggleSettingView] = useState(true)
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [unsubscribed, setUnsubscribed] = useState(false)
  const [settingsOptions, setSettingsOptions] = useState({
    calllogs: null,
    contacts: null,
    quarantine: null,
    voicemail: null
  })
  const [accessToken, setAccessToken] = useState('')

  const {
    user: { businessId: thryvId }
  } = useAuth0()
  const axiosClient = authClient()

  const ringCentralServiceURL = `/api/ring-central`

  useEffect(() => {
    //checks user setting status- if any of the values are null this is the user's first visit so they must configure settings
    //otherwise, sets settings to selected options, sets should update true and redirects them to the spam filters
    const checkSettingsStatus = async () => {
      try {
        setIsLoading(true)
        const { data } = await axiosClient({
          url: `${ringCentralServiceURL}/user/${thryvId}`
        })

        const areAllSettingsNull = Object.values(data).every(
          setting => setting === null
        )

        if (areAllSettingsNull) {
          setSettingsOptions({
            calllogs: true,
            contacts: true,
            quarantine: true,
            voicemail: true
          })
        } else {
          setSettingsOptions({
            calllogs: data.calllogs,
            contacts: data.contacts,
            quarantine: data.quarantine,
            voicemail: data.voicemail
          })
          setAccessToken(data.access_token)
          setShouldUpdate(true)
          setToggleSettingView(false)
        }
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        toast.error(
          'Something went wrong while retrieving your RingCentral settings. If the problem persists please contact Thryv support.'
        )
      }
    }
    checkSettingsStatus()
  }, [])

  const handleSettingsChange = settingName => {
    setSettingsOptions(prevOptions => ({
      ...prevOptions,
      [settingName]: !prevOptions[settingName]
    }))
  }

  const submitSettings = async () => {
    const settingsObject = {
      thryvId,
      voicemail: settingsOptions.voicemail ? 1 : 0,
      contacts: settingsOptions.contacts ? 1 : 0,
      callLogs: settingsOptions.calllogs ? 1 : 0,
      quarantine: settingsOptions.quarantine ? 1 : 0,
      method: shouldUpdate ? 'update' : 'install'
    }

    try {
      setIsLoading(true)
      await axiosClient({
        method: 'patch',
        url: `${ringCentralServiceURL}/user`,
        data: settingsObject
      })
      setIsLoading(false)
      if (shouldUpdate) {
        openSpamFilter()
      } else {
        setShouldShowSuccessModal(true)
      }
    } catch (err) {
      setIsLoading(false)
      toast.error(
        'Something went wrong while updating your RingCentral settings. If the problem persists please contact Thryv support.'
      )
    }
  }

  const unSubscribeHandler = async () => {
    try {
      setIsLoading(true)
      await axiosClient({
        method: 'delete',
        url: `${ringCentralServiceURL}/user/unsubscribe/${thryvId}`
      })
      setUnsubscribed(true)
      setIsLoading(false)
    } catch (err) {
      toast.error(
        'Something went wrong while unsubscribing from RingCentral. If the problem persists please contact Thryv support.'
      )
      setIsLoading(false)
    }
  }

  const openSettingView = () => {
    setToggleSettingView(true)
  }

  const openSpamFilter = () => {
    setToggleSettingView(false)
  }

  return (
    <div>
      <ToastContainer />

      {isLoading ? (
        <LoaderSpinner />
      ) : (
        <>
          <InstallSuccessModal
            app="RingCentral"
            shouldShow={shouldShowSuccesModal}
            closeModal={() => {
              setShouldShowSuccessModal(false)
              setShouldUpdate(true)
              openSpamFilter()
            }}
          />

          <NavBar
            title={'RingCentral'}
            showButton={shouldUpdate}
            showSettingsButton={!toggleSettingView}
            showSpamFilterButton={toggleSettingView}
            handleUninstall={unSubscribeHandler}
            customMessage={UNINSTALL_MESSAGE}
            handleSettingsClick={openSettingView}
            handleSpamFilterClick={openSpamFilter}
            buttonText={BUTTON_TEXT}
            mobileFontSize={'5vw'}
          />

          {toggleSettingView ? (
            <Settings
              settingsOptions={settingsOptions}
              handleSettingsChange={handleSettingsChange}
              submitSettings={submitSettings}
            />
          ) : (
            <RingCentralSpamFilter accessToken={accessToken} />
          )}

          <UninstallSuccessModal app="RingCentral" shouldShow={unsubscribed} />
        </>
      )}
    </div>
  )
}

export default SettingsSpamFilterContainer
