import styled from '@emotion/styled'

// GoogleAnalytics.js
export const MainContainer = styled.div`
  text-align: center;

  & h2 {
    margin-top: 1rem;
    font-size: 40px;
  }
`
