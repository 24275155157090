import React from 'react'

import { Header, Button } from '@thryvlabs/maverick'

import DateSetting from './DateSetting'
import SettingsItem from './SettingsItem'
import { HeaderContainer, SettingsContainer } from '../../../style/myob-style'

const HistoricalSettings = ({
  settingsOptions,
  disableSettingsOptions,
  handleSettingsChange,
  submitSettings,
  setCurrentDateFromThryv,
  currentDateFromThryv,
  setCurrentDateFromCodat,
  currentDateFromCodat
}) => {
  const myobSettingsOptions = [
    {
      value: settingsOptions.historicalClientsFromThryv,
      disableSetting: disableSettingsOptions.historicalClientsFromThryvDisabled,
      setValue: () => handleSettingsChange('historicalClientsFromThryv'),
      header: 'Import historical clients from Thryv to MYOB AccountRight?',
      id: 'historicalClientsFromThryv',
      idNumber: 0
    },
    {
      value: settingsOptions.historicalInvoicesFromThryv,
      disableSetting:
        disableSettingsOptions.historicalInvoicesFromThryvDisabled,
      setValue: () => handleSettingsChange('historicalInvoicesFromThryv'),
      header: 'Import historical invoices from Thryv to MYOB AccountRight?',
      dateHeader:
        'A date is required in order to enable historical invoices from Thryv. Please select a date.',
      id: 'historicalInvoicesFromThryv',
      idNumber: 1
    },
    {
      value: settingsOptions.historicalPaymentsFromThryv,
      disableSetting:
        disableSettingsOptions.historicalPaymentsFromThryvDisabled,
      setValue: () => handleSettingsChange('historicalPaymentsFromThryv'),
      header: 'Import historical payments from Thryv to MYOB AccountRight?',
      id: 'historicalPaymentsFromThryv',
      idNumber: 2
    },
    {
      value: settingsOptions.historicalClientsFromCodat,
      disableSetting: disableSettingsOptions.historicalClientsFromCodatDisabled,
      setValue: () => handleSettingsChange('historicalClientsFromCodat'),
      header: 'Import historical clients from MYOB AccountRight to Thryv?',
      id: 'historicalClientsFromCodat',
      idNumber: 3
    },
    {
      value: settingsOptions.historicalInvoicesFromCodat,
      disableSetting:
        disableSettingsOptions.historicalInvoicesFromCodatDisabled,
      setValue: () => handleSettingsChange('historicalInvoicesFromCodat'),
      header: 'Import historical invoices from MYOB AccountRight to Thryv?',
      dateHeader:
        'A date is required in order to enable historical invoices from MYOB AccountRight. Please select a date.',
      id: 'historicalInvoicesFromCodat',
      idNumber: 4
    },
    {
      value: settingsOptions.historicalPaymentsFromCodat,
      disableSetting:
        disableSettingsOptions.historicalPaymentsFromCodatDisabled,
      setValue: () => handleSettingsChange('historicalPaymentsFromCodat'),
      header: 'Import historical payments from MYOB AccountRight to Thryv?',
      id: 'historicalPaymentsFromCodat',
      idNumber: 5
    }
  ]

  return (
    <>
      <SettingsContainer>
        <HeaderContainer>
          <Header fontWeight="bold" variant="h1">
            Historical Import Options
          </Header>
        </HeaderContainer>
        <div>
          {myobSettingsOptions.map(item => {
            if (item.id === 'historicalInvoicesFromThryv') {
              return (
                <React.Fragment key={item.idNumber}>
                  <SettingsItem
                    header={item.header}
                    itemValue={item.value}
                    onChangeItem={item.setValue}
                    disabled={item.disableSetting}
                  />
                  {settingsOptions.historicalInvoicesFromThryv && (
                    <DateSetting
                      currentDate={
                        item.id === 'historicalInvoicesFromThryv'
                          ? currentDateFromThryv
                          : currentDateFromCodat
                      }
                      setCurrentDate={
                        item.id === 'historicalInvoicesFromThryv'
                          ? setCurrentDateFromThryv
                          : setCurrentDateFromCodat
                      }
                      name={item.id}
                      header={item.dateHeader}
                    />
                  )}
                </React.Fragment>
              )
            } else if (item.id === 'historicalInvoicesFromCodat') {
              return (
                <React.Fragment key={item.idNumber}>
                  <SettingsItem
                    header={item.header}
                    itemValue={item.value}
                    onChangeItem={item.setValue}
                    disabled={item.disableSetting}
                  />
                  {settingsOptions.historicalInvoicesFromCodat && (
                    <DateSetting
                      currentDate={
                        item.id === 'historicalInvoicesFromCodat'
                          ? currentDateFromCodat
                          : currentDateFromThryv
                      }
                      setCurrentDate={
                        item.id === 'historicalInvoicesFromCodat'
                          ? setCurrentDateFromCodat
                          : setCurrentDateFromThryv
                      }
                      name={item.id}
                      header={item.dateHeader}
                    />
                  )}
                </React.Fragment>
              )
            } else {
              return (
                <SettingsItem
                  key={item.idNumber}
                  header={item.header}
                  itemValue={item.value}
                  onChangeItem={item.setValue}
                  disabled={item.disableSetting}
                />
              )
            }
          })}
          <Button variant="primary" onClick={() => submitSettings()}>
            Next
          </Button>
        </div>
      </SettingsContainer>
    </>
  )
}

export default HistoricalSettings
