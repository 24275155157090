import { Icon, ParagraphText } from '@thryvlabs/maverick'

import shopifyLogo from '../../assets/shopify-logo.png'
import thryvappSM from '../../assets/thryvappSM.png'
import { HeaderContainer, LogoContainer } from '../../style/landing-page-style'
import {
  ThryvLogo,
  ShopifyLogo,
  ShopifySettingsText
} from '../../style/shopify-style'

const ShopifySettings = () => {
  const params = new URLSearchParams(window.location.search)
  const status = params.get('status')

  return (
    <>
      <HeaderContainer>
        <LogoContainer>
          <ThryvLogo src={thryvappSM} />
          <Icon type="solid" variant="plus" className="plus-sign" />
          <ShopifyLogo src={shopifyLogo} />
        </LogoContainer>
      </HeaderContainer>
      {status === 'new' ? (
        <ShopifySettingsText>
          <ParagraphText variant="lg">
            Your Shopify app is syncing with Thryv. You will receive an email
            notification and inbox message from a <b>Thryv Specialist</b> when
            the sync is complete.
          </ParagraphText>
        </ShopifySettingsText>
      ) : (
        <ShopifySettingsText>
          <ParagraphText variant="lg">
            Your Shopify app is installed.
          </ParagraphText>
          <ParagraphText variant="lg">
            To be taken to your Thryv account, click{' '}
            <a href="https://go.thryv.com/app/dashboard">here.</a>
          </ParagraphText>
          <ParagraphText variant="lg">
            If you would like to uninstall it, please navigate to your Shopify
            account.
          </ParagraphText>
          <ParagraphText variant="lg">
            For assistance and guidance, please reference our Shopify
            integration guide{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://learn.thryv.com/hc/en-us/articles/360046260572-Shopify"
            >
              here.
            </a>
          </ParagraphText>
        </ShopifySettingsText>
      )}
    </>
  )
}

export default ShopifySettings
