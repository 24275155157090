import signaturesLogo from '../../assets/Thryv_Signatures_Lockup_RGB.png'
import LandingPage from '../../components/landing-page/landing-page'
import { signaturesIconStyle } from '../../style/app-icon-style'

const Signatures = () => {
  let { REACT_APP_SIGNATURES_REDIRECT } = process.env

  const handleClick = e => {
    e.preventDefault()
    window.location.href = REACT_APP_SIGNATURES_REDIRECT
  }

  const signaturesSections = [
    {
      header: 'What will this application do?',
      statements: [
        'Signatures is an integrated eSignature for Thryv. Signatures allows business to send signature requests to clients that are templatized, secure, and timely.',
        'It effortlessly reminds signers about pending requests and upon completion organizes signed documents into client profiles for easy access.',
        'Thryv Signatures is a powerful efficiency tool that allows you to effortlessly request tamper-proof signatures on documents in a safe and secure way.'
      ]
    }
  ]

  return (
    <LandingPage
      sections={signaturesSections}
      onSubmit={handleClick}
      appName={'Signatures'}
      appIconSource={signaturesLogo}
      appIconStyle={signaturesIconStyle}
      showThryvIcon={false}
    />
  )
}

export default Signatures
