import { useEffect, useState } from 'react'

import { Button, Header, Select } from '@thryvlabs/maverick'
import { useNavigate, useLocation } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import InstallSuccessModal from '../../components/modals/install-success-modal'
import NavBar from '../../components/nav-bar/nav-bar'
import {
  AccountExistsCard,
  SelectionDetails,
  SuccessCard
} from '../../components/xero/xero-components'
import { Container } from '../../style/containers-style'
import {
  AccountCreateButtonContainer,
  AccountCreationMessageContainer,
  AccountHeaderContainer,
  AccountRowContainer,
  AccountSectionContainer,
  SelectContainer,
  XeroJumbotron
} from '../../style/xero-style'
import { authClient } from '../../util/auth-client'

const xeroServiceURL = '/api/xero'

const XeroAccountCreate = () => {
  const [accountMessages, setAccountMessages] = useState({
    invoiceSuccess: false,
    invoiceExists: false,
    paymentSuccess: false,
    paymentExists: false
  })
  const [installEnable, setInstallEnable] = useState(false)
  const [invoiceAccountsOptions, setInvoiceAccountsOptions] = useState([])
  const [paymentAccountsOptions, setPaymentAccountsOptions] = useState([])
  const [selectedInvoiceAccount, setSelectedInvoiceAccount] = useState({
    name: '',
    value: '',
    accountID: ''
  })
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState({
    name: '',
    value: '',
    accountID: ''
  })
  const [showInstallSuccessModal, setShowInstallSuccessModal] = useState(false)

  const { state } = useLocation()
  const navigate = useNavigate()
  const axiosClient = authClient()

  const {
    payload: {
      tenantId,
      currency,
      xeroRefreshToken,
      thryvId,
      checkedHistoricalClients,
      checkedHistoricalClientsFromThryv,
      checkedHistoricalInvoicePayment,
      checkedHistoricalInvoicesPaymentsThryv,
      checkedOngoingClients,
      checkedOngoingClientsThryv,
      checkedOngoingInvoicesPayments,
      checkedOngoingInvoicesPaymentsThryv,
      checkedAddressValue,
      syncFromDateThryv,
      syncFromDateXero
    } = {}
  } = state || {}

  const handleAccountCreationMessages = key => {
    setAccountMessages(prevSettings => ({
      ...prevSettings,
      [key]: !prevSettings[key]
    }))
  }

  const installXero = async () => {
    const payload = {
      historicalClientsValue: checkedHistoricalClients,
      historicalClientsFromThryvValue: checkedHistoricalClientsFromThryv,
      historicalInvoicePaymentValue: checkedHistoricalInvoicePayment,
      historicalInvoicesPaymentsThryvValue:
        checkedHistoricalInvoicesPaymentsThryv,
      ongoingClientsValue: checkedOngoingClients,
      ongoingClientsThryvValue: checkedOngoingClientsThryv,
      ongoingInvoicesPaymentsValue: checkedOngoingInvoicesPayments,
      ongoingInvoicesPaymentsThryvValue: checkedOngoingInvoicesPaymentsThryv,
      address: checkedAddressValue,
      invoiceAccountId: selectedInvoiceAccount.value,
      paymentAccountId: selectedPaymentAccount.accountID,
      syncFromDateThryv,
      syncFromDateXero,
      tenantId,
      currency,
      xeroRefreshToken,
      thryvId
    }

    try {
      await axiosClient({
        method: 'post',
        url: `${xeroServiceURL}/install`,
        data: payload
      })
      setShowInstallSuccessModal(true)
    } catch (err) {
      toast.error(
        'Something went wrong while installing Xero. If the problem persists please contact Thryv support.'
      )
    }
  }

  const createXeroAccount = async accountName => {
    const payload = {
      thryvId,
      tenantId,
      xeroRefreshToken,
      accountName
    }

    try {
      const {
        data: { accountId, alreadyExists, code }
      } = await axiosClient({
        method: 'post',
        url: `${xeroServiceURL}/create-account`,
        data: payload
      })

      const isInvoice = accountName === 'Thryv Invoices'
      const setAccountsOptions = isInvoice
        ? setInvoiceAccountsOptions
        : setPaymentAccountsOptions
      const handleAcctChange = isInvoice
        ? setSelectedInvoiceAccount
        : setSelectedPaymentAccount

      const newAccount = {
        name: accountName,
        value: code,
        accountID: accountId
      }

      setAccountsOptions(prevAccounts => [...prevAccounts, newAccount])
      handleAcctChange(newAccount)

      if (alreadyExists) {
        handleAccountCreationMessages(
          accountName === 'Thryv Invoices' ? 'invoiceExists' : 'paymentExists'
        )
      }
      if (!alreadyExists) {
        handleAccountCreationMessages(
          accountName === 'Thryv Invoices' ? 'invoiceSuccess' : 'paymentSuccess'
        )
      }
    } catch (err) {
      toast.error(
        'Something went wrong while creating a Xero account. If the problem persists please contact Thryv support.'
      )
    }
  }

  useEffect(() => {
    async function getInvoicePaymentAccounts() {
      try {
        const { data } = await axiosClient(
          `${xeroServiceURL}/invoice-payment/${tenantId}/${xeroRefreshToken}/${thryvId}`
        )

        const invoiceAccountsArray = data.invoiceAccounts.map(
          invoiceAccount => ({
            name: invoiceAccount.Name,
            value: invoiceAccount.Code,
            accountID: invoiceAccount.AccountID
          })
        )
        const paymentAccountsArray = data.paymentAccounts.map(
          paymentAccount => ({
            name: paymentAccount.Name,
            value: paymentAccount.Code,
            accountID: paymentAccount.AccountID
          })
        )

        setInvoiceAccountsOptions(invoiceAccountsArray)
        setPaymentAccountsOptions(paymentAccountsArray)
      } catch (err) {
        toast.error(
          'Something went wrong while getting payment and invoice accounts. If the problem persists please contact Thryv support.'
        )
      }
    }
    getInvoicePaymentAccounts()
  }, [])

  useEffect(() => {
    if (selectedInvoiceAccount && selectedPaymentAccount) {
      setInstallEnable(true)
    }
  }, [selectedInvoiceAccount, selectedPaymentAccount])

  return (
    <>
      <InstallSuccessModal
        app="Xero"
        shouldShow={showInstallSuccessModal}
        closeModal={() => setShowInstallSuccessModal(false)}
      />
      <NavBar title="Xero" />
      <Container pb="2rem">
        <ToastContainer />
        <XeroJumbotron>
          <AccountHeaderContainer>
            <Header fontWeight="bold" variant="h6">
              Please select the sales and payment accounts Thryv will use for
              posting transactions. Please contact your accountant if you need
              assisstance.
            </Header>
          </AccountHeaderContainer>
          <AccountSectionContainer>
            <Header fontWeight="bold" variant="h6">
              Invoices
            </Header>
            <AccountRowContainer>
              <SelectionDetails
                optionName="Invoices"
                createXeroAccount={createXeroAccount}
              />
              <SelectContainer>
                <Select
                  options={invoiceAccountsOptions}
                  selectLabel="Select Invoice Account"
                  width="full"
                  selectedOption={selectedInvoiceAccount}
                  setSelectedOption={setSelectedInvoiceAccount}
                />
              </SelectContainer>
              <AccountCreationMessageContainer>
                {accountMessages.invoiceSuccess && (
                  <SuccessCard option="Invoices" />
                )}
                {accountMessages.invoiceExists && (
                  <AccountExistsCard option="Invoices" />
                )}
              </AccountCreationMessageContainer>
            </AccountRowContainer>
          </AccountSectionContainer>
          <AccountSectionContainer>
            <Header fontWeight="bold" variant="h6">
              Payments
            </Header>
            <AccountRowContainer>
              <SelectionDetails
                optionName="Payments"
                createXeroAccount={createXeroAccount}
              />
              <SelectContainer>
                <Select
                  options={paymentAccountsOptions}
                  selectLabel="Select Payment Account"
                  width="full"
                  selectedOption={selectedPaymentAccount}
                  setSelectedOption={setSelectedPaymentAccount}
                />
              </SelectContainer>
              <AccountCreationMessageContainer>
                {accountMessages.paymentSuccess && (
                  <SuccessCard option="Payments" />
                )}
                {accountMessages.paymentExists && (
                  <AccountExistsCard option="Payments" />
                )}
              </AccountCreationMessageContainer>
            </AccountRowContainer>
          </AccountSectionContainer>
          <AccountCreateButtonContainer>
            <Button variant="primary" onClick={() => navigate(-1)}>
              Back
            </Button>
            <Button
              variant="primary"
              onClick={installXero}
              disabled={!installEnable}
            >
              Install
            </Button>
          </AccountCreateButtonContainer>
        </XeroJumbotron>
      </Container>
    </>
  )
}

export default XeroAccountCreate
