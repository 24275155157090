import {
  Header,
  ParagraphText,
  Table,
  TableContent,
  TableMenu,
  TableRow
} from '@thryvlabs/maverick'

import { ContentBox, RowContainer } from '../../style/xero-style'
import { SwitchButton } from '../switch-button'

const ImportOptionsTable = ({
  optionType,
  historicalImportOptions,
  ongoingImportOptions,
  changeXeroInvoicesDate,
  changeThryvInvoicesDate,
  historicalDatesStaticEnabled,
  handleOptionChange
}) => {
  const historicalOptions = [
    {
      key: 0,
      question: 'Import Historical Clients from Xero?',
      detail:
        '*Recommended Sync: Ensures the seamless sync of future transactions for past Clients',
      defaultChecked: historicalImportOptions.xeroClients,
      onChange: () => handleOptionChange('historical', 'xeroClients'),
      invoicesAndPayments: {
        question: 'Import Historical Invoices and Payments from Xero?',
        detail:
          '*Note: You must import Clients from Xero in order to import their Invoices and Payments',
        defaultChecked: historicalImportOptions.xeroInvoicesPayments,
        onChange: () =>
          handleOptionChange('historical', 'xeroInvoicesPayments'),
        dateValueChange: changeXeroInvoicesDate,
        syncDateData: historicalImportOptions.syncXeroFromDate
      }
    },
    {
      key: 1,
      question: 'Import Historical Clients from Thryv?',
      detail:
        '*Recommended Sync: Ensures the seamless sync of future transactions for past Clients',
      defaultChecked: historicalImportOptions.thryvClients,
      onChange: () => handleOptionChange('historical', 'thryvClients'),
      invoicesAndPayments: {
        question: 'Import Historical Invoices and Payments from Thryv?',
        detail:
          '*Note: You must import Clients from Thryv in order to import their Invoices and Payments',
        defaultChecked: historicalImportOptions.thryvInvoicesPayments,
        onChange: () =>
          handleOptionChange('historical', 'thryvInvoicesPayments'),
        dateValueChange: changeThryvInvoicesDate,
        syncDateData: historicalImportOptions.syncThryvFromDate
      }
    }
  ]

  const ongoingOptions = [
    {
      key: 0,
      question: 'Import Clients from Xero?',
      detail:
        '*Recommended Sync: Ensures the seamless sync of future transactions for new Clients',
      defaultChecked: ongoingImportOptions.xeroClients,
      onChange: () => handleOptionChange('ongoing', 'xeroClients'),
      invoicesAndPayments: {
        question: 'Import Invoices and Payments from Xero?',
        detail:
          '*Note: You must import Clients from Xero in order to import their Invoices and Payments',
        defaultChecked: ongoingImportOptions.xeroInvoicesPayments,
        onChange: () => handleOptionChange('ongoing', 'xeroInvoicesPayments')
      }
    },
    {
      key: 1,
      question: 'Import Clients from Thryv?',
      detail:
        '*Recommended Sync: Ensures the seamless sync of future transactions for new Clients',
      defaultChecked: ongoingImportOptions.thryvClients,
      onChange: () => handleOptionChange('ongoing', 'thryvClients'),
      invoicesAndPayments: {
        question: 'Import Invoices and Payments from Thryv?',
        detail:
          '*Note: You must import Clients from Thryv in order to import their Invoices and Payments',
        defaultChecked: ongoingImportOptions.thryvInvoicesPayments,
        onChange: () => handleOptionChange('ongoing', 'thryvInvoicesPayments')
      }
    }
  ]

  return (
    <Table>
      <TableMenu>
        <Header fontWeight="light" variant="h1">
          {optionType} Import Options
        </Header>
      </TableMenu>
      {optionType === 'Historical' && (
        <TableContent>
          {historicalOptions.map(option => (
            <div key={option.key}>
              <RowContainer>
                <TableRow variant="static">
                  <ContentBox>
                    <Header fontWeight="semibold" variant="h6">
                      {option.question}
                    </Header>
                    <ParagraphText variant="sm">{option.detail}</ParagraphText>
                  </ContentBox>
                  <SwitchButton
                    defaultValue={option.defaultChecked}
                    changeFunc={option.onChange}
                  />
                </TableRow>
              </RowContainer>
              {option.defaultChecked === true && (
                <RowContainer>
                  <TableRow variant="static">
                    <ContentBox>
                      <Header fontWeight="semibold" variant="h6">
                        {option.invoicesAndPayments.question}
                      </Header>
                      <ParagraphText variant="sm">
                        {option.invoicesAndPayments.detail}
                      </ParagraphText>
                      {(historicalDatesStaticEnabled &&
                        historicalImportOptions.syncThryvFromDate !== '') ||
                      (historicalDatesStaticEnabled &&
                        historicalImportOptions.syncXeroFromDate) ? (
                        <>
                          <Header variant="h6" fontWeight="light">
                            Previously Selected Date :
                          </Header>
                          <input
                            type="text"
                            value={option.invoicesAndPayments.syncDateData}
                            readOnly
                          />
                        </>
                      ) : (historicalImportOptions.xeroInvoicesPayments &&
                          option.invoicesAndPayments.dateValueChange ===
                            changeXeroInvoicesDate) ||
                        (historicalImportOptions.thryvInvoicesPayments &&
                          option.invoicesAndPayments.dateValueChange ===
                            changeThryvInvoicesDate) ? (
                        <>
                          {!option.invoicesAndPayments.syncDateData && (
                            <ParagraphText
                              variant="sm"
                              color="thryv-orange-500"
                            >
                              Enter the date you would like transactions
                              imported from.
                            </ParagraphText>
                          )}
                          <input
                            type="date"
                            onChange={
                              option.invoicesAndPayments.dateValueChange
                            }
                            defaultValue={
                              option.invoicesAndPayments.syncDateData
                            }
                            className="date-input"
                            data-testid={
                              option.invoicesAndPayments.question.includes(
                                'Xero'
                              )
                                ? 'xero-date-input'
                                : 'thryv-date-input'
                            }
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </ContentBox>
                    <SwitchButton
                      defaultValue={option.invoicesAndPayments.defaultChecked}
                      changeFunc={option.invoicesAndPayments.onChange}
                    />
                  </TableRow>
                </RowContainer>
              )}
            </div>
          ))}
        </TableContent>
      )}
      {optionType === 'Ongoing' && (
        <TableContent>
          {ongoingOptions.map(option => (
            <div key={option.key}>
              <RowContainer>
                <TableRow variant="static">
                  <ContentBox>
                    <Header fontWeight="semibold" variant="h6">
                      {option.question}
                    </Header>
                    <ParagraphText variant="sm">{option.detail}</ParagraphText>
                  </ContentBox>
                  <SwitchButton
                    defaultValue={option.defaultChecked}
                    changeFunc={option.onChange}
                  />
                </TableRow>
              </RowContainer>
              {option.defaultChecked === true && (
                <RowContainer>
                  <TableRow variant="static">
                    <ContentBox>
                      <Header fontWeight="semibold" variant="h6">
                        {option.invoicesAndPayments.question}
                      </Header>
                      <ParagraphText variant="sm">
                        {option.invoicesAndPayments.detail}
                      </ParagraphText>
                    </ContentBox>
                    <SwitchButton
                      defaultValue={option.invoicesAndPayments.defaultChecked}
                      changeFunc={option.invoicesAndPayments.onChange}
                    />
                  </TableRow>
                </RowContainer>
              )}
            </div>
          ))}
        </TableContent>
      )}
    </Table>
  )
}

export default ImportOptionsTable
