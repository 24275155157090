import { useNavigate } from 'react-router-dom'

import googleSheetsLogo from '../../assets/google-sheets-logo.png'
import LandingPage from '../../components/landing-page/landing-page'
import { appIconStyle } from '../../style/app-icon-style'

const GoogleSheets = () => {
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/google-sheets-settings')
  }
  const googleSheetsSections = [
    {
      header: 'What will this application need access to?',
      statements: [
        'Your User details',
        'Client details',
        'Product details',
        'Booking details',
        'Estimates, Invoices and Payment details'
      ]
    },

    {
      header: 'What will this application do?',
      statements: [
        'Ability to add new and update existing Clients in Thryv.',
        'Ability to add a new Product in Thryv.',
        'Ability to add a Booking in Thryv.',
        'Ability to add an Estimate, Invoice and/or Payment in Thryv.',
        'Ability to email Estimates, Invoices, and/or Payment receipts.',
        'Export Clients, Products, Bookings, Estimates, Invoices and Payments.'
      ]
    },

    {
      header: 'Instructions:',
      statements: [
        'To ensure a smooth upload, remove all extra formatting (like whitespace, merged cells, images, and tables), use the first row for headers only (no titles or graphics), and ensure the first column has data for upload without blanks. To learn more, click '
      ],
      link: {
        text: 'here.',
        url: 'https://learn.thryv.com/hc/en-us/articles/22290901708301-Google-Sheets'
      }
    }
  ]

  return (
    <LandingPage
      sections={googleSheetsSections}
      onSubmit={handleClick}
      appName="Google Sheets"
      appIconSource={googleSheetsLogo}
      appIconStyle={appIconStyle}
    />
  )
}

export default GoogleSheets
