import styled from '@emotion/styled'

// CloverRegister.js
export const Wrapper = styled.div`
  padding: 0 1rem;
  margin-bottom: 3rem;

  & h3 {
    text-align: center;
  }
`
export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`
export const InputContainer = styled.div`
  width: 100%;
  padding-top: 1rem;
`
export const ErrorText = styled.div`
  color: red;
  font-size: 0.9rem;
  line-height: 1.6;
`
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3px auto 0;
  padding: 0 1rem;
  width: 30%;

  @media (max-width: 992px) {
    width: 100%;
  }
  & form {
    width: 100%;
  }
  & input {
    width: 100%;
  }
  & button {
    padding: 0.5rem;
    width: 75%;
    margin-top: 1rem;
  }
`
export const RegisteredWrapper = styled.div`
  margin: 3rem auto 0;
  padding: 0 15px;
  width: 60%;
  text-align: center;

  @media (max-width: 768px) {
    width: 95%;

    & h2 {
      font-size: 1.25rem;
    }
  }
`
export const Image = styled.img`
  margin: 3rem auto 0;
  padding: 0 15px;
`

// CloverError.js
export const ErrorContainer = styled.div`
  width: 100%;
  padding: 0 15px;
  margin: 0 auto 3rem auto;
  box-sizing: border-box;
`
export const ErrorHeaderContainer = styled.div`
  width: 80%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  box-sizing: border-box;

  & p {
    margin-top: 1rem;
    font-size: 1.25rem;
    font-weight: 600;
  }
  @media (max-width: 768px) {
    width: 95%;
  }
`
export const ErrorTableContainer = styled.div`
  box-sizing: border-box;
  width: 80%;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
`
export const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`
export const TableCell = styled.div`
  width: 25%;

  & h6 {
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    line-height: 1.1;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    color: rgba(128, 128, 128, var(--tw-text-opacity));
    --tw-text-capacity: 1;
  }
`

// CloverError.js Mobile
export const MobileContainer = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  & h3 {
    margin-top: 2rem;
  }
`
export const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin: 2vw;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`
export const CardHeader = styled.div`
  background-color: #f7f7f7;
  padding: 2vh;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h2 {
    margin: 0;
    color: #333;
  }
`
export const CardContent = styled.div`
  padding: 2vh;
`
export const CardField = styled.div`
  display: flex;
  gap: 5px;
  padding: 0.5vh;
`
export const TextBox = styled.span`
  font-size: 0.85rem;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
`

// CloverSynced.js
export const SyncedContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 150px;
`
export const SyncedRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
`
export const SyncedColumn = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  & p {
    font-size: 1.25rem;
    margin-top: 3rem;
    text-align: center;
  }
  & a {
    color: #ff0000;
  }
  & a:hover {
    color: #e64f0a;
  }
  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (max-width: 768px) {
    & p {
      margin-top: 1.25rem;
    }
  }
`

// CloverSettings.js
export const SettingsContainer = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 3rem;
  text-align: center;

  & h2 {
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    width: 95%;
    margin-top: 1rem;

    & h2 {
      font-size: 1.25rem;
    }
  }
`
