import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import { LoaderSpinner } from '../../components/loader-spinner'
import { LogoContainer } from '../../style/landing-page-style'
import { authClient } from '../../util/auth-client'

const { REACT_APP_URL } = process.env

const XeroSettings = () => {
  const navigate = useNavigate()
  const axiosClient = authClient()

  useEffect(() => {
    async function getXeroAuthTokens() {
      const code = new URLSearchParams(window.location.search).get('code')

      const payload = {
        redirectURI: REACT_APP_URL,
        code
      }

      try {
        const {
          data: { access_token, refresh_token }
        } = await axiosClient({
          method: 'post',
          url: '/api/xero/new-tokens',
          data: payload
        })
        navigate('/xero-settings-options', {
          state: {
            xeroAuth: {
              xeroAuthToken: access_token,
              xeroRefreshToken: refresh_token
            }
          }
        })
      } catch (error) {
        toast.error(
          `There was an error fetching Xero Authentication: ${error.message}. Please contact Thryv Support if this issue persists.`
        )
      }
    }
    getXeroAuthTokens()
  }, [])

  return (
    <>
      <ToastContainer />
      <LogoContainer>
        <LoaderSpinner />
      </LogoContainer>
    </>
  )
}
export default XeroSettings
