import { BrowserRouter as Router } from 'react-router-dom'

import Auth0ProviderWithRedirectCallback from '../components/AuthenticatedApps/context/Auth0ProviderWithRedirectCallback'

function AppProviders({ children }) {
  return (
    <Router>
      <Auth0ProviderWithRedirectCallback
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        useRefreshTokens
        useRefreshTokensFallback={true}
        cacheLocation="localstorage"
      >
        {children}
      </Auth0ProviderWithRedirectCallback>
    </Router>
  )
}
export { AppProviders }
