import styled from '@emotion/styled'

export const HeaderContainer = styled.div`
  clear: both;
  padding-top: 50px;
  padding-bottom: 20px;
  text-align: center;
  margin-bottom: 30px;
`
export const SettingsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  & > div {
    text-align: center;
    flex: 0 0 83.333%;
    max-width: 83.333%;
  }
`

export const SettingsRowContainer = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  justify-content: center;
  text-align: left;
  margin-left: 10vw;

  @media (max-width: 768px) {
    margin-left: 0;
    grid-template-columns: 90% 10%;
    gap: 5vw;
  }
`

export const SettingsDetailsContainer = styled.ul`
  list-style-type: disc;
  margin-left: 35px;
  padding: 2px;

  @media (max-width: 768px) {
    margin-left: 4vw;
  }
`
export const WarningText = styled.p`
  font-weight: bold;
  color: red;
  margin-top: 2px;
`
export const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: black;
  color: white;
  font-family: 'Montserrat', sans-serif;
`

export const NavbarTitle = styled.span`
  font-size: 1.2rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`

export const NavbarButtonGroup = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1vh;
  }
`
export const NavbarButton = styled.button`
  padding: 0.3rem 0.8rem;
  background-color: black;
  color: white;
  cursor: pointer;
  outline: none;

  &:first-of-type {
    margin-left: 0;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    border: 1px solid white;
    border-radius: 8px;
    width: 35vw;
  }
`
export const NavbarSeparator = styled.div`
  margin: 0 0.5rem;
  color: white;

  @media (max-width: 768px) {
    display: none;
  }
`

export const SpamFilterContainer = styled.div`
  margin: 5vh 10vw 5vh;

  @media (max-width: 768px) {
    margin: 5vh 5vw 1vh;
  }
`

export const SpamHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4vh;
`

export const EightColumnTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr 1fr 2fr 1fr 2fr;
  overflow-x: auto;
  grid-gap: 14px;
  width: 100%;
`

export const TableCell = styled.div`
  white-space: nowrap;
  overflow: scroll;
  align-self: end;
`
export const NoCallsContianer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5vh;
`
export const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: -8px;
`

export const EditFieldsWrapper = styled.div`
  display: flex;
  gap: 4px;
`
export const EditFieldsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1vw;
  padding-right: 1vw;

  @media (max-width: 768px) {
    align-items: center;
  }
`

export const InputWrapper = styled.div`
  margin-top: 30px;
  padding: 15px;

  & input {
    width: 20vw;

    @media (max-width: 768px) {
      width: 95%;
      font-size: 18px;
    }
  }
`

export const NegativeBottomMargin = styled.div`
  margin-bottom: -1.5vh;
`
export const SpamFooterRow = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 2vh;
  margin-right: 5vw;
`

export const TextCenter = styled.div`
  text-align: center;
`
