/* From Twilio: `The Queue resource describes a call queue that
contains individual calls . . .` The queue is screened by the
client here; each call in the queue can be released -- allowed
through -- or deleted -- marked as spam */

import { useEffect, useState } from 'react'

import { Button } from '@thryvlabs/maverick'
import { ToastContainer, toast } from 'react-toastify'

import UpdateSuccessModal from '../../components/modals/update-success-modal'
import EditEmailModal from '../../components/twilio/edit-email-modal'
import EditNameModal from '../../components/twilio/edit-name-modal'
import TwilioSpamFilterTable from '../../components/twilio/spam-filter-table'
import SubmitQueueSuccessModal from '../../components/twilio/submit-queue-success-modal'
import { SubmitButton } from '../../style/twilio-style'
import { authClient } from '../../util/auth-client'

export default function TwilioSpamFilter({ twilioUser }) {
  const [pageState, setPageState] = useState({
    showEditNameModal: false,
    showEditEmailModal: false,
    showUpdateSuccessModal: false,
    showQueueSubmitSuccessModal: false
  })
  const [selectedField, setSelectedField] = useState({})
  const [userQueue, setUserQueue] = useState([])
  const [callsToSubmit, setCallsToSubmit] = useState([])

  const axiosClient = authClient()
  const thryvId = twilioUser.thryvId

  // handles page state for displaying success or edit modals
  const updatePageState = updates => {
    setPageState(prevState => ({
      ...prevState,
      ...updates
    }))
  }

  // updates the callsToSubmit state array when a 'Release' or 'Delete' radio button is pressed
  const handleCallAction = call => {
    setCallsToSubmit(previous => [
      // in the event that the user switches radio button options, filter the previous call
      ...previous.filter(previousCall => previousCall.callSid !== call.callSid),
      call
    ])
  }

  // handles opening the edit email or edit name modals
  const handleFieldSelection = field => {
    if ('email' in field) {
      setSelectedField(field)
      updatePageState({ showEditEmailModal: true })
    }
    if ('first_name' in field || 'last_name' in field) {
      setSelectedField(field)
      updatePageState({ showEditNameModal: true })
    }
  }

  // getUserQueue checks the database by thryvId for a user's call queue, returned as queues
  const getUserQueue = async () => {
    try {
      const {
        data: { queues }
      } = await axiosClient(`/api/twilio/queues/${thryvId}`)
      if (queues) {
        setUserQueue(queues)
      }
    } catch (error) {
      toast.error(`
        An error occurred while fetching your Twilio spam call queue. 
        Please contact Thryv Support if the issue persists.
      `)
    }
  }

  // updateCall makes changes to a single row in the Queue table
  const updateCall = async field => {
    const callSid = selectedField.callsid
    try {
      await axiosClient({
        method: 'PATCH',
        url: `/api/twilio/queues/${callSid}`,
        data: field
      })

      setUserQueue(prevState =>
        prevState.map(call =>
          call.callsid === callSid
            ? {
                ...call,
                ...(field.email && { email: field.email }),
                ...(field.firstName && { first_name: field.firstName }),
                ...(field.lastName && { last_name: field.lastName })
              }
            : call
        )
      )
      updatePageState({ showUpdateSuccessModal: true })
    } catch (error) {
      toast.error(`
        An error occurred while updating the call in your spam filter.
        Please contact Thryv Support if the issue persists.
      `)
    }
    updatePageState(
      field.email ? { showEditEmailModal: false } : { showEditNameModal: false }
    )
  }

  // submitQueue sends callsToSubmit array of calls marked release or delete to trayapp
  const submitQueue = async () => {
    try {
      const payload = {
        thryvId,
        calls: callsToSubmit
      }
      await axiosClient({
        method: 'POST',
        url: '/api/twilio/queues/configure',
        data: payload
      })
      updatePageState({ showQueueSubmitSuccessModal: true })
      await getUserQueue()
    } catch (err) {
      toast.error(`
        An error occurred while submitting your spam filter queue.
        Please contact Thryv Support if the issue persists.
      `)
    }
  }

  useEffect(() => {
    getUserQueue()
  }, [])

  return (
    <>
      <ToastContainer />
      <TwilioSpamFilterTable
        getUserQueue={getUserQueue}
        userQueue={userQueue}
        handleCallAction={handleCallAction}
        handleFieldSelection={handleFieldSelection}
      />
      <SubmitButton>
        <Button
          variant="primary"
          onClick={submitQueue}
          disabled={callsToSubmit.length === 0}
        >
          Submit Queue
        </Button>
      </SubmitButton>
      {pageState.showEditEmailModal && (
        <EditEmailModal
          closeModal={() => updatePageState({ showEditEmailModal: false })}
          onSave={updateCall}
        />
      )}
      {pageState.showEditNameModal && (
        <EditNameModal
          onSave={updateCall}
          closeModal={() => updatePageState({ showEditNameModal: false })}
        />
      )}
      {pageState.showUpdateSuccessModal && (
        <UpdateSuccessModal
          closeModal={() => updatePageState({ showUpdateSuccessModal: false })}
        />
      )}
      {pageState.showQueueSubmitSuccessModal && (
        <SubmitQueueSuccessModal
          closeModal={() =>
            updatePageState({ showQueueSubmitSuccessModal: false })
          }
        />
      )}
    </>
  )
}
