import { useState } from 'react'

import {
  Button,
  ParagraphText,
  Header,
  Icon,
  Toggle,
  Select
} from '@thryvlabs/maverick'

import {
  HoursContainer,
  HeaderContainer,
  ButtonNavContainer,
  CardsContainer,
  Card,
  CardHeader,
  CardContent,
  ErrorMessage,
  MobileHoursContainer
} from '../../../style/gbm-style'
import { HOURS } from '../constants'
import {
  handleOpenClose,
  handleOpenTime,
  handleOpenMeridiem,
  handleCloseTime,
  handleCloseMeridiem
} from '../hoursHelpers'

function DayCard({ day, setBusinessHours }) {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <Card key={day.day}>
      <CardHeader
        backgroundColor={day.open ? 'white' : 'LightGray'}
        onClick={handleToggleCollapse}
      >
        <Header fontWeight="normal" variant="h5">
          {day.day}
        </Header>
        <Icon
          type="solid"
          variant={isCollapsed ? 'chevronDown' : 'chevronUp'}
          color="#000000"
        />
      </CardHeader>
      {!isCollapsed && (
        <CardContent>
          <Toggle
            label={day.open ? 'Open' : 'Closed'}
            toggleSize="normal"
            onChange={() => {
              handleOpenClose(setBusinessHours, day.day)
            }}
            toggled={day.open}
          />
          {day.open && (
            <MobileHoursContainer>
              <ParagraphText variant="reg">Opening Hour:</ParagraphText>
              <Select
                options={HOURS}
                selectLabel="Opening Hour"
                selectedOption={day.openTime}
                setSelectedOption={newTime => {
                  handleOpenTime(newTime, setBusinessHours, day.day)
                }}
                width="md"
              />
              <Select
                options={[
                  { name: 'AM', value: 'AM' },
                  { name: 'PM', value: 'PM' }
                ]}
                selectLabel="Meridiem"
                selectedOption={day.openMeridiem}
                setSelectedOption={newMeridiem => {
                  handleOpenMeridiem(newMeridiem, setBusinessHours, day.day)
                }}
                width="md"
              />
              <ParagraphText variant="reg">Closing Hour:</ParagraphText>
              <Select
                options={HOURS}
                selectLabel="Closing Hour"
                selectedOption={day.closeTime}
                setSelectedOption={newTime => {
                  handleCloseTime(newTime, setBusinessHours, day.day)
                }}
                width="md"
              />
              <Select
                options={[
                  { name: 'AM', value: 'AM' },
                  { name: 'PM', value: 'PM' }
                ]}
                selectLabel="Meridiem"
                selectedOption={day.closeMeridiem}
                setSelectedOption={newMeridiem => {
                  handleCloseMeridiem(newMeridiem, setBusinessHours, day.day)
                }}
                width="md"
              />
              {!day.openBeforeClose && (
                <ErrorMessage>
                  Invalid hours: Closing before Opening
                </ErrorMessage>
              )}
            </MobileHoursContainer>
          )}
        </CardContent>
      )}
    </Card>
  )
}

function Hours({
  nextStep,
  prevStep,
  businessHours,
  setBusinessHours,
  hoursErrors
}) {
  return (
    <HoursContainer>
      <HeaderContainer>
        <Header fontWeight="bold" variant="h3">
          GBM Hours Selection
        </Header>
        <ParagraphText variant="sm">Step 2 of 3</ParagraphText>
        <ParagraphText variant="reg">
          Please provide the hours and days that your business is open.
        </ParagraphText>
      </HeaderContainer>
      <CardsContainer>
        {businessHours.map(day => (
          <DayCard
            key={day.day}
            day={day}
            setBusinessHours={setBusinessHours}
          />
        ))}
        {!hoursErrors.oneDayOpen && (
          <ErrorMessage>
            Your business must be open at least one day a week
          </ErrorMessage>
        )}
      </CardsContainer>
      <ButtonNavContainer>
        <Button
          variant="primary"
          onClick={prevStep}
          data-testid="previous-step-button"
        >
          Previous
        </Button>
        <Button
          variant="primary"
          onClick={nextStep}
          disabled={!hoursErrors.oneDayOpen || !hoursErrors.noOpenAfterClose}
          data-testid="next-step-button"
        >
          Next
        </Button>
      </ButtonNavContainer>
    </HoursContainer>
  )
}

export default Hours
