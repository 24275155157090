const convertTo24 = (time, modifier) => {
  let [hours, minutes] = time.split(':')

  if (hours === '12') {
    hours = '00'
  }

  if (modifier === 'PM') {
    hours = String(parseInt(hours, 10) + 12)
  }

  return [hours, minutes]
}

export { convertTo24 }
