import { StrictMode } from 'react'

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import App from './App'
import './index.css'
import { AppProviders } from './context'

const logger = createLogger({})

const store = createStore(applyMiddleware(thunk, logger))

;(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID
  })
  /* eslint-disable */
  ReactDOM.render(
    <LDProvider>
      <StrictMode>
        <Provider store={store}>
          <AppProviders>
            <App />
          </AppProviders>
        </Provider>
      </StrictMode>
    </LDProvider>,
    document.getElementById('root')
  )
})()
