import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { ToastContainer, toast } from 'react-toastify'

import { LoaderSpinner } from '../../components/loader-spinner'
import UninstallApp from '../../components/meta/uninstall-app'
import UninstallSuccessModal from '../../components/modals/uninstall-success-modal'
import NavBar from '../../components/nav-bar/nav-bar'
import { authClient } from '../../util/auth-client'

const { REACT_APP_THRYV_URL } = process.env

function InstagramNextUninstall() {
  const [modalShow, setModalShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuth0()
  const axiosClient = authClient()

  const uninstallInstagram = async () => {
    setIsLoading(true)

    try {
      await axiosClient({
        method: 'delete',
        url: `/api/instagram/uninstall/${user.businessId}`
      })
      setModalShow(true)
      setIsLoading(false)
    } catch (error) {
      toast.error(
        'Failed to uninstall Instagram integration. Please contact Thryv Support if this issue persists.'
      )
    }
  }

  const navigateBackToThryv = () => {
    window.location.href = `${REACT_APP_THRYV_URL}/app/app-market`
  }

  return (
    <>
      <ToastContainer />
      <NavBar title={'Instagram Messenger'} />
      <UninstallApp
        uninstallApp={uninstallInstagram}
        navigateBackToThryv={navigateBackToThryv}
        appName="Instagram Messenger"
      />
      <UninstallSuccessModal app="Instagram Messenger" shouldShow={modalShow} />
      {isLoading && <LoaderSpinner />}
    </>
  )
}

export default InstagramNextUninstall
