import { useState } from 'react'

import {
  Avatar,
  Button,
  Header,
  Icon,
  PaginatedList,
  Radio,
  Table,
  TableHeader,
  TableInfo,
  TableMenu,
  TableRow
} from '@thryvlabs/maverick'

import {
  FieldContainer,
  HeaderContainer,
  MediumContainer,
  MenuContainer,
  MessageScrollBox,
  RadioGroupContainer,
  TableContainer,
  TextBox,
  Card,
  CardHeader,
  CardContent,
  CardField
} from '../../style/twilio-style'
import useWindowDimensions from '../../util/use-window-dimensions'

export default function TwilioSpamFilterTable({
  getUserQueue,
  userQueue,
  handleCallAction,
  handleFieldSelection
}) {
  const [currentDate] = useState(new Date())

  const tableDescription = `
    The spam filter will queue incoming calls and SMS 
    from contacts that do not already exist in Thryv.
  `

  const tableColumns = [
    { fieldName: 'Name', widthPercent: 19 },
    { fieldName: 'Email', widthPercent: 20 },
    { fieldName: 'Phone Number', widthPercent: 12 },
    { fieldName: 'Type', widthPercent: 8 },
    { fieldName: 'Date', widthPercent: 12 },
    { fieldName: 'Message', widthPercent: 19 },
    { fieldName: 'Action', widthPercent: 10 }
  ]

  const { width: windowWidth } = useWindowDimensions()

  return (
    <TableContainer>
      <MediumContainer>
        <Table>
          <TableHeader title="Spam Filter" description={tableDescription}>
            <Button variant="primary" onClick={getUserQueue}>
              Refresh
            </Button>
          </TableHeader>
          {windowWidth > 768 && (
            <TableMenu>
              <MenuContainer>
                {tableColumns.map(field => (
                  <HeaderContainer
                    key={field.fieldName}
                    widthPercent={field.widthPercent}
                  >
                    <Header variant="h6" fontWeight="semibold">
                      {field.fieldName}
                    </Header>
                  </HeaderContainer>
                ))}
              </MenuContainer>
            </TableMenu>
          )}
          <TableInfo
            date={currentDate}
            data={userQueue}
            label={
              userQueue?.length > 1 || userQueue?.length === 0
                ? 'Calls'
                : 'Call'
            }
          />
          {windowWidth > 768 ? (
            <PaginatedList list={userQueue} itemsPerPage={10}>
              <TwilioTableRow
                handleCallAction={handleCallAction}
                handleFieldSelection={handleFieldSelection}
              />
            </PaginatedList>
          ) : (
            <PaginatedList list={userQueue} itemsPerPage={10}>
              <TwilioMobileCard
                handleCallAction={handleCallAction}
                handleFieldSelection={handleFieldSelection}
              />
            </PaginatedList>
          )}
        </Table>
      </MediumContainer>
    </TableContainer>
  )
}

function TwilioTableRow({
  callsid,
  thryvid,
  email,
  first_name,
  last_name,
  caller,
  type,
  timestamp,
  message,
  handleFieldSelection,
  handleCallAction
}) {
  const [action, setAction] = useState()
  const emptyField = 'N/A'

  const handleRadioButton = event => {
    const eventAction = event.target.value
    const call = {
      thryv_id: thryvid,
      callsid,
      status: eventAction
    }
    setAction(previous => {
      return previous !== eventAction ? eventAction : undefined
    })
    handleCallAction(call)
  }

  return (
    <TableRow variant="static" compact data-testid="spam-filter-row">
      <FieldContainer width="19%">
        {
          <Avatar
            variant="name"
            size="small"
            name={{
              firstName: first_name,
              lastName: last_name
            }}
          />
        }
        {first_name && <TextBox>{first_name}</TextBox>}
        {last_name && <TextBox>{last_name}</TextBox>}
        <Button
          variant="text"
          level={1}
          onClick={() =>
            handleFieldSelection({ first_name, last_name, callsid })
          }
          data-testid="edit-name-btn"
        >
          <Icon type="solid" variant="edit" width="20px" />
        </Button>
      </FieldContainer>
      <FieldContainer width="20%">
        <TextBox>{email || emptyField}</TextBox>
        <Button
          variant="text"
          level={1}
          onClick={() => handleFieldSelection({ email, callsid })}
          data-testid="edit-email-btn"
        >
          <Icon type="solid" variant="edit" width="20px" />
        </Button>
      </FieldContainer>
      <FieldContainer width="12%">
        <TextBox>{caller || emptyField}</TextBox>
      </FieldContainer>
      <FieldContainer width="8%">
        <TextBox>{type || emptyField}</TextBox>
      </FieldContainer>
      <FieldContainer width="12%">
        <TextBox>
          {(!!timestamp && new Date(timestamp).toLocaleString()) || emptyField}
        </TextBox>
      </FieldContainer>
      <FieldContainer width="19%">
        {message ? (
          <MessageScrollBox>
            <TextBox>{message}</TextBox>
          </MessageScrollBox>
        ) : (
          <TextBox>{emptyField}</TextBox>
        )}
      </FieldContainer>
      <FieldContainer width="10%">
        <RadioGroupContainer>
          <Radio
            label="Release"
            value="release"
            hasLabel
            handleOnChange={handleRadioButton}
            toggled={action === 'release'}
            data-testid="release-radio-btn"
          />
          <Radio
            label="Delete"
            value="delete"
            hasLabel
            handleOnChange={handleRadioButton}
            toggled={action === 'delete'}
            data-testid="delete-radio-btn"
          />
        </RadioGroupContainer>
      </FieldContainer>
    </TableRow>
  )
}

function TwilioMobileCard({
  callsid,
  thryvid,
  email,
  first_name,
  last_name,
  caller,
  type,
  timestamp,
  message,
  handleFieldSelection,
  handleCallAction
}) {
  const [action, setAction] = useState()
  const [isCollapsed, setIsCollapsed] = useState(true)
  const emptyField = 'N/A'

  const handleRadioButton = event => {
    const eventAction = event.target.value
    const call = {
      thryv_id: thryvid,
      callsid,
      status: eventAction
    }
    setAction(previous => {
      return previous !== eventAction ? eventAction : undefined
    })
    handleCallAction(call)
  }

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <Card>
      <CardHeader onClick={handleToggleCollapse}>
        <Header fontWeight="normal" variant="h5">
          {type === 'Call' ? 'Call' : 'SMS'}{' '}
          {first_name || last_name
            ? `from ${first_name || ''} ${last_name || ''}`
            : ''}
        </Header>
        <Icon
          type="solid"
          variant={isCollapsed ? 'chevronDown' : 'chevronUp'}
          color="#000000"
        />
      </CardHeader>
      {!isCollapsed && (
        <CardContent>
          <CardField>
            <TextBox>Name:</TextBox>{' '}
            <TextBox>
              {first_name || last_name
                ? `${first_name || ''} ${last_name || ''}`
                : emptyField}
            </TextBox>
            <Button
              variant="text"
              level={1}
              onClick={() =>
                handleFieldSelection({ first_name, last_name, callsid })
              }
              data-testid="edit-name-btn"
            >
              <Icon type="solid" variant="edit" width="20px" />
            </Button>
          </CardField>
          <CardField>
            <TextBox>Email:</TextBox>
            <TextBox>{email || emptyField}</TextBox>{' '}
            <Button
              variant="text"
              level={1}
              onClick={() => handleFieldSelection({ email, callsid })}
              data-testid="edit-email-btn"
            >
              <Icon type="solid" variant="edit" width="20px" />
            </Button>
          </CardField>
          <CardField>
            <TextBox>Phone Number:</TextBox>
            <TextBox>{caller || emptyField}</TextBox>
          </CardField>
          <CardField>
            <TextBox>Type:</TextBox>
            <TextBox>{type || emptyField}</TextBox>
          </CardField>
          <CardField>
            <TextBox>Date:</TextBox>
            <TextBox>
              {new Date(timestamp).toLocaleString() || emptyField}
            </TextBox>
          </CardField>
          <CardField>
            <TextBox>Message:</TextBox>
            <TextBox>{message || emptyField}</TextBox>
          </CardField>
          <CardField>
            <TextBox>Action:</TextBox>
          </CardField>
          <CardField>
            <Radio
              label="Release"
              value="release"
              hasLabel
              handleOnChange={handleRadioButton}
              toggled={action === 'release'}
              data-testid="release-radio-btn"
            />
            <Radio
              label="Delete"
              value="delete"
              hasLabel
              handleOnChange={handleRadioButton}
              toggled={action === 'delete'}
              data-testid="delete-radio-btn"
            />
          </CardField>
        </CardContent>
      )}
    </Card>
  )
}
