import { Header, ParagraphText, Icon } from '@thryvlabs/maverick'

import Sections from './sections'
import TermsAndConditions from './terms-conditions'
import thryvLogo from '../../assets/thryvappSM.png'
import {
  AppLogo,
  ContextContainer,
  HeaderContainer,
  LogoContainer,
  MainContainer,
  TermsContainer,
  ThryvLogo
} from '../../style/landing-page-style'

const LandingPage = ({
  sections,
  onSubmit,
  appName,
  warning,
  appIconSource,
  appIconStyle,
  showThryvIcon = true,
  isMobileStyling
}) => {
  return (
    <MainContainer>
      <HeaderContainer>
        <LogoContainer isMobileStyling={isMobileStyling}>
          {showThryvIcon && (
            <>
              <ThryvLogo src={thryvLogo} data-testid="thryv-logo" />
              <Icon type="solid" variant="plus" />
            </>
          )}
          <AppLogo
            appIconStyle={appIconStyle}
            src={appIconSource}
            data-testid="app-logo"
          />
        </LogoContainer>
        <Header fontWeight="normal" variant="h3">
          &apos;{appName}&apos; is requesting permission to access your Thryv
          account.
        </Header>
      </HeaderContainer>
      <ContextContainer>
        {warning && (
          <TermsContainer>
            <ParagraphText
              variant="lg"
              color="thryv-black-700"
              className="warning-text"
            >
              <Icon
                type="solid"
                variant="circleExclamation"
                color="red"
                className="icon-style"
              />
              {warning}
            </ParagraphText>
          </TermsContainer>
        )}
        {sections && <Sections key={sections} sections={sections} />}
        <TermsAndConditions handleClick={onSubmit} />
      </ContextContainer>
    </MainContainer>
  )
}

export default LandingPage
