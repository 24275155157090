import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { toast, ToastContainer } from 'react-toastify'

import { LoaderSpinner } from '../../components/loader-spinner'
import HistoricalSettings from './Settings/HistoricalSettings'
import OngoingSettings from './Settings/OngoingSettings'
import ErrorsTable from './Settings/ErrorsTable'
import NavBar from '../../components/nav-bar/nav-bar'
import InstallSuccessModal from '../../components/modals/install-success-modal'
import UninstallSuccessModal from '../../components/modals/uninstall-success-modal'
import UpdateSuccessModal from '../../components/modals/update-success-modal'
import { authClient } from '../../util/auth-client'

const UNINSTALL_MESSAGE = `Uninstall the MYOB AccountRight App.`
const BUTTON_TEXT = `Errors`

const MyObAccountingSettings = () => {
  const [shouldShowInstallSuccesModal, setShouldShowInstallSuccessModal] =
    useState(false)
  const [shouldShowUpdateSuccesModal, setShouldShowUpdateSuccessModal] =
    useState(false)
  const [toggleSettingView, setToggleSettingView] = useState(true)
  const [currentDateFromThryv, setCurrentDateFromThryv] = useState()
  const [currentDateFromCodat, setCurrentDateFromCodat] = useState()
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const [firstVisitFlow, setFirstVisitFlow] = useState(true)
  const [reauthButton, setReauthButton] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [unsubscribed, setUnsubscribed] = useState(false)
  const [myobRedirectUrl, setMyobRedirectUrl] = useState('')
  const [navBarSettings, setNavBarSetting] = useState({
    showSettingsButton: false,
    showErrorsButton: false,
    showErrorsTable: false,
    showMYOBSettings: true
  })
  const [errorsList, setErrorsList] = useState([])
  const [historicalSettingsOptions, setHistoricalSettingsOptions] = useState({
    historicalClientsFromThryv: false,
    historicalInvoicesFromThryv: false,
    historicalPaymentsFromThryv: false,
    historicalClientsFromCodat: false,
    historicalInvoicesFromCodat: false,
    historicalPaymentsFromCodat: false
  })
  const [
    historicalSettingsDisableOptions,
    setHistoricalSettingsDisableOptions
  ] = useState({
    historicalClientsFromThryvDisabled: false,
    historicalInvoicesFromThryvDisabled: false,
    historicalPaymentsFromThryvDisabled: true,
    historicalClientsFromCodatDisabled: false,
    historicalInvoicesFromCodatDisabled: false,
    historicalPaymentsFromCodatDisabled: true
  })
  const [ongoingSettingsOptions, setOngoingSettingsOptions] = useState({
    ongoingClientsFromThryv: false,
    ongoingInvoicesFromThryv: false,
    ongoingPaymentsFromThryv: false,
    ongoingClientsFromCodat: false,
    ongoingInvoicesFromCodat: false,
    ongoingPaymentsFromCodat: false
  })

  const {
    user: { businessId: thryvId }
  } = useAuth0()
  const axiosClient = authClient()

  const myobServiceURL = `/api/myob`

  const unSubscribeHandler = async () => {
    try {
      setIsLoading(true)
      await axiosClient({
        method: 'delete',
        url: `${myobServiceURL}/uninstall/${thryvId}`
      })
      setUnsubscribed(true)
      setIsLoading(false)
    } catch (err) {
      toast.error(
        'Something went wrong while unsubscribing from MYOB Accounting. If the problem persists please contact Thryv support.'
      )
      setIsLoading(false)
    }
  }
  const checkErrors = async () => {
    try {
      const { data } = await axiosClient({
        url: `${myobServiceURL}/invoice-import-errors/${thryvId}`
      })

      if (
        Array.isArray(data.invoiceImportErrors) &&
        data.invoiceImportErrors.length
      ) {
        setErrorsList(data.invoiceImportErrors)
        if (!navBarSettings.showSettingsButton) {
          setNavBarSetting(prevOptions => ({
            ...prevOptions,
            showErrorsButton: true
          }))
        }
      }
    } catch (err) {
      toast.error(
        'Something went wrong while retrieving your invoice errors. If the problem persists please contact Thryv support.'
      )
    }
  }

  const goToSettingsPage = () => {
    setNavBarSetting({
      showMYOBSettings: true,
      showErrorsTable: false,
      showErrorsButton: true,
      showSettingsButton: false
    })
  }

  const goToErrorsPage = () => {
    setNavBarSetting({
      showMYOBSettings: false,
      showErrorsTable: true,
      showErrorsButton: false,
      showSettingsButton: true
    })
  }

  useEffect(() => {
    //checks user setting status- if any of the values are null this is the user's first visit so they must configure settings
    //otherwise, sets settings to selected options, should bypass the historical settings and show a reauth and update option
    const checkSettingsStatus = async () => {
      try {
        setIsLoading(true)
        const { data } = await axiosClient({
          url: `${myobServiceURL}/user/${thryvId}`
        })

        const areAllSettingsNull = Object.values(data).every(
          setting => setting === null
        )

        if (!areAllSettingsNull) {
          setOngoingSettingsOptions({
            ongoingClientsFromThryv:
              data.ongoing_client_thryv === 1 ? true : false,
            ongoingInvoicesFromThryv:
              data.ongoing_invoice_thryv === 1 ? true : false,
            ongoingPaymentsFromThryv:
              data.ongoing_payment_thryv === 1 ? true : false,
            ongoingClientsFromCodat:
              data.ongoing_client_codat === 1 ? true : false,
            ongoingInvoicesFromCodat:
              data.ongoing_invoice_codat === 1 ? true : false,
            ongoingPaymentsFromCodat:
              data.ongoing_payment_codat === 1 ? true : false
          })
          setShouldUpdate(true)
          setFirstVisitFlow(false)
          setToggleSettingView(false)
          setNavBarSetting(prevOptions => ({
            ...prevOptions,
            showErrorsButton: true
          }))
        }
        if (data.status !== 'Linked') {
          setReauthButton(true)
        }
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        toast.error(
          'Something went wrong while retrieving your MYOB settings. If the problem persists please contact Thryv support.'
        )
      }
    }
    checkSettingsStatus()
  }, [])

  useEffect(() => {
    checkErrors()
  }, [])

  useEffect(() => {
    if (historicalSettingsOptions.historicalInvoicesFromThryv) {
      //enable historical payments toggle button
      setHistoricalSettingsDisableOptions(prevOptions => ({
        ...prevOptions,
        historicalPaymentsFromThryvDisabled: false
      }))
    } else {
      //move toggle to off
      setHistoricalSettingsOptions(prevOptions => ({
        ...prevOptions,
        historicalPaymentsFromThryv: false
      }))
      //disable historical payments button
      setHistoricalSettingsDisableOptions(prevOptions => ({
        ...prevOptions,
        historicalPaymentsFromThryvDisabled: true
      }))
    }
  }, [historicalSettingsOptions.historicalInvoicesFromThryv])

  useEffect(() => {
    if (historicalSettingsOptions.historicalInvoicesFromCodat) {
      //enable historical payments toggle button
      setHistoricalSettingsDisableOptions(prevOptions => ({
        ...prevOptions,
        historicalPaymentsFromCodatDisabled: false
      }))
    } else {
      //move toggle to off
      setHistoricalSettingsOptions(prevOptions => ({
        ...prevOptions,
        historicalPaymentsFromCodat: false
      }))
      //disable historical payments button
      setHistoricalSettingsDisableOptions(prevOptions => ({
        ...prevOptions,
        historicalPaymentsFromCodatDisabled: true
      }))
    }
  }, [historicalSettingsOptions.historicalInvoicesFromCodat])

  const handleHistoricalSettingsChange = settingName => {
    setHistoricalSettingsOptions(prevOptions => ({
      ...prevOptions,
      [settingName]: !prevOptions[settingName]
    }))
  }

  const handleOngoingSettingsChange = settingName => {
    setOngoingSettingsOptions(prevOptions => ({
      ...prevOptions,
      [settingName]: !prevOptions[settingName]
    }))
  }

  const reauthSettings = async () => {
    const reauthObject = {
      thryvId
    }
    try {
      setIsLoading(true)
      const { data } = await axiosClient({
        method: 'post',
        url: `${myobServiceURL}/reauthenticate`,
        data: reauthObject
      })
      setIsLoading(false)
      setMyobRedirectUrl(data.href)
      setShouldShowInstallSuccessModal(true)
    } catch (err) {
      setIsLoading(false)
      toast.error(
        'Something went wrong while reauthenticating your MYOB AccountRight settings. If the problem persists please contact Thryv support.'
      )
    }
  }

  const submitHistoricalSettings = async () => {
    if (
      (!currentDateFromThryv &&
        historicalSettingsOptions.historicalInvoicesFromThryv) ||
      (!currentDateFromCodat &&
        historicalSettingsOptions.historicalInvoicesFromCodat)
    ) {
      toast.error(
        'A date is required in order to enable historical invoices. Please select a date.'
      )
    } else {
      setToggleSettingView(false)
    }
  }
  const backSettings = async () => {
    setToggleSettingView(true)
  }
  const onCloseUpdate = async () => {
    setShouldShowUpdateSuccessModal(false)
    setShouldUpdate(true)
    setFirstVisitFlow(false)
  }
  const onCloseInstall = async () => {
    if (myobRedirectUrl) {
      window.location.href = myobRedirectUrl
    } else {
      setShouldShowInstallSuccessModal(false)
      setShouldUpdate(true)
      setFirstVisitFlow(false)
    }
  }

  const submitSettings = async () => {
    const settingsObject = {
      thryvId,
      ...(firstVisitFlow && {
        historicalClientsFromThryv:
          historicalSettingsOptions.historicalClientsFromThryv ? 1 : 0,
        historicalInvoicesFromThryv:
          historicalSettingsOptions.historicalInvoicesFromThryv ? 1 : 0,
        syncFromDateThryv: currentDateFromThryv?.format() || '',
        historicalPaymentsFromThryv:
          historicalSettingsOptions.historicalPaymentsFromThryv ? 1 : 0,
        historicalClientsFromCodat:
          historicalSettingsOptions.historicalClientsFromCodat ? 1 : 0,
        historicalInvoicesFromCodat:
          historicalSettingsOptions.historicalInvoicesFromCodat ? 1 : 0,
        syncFromDateThryvCodat: currentDateFromCodat?.format() || '',
        historicalPaymentsFromCodat:
          historicalSettingsOptions.historicalPaymentsFromCodat ? 1 : 0
      }),
      ongoingClientsFromThryv: ongoingSettingsOptions.ongoingClientsFromThryv
        ? 1
        : 0,
      ongoingInvoicesFromThryv: ongoingSettingsOptions.ongoingInvoicesFromThryv
        ? 1
        : 0,
      ongoingPaymentsFromThryv: ongoingSettingsOptions.ongoingPaymentsFromThryv
        ? 1
        : 0,
      ongoingClientsFromCodat: ongoingSettingsOptions.ongoingClientsFromCodat
        ? 1
        : 0,
      ongoingInvoicesFromCodat: ongoingSettingsOptions.ongoingInvoicesFromCodat
        ? 1
        : 0,
      ongoingPaymentsFromCodat: ongoingSettingsOptions.ongoingPaymentsFromCodat
        ? 1
        : 0,
      method: shouldUpdate ? 'update' : 'install'
    }

    try {
      setIsLoading(true)
      const { data } = await axiosClient({
        method: shouldUpdate ? 'patch' : 'post',
        url: shouldUpdate
          ? `${myobServiceURL}/update`
          : `${myobServiceURL}/install`,
        data: settingsObject
      })
      setIsLoading(false)
      if (shouldUpdate) {
        setShouldShowUpdateSuccessModal(true)
      } else {
        setMyobRedirectUrl(data.href)
        setShouldShowInstallSuccessModal(true)
      }
    } catch (err) {
      setIsLoading(false)
      toast.error(
        `Something went wrong while ${
          shouldUpdate ? 'updating' : 'installing'
        } your MYOB settings. If the problem persists please contact Thryv support.`
      )
    }
  }

  return (
    <div>
      <ToastContainer />

      {isLoading ? (
        <LoaderSpinner />
      ) : (
        <>
          <InstallSuccessModal
            app={'MYOB Accounting'}
            shouldShow={shouldShowInstallSuccesModal}
            integrationComplete={shouldUpdate}
            closeModal={onCloseInstall}
          />
          {shouldShowUpdateSuccesModal && (
            <UpdateSuccessModal closeModal={onCloseUpdate} />
          )}
          <NavBar
            title={'MYOB Accounting'}
            showButton={shouldUpdate}
            handleUninstall={unSubscribeHandler}
            customMessage={UNINSTALL_MESSAGE}
            showSettingsButton={navBarSettings.showSettingsButton}
            handleSettingsClick={goToSettingsPage}
            showSpamFilterButton={navBarSettings.showErrorsButton}
            handleSpamFilterClick={goToErrorsPage}
            buttonText={BUTTON_TEXT}
            mobileFontSize={'5vw'}
          />
          {navBarSettings.showMYOBSettings && toggleSettingView && (
            <HistoricalSettings
              settingsOptions={historicalSettingsOptions}
              disableSettingsOptions={historicalSettingsDisableOptions}
              handleSettingsChange={handleHistoricalSettingsChange}
              submitSettings={submitHistoricalSettings}
              currentDateFromThryv={currentDateFromThryv}
              setCurrentDateFromThryv={setCurrentDateFromThryv}
              currentDateFromCodat={currentDateFromCodat}
              setCurrentDateFromCodat={setCurrentDateFromCodat}
            />
          )}
          {navBarSettings.showMYOBSettings && !toggleSettingView && (
            <OngoingSettings
              settingsOptions={ongoingSettingsOptions}
              handleSettingsChange={handleOngoingSettingsChange}
              backSettings={backSettings}
              firstVisitFlow={firstVisitFlow}
              reauthButton={reauthButton}
              reauthSettings={reauthSettings}
              submitSettings={submitSettings}
            />
          )}
          {navBarSettings.showErrorsTable && (
            <ErrorsTable errorsList={errorsList} checkErrors={checkErrors} />
          )}
          <UninstallSuccessModal
            app="MYOB Accounting"
            shouldShow={unsubscribed}
          />
        </>
      )}
    </div>
  )
}

export default MyObAccountingSettings
