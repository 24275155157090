import { useNavigate } from 'react-router-dom'

import jotformLogo from '../../assets/JotformLogo.png'
import LandingPage from '../../components/landing-page/landing-page'
import { appIconStyle } from '../../style/app-icon-style'

const Jotform = () => {
  const appName = 'Jotform'
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/jotform-settings')
  }
  const jotFormSelections = [
    {
      header: 'What will this application need access to?',
      statements: [
        'Your user details',
        'Your form details',
        'Your form submissions'
      ]
    },
    {
      header: 'What will this application do?',
      statements: [
        'Add new Contacts in Thryv.',
        'Update existing Clients in Thryv.',
        'Inject the Jotform into the Inbox of Thryv as well as the conversation history on the client card.'
      ]
    }
  ]

  return (
    <LandingPage
      sections={jotFormSelections}
      onSubmit={handleClick}
      appName={appName}
      appIconSource={jotformLogo}
      appIconStyle={appIconStyle}
      isMobileStyling={true}
    />
  )
}

export default Jotform
