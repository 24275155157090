import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'

const { REACT_APP_BACKEND_URL } = process.env

const authClient = () => {
  const { getAccessTokenSilently } = useAuth0()

  const axiosClient = axios.create({ baseURL: REACT_APP_BACKEND_URL })

  axiosClient.interceptors.request.use(
    async config => {
      const authToken = await getAccessTokenSilently()
      if (authToken) {
        config.headers = {
          Authorization: `Bearer ${authToken}`
        }
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  return axiosClient
}

export { authClient }
