import { Button, Icon, Modal, ParagraphText } from '@thryvlabs/maverick'

import {
  IconContainer,
  ModalSectionContainer,
  TextContainer
} from '../../style/update-success-modal'

export default function UpdateSuccessModal({ closeModal }) {
  return (
    <Modal
      variant="default"
      altBtn={<></>}
      onClose={closeModal}
      openOnLoad
      hideX
      action
      tabIndex={0}
    >
      <ModalSectionContainer>
        <IconContainer>
          <Icon variant="circleExclamation" type="solid" color="green" />
        </IconContainer>
        <TextContainer>
          <ParagraphText variant="lg">Successfully Updated</ParagraphText>
        </TextContainer>
      </ModalSectionContainer>
      <ModalSectionContainer className="bottom-padding">
        <Button variant="primary" onClick={closeModal}>
          Close
        </Button>
      </ModalSectionContainer>
    </Modal>
  )
}
