import { useNavigate } from 'react-router-dom'

import quickbooksLogo from '../../assets/quickbooksLogo.png'
import LandingPage from '../../components/landing-page/landing-page'
import { appIconStyle } from '../../style/app-icon-style'

const QuickbooksOnline = () => {
  const appName = 'QuickBooks'
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/quickbooks-online-settings')
  }
  const quickbookOnlineSelections = [
    {
      header: 'What will this application need access to?',
      statements: ['Customers', 'Invoices', 'Payments', 'Estimates']
    },
    {
      header: 'What will this application do?',
      statements: [
        'Sync current Customers between Thryv and QuickBooks Online.',
        'Sync future Customers between Thryv and QuickBooks Online.',
        'Sync current Estimates between Thryv and QuickBooks Online',
        'Sync future Estimates between Thryv and QuickBooks Online.',
        'Sync current Invoices between Thryv and QuickBooks Online.',
        'Sync future Invoices between Thryv and QuickBooks Online.',
        'Sync current Payments between Thryv and QuickBooks Online.',
        'Sync future Payments between Thryv and QuickBooks Online.'
      ]
    }
  ]

  return (
    <LandingPage
      sections={quickbookOnlineSelections}
      onSubmit={handleClick}
      appName={appName}
      appIconSource={quickbooksLogo}
      appIconStyle={appIconStyle}
    />
  )
}

export default QuickbooksOnline
