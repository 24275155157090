import { compareTimes } from '../../util/compareTimes'

export function handleOpenClose(setBusinessHours, day) {
  setBusinessHours(prevHours =>
    prevHours.map(prevDayData => {
      if (prevDayData.day === day) {
        return {
          ...prevDayData,
          open: !prevDayData.open,
          openBeforeClose: true,
          openTime: prevDayData.open
            ? { name: '', value: '' }
            : { name: '9:00', value: '9:00' },
          openMeridiem: prevDayData.open
            ? { name: '', value: '' }
            : { name: 'AM', value: 'AM' },
          closeTime: prevDayData.open
            ? { name: '', value: '' }
            : { name: '5:00', value: '5:00' },
          closeMeridiem: prevDayData.open
            ? { name: '', value: '' }
            : { name: 'PM', value: 'PM' }
        }
      }
      return prevDayData
    })
  )
}

export function handleOpenTime(newTime, setBusinessHours, day) {
  setBusinessHours(prevHours =>
    prevHours.map(prevDayData => {
      if (prevDayData.day === day) {
        return {
          ...prevDayData,
          openBeforeClose: compareTimes(
            newTime.value,
            prevDayData.openMeridiem.value,
            prevDayData.closeTime.value,
            prevDayData.closeMeridiem.value
          ),
          openTime: newTime
        }
      }
      return prevDayData
    })
  )
}

export function handleOpenMeridiem(newMeridiem, setBusinessHours, day) {
  setBusinessHours(prevHours =>
    prevHours.map(prevDayData => {
      if (prevDayData.day === day) {
        return {
          ...prevDayData,
          openBeforeClose: compareTimes(
            prevDayData.openTime.value,
            newMeridiem.value,
            prevDayData.closeTime.value,
            prevDayData.closeMeridiem.value
          ),
          openMeridiem: newMeridiem
        }
      }
      return prevDayData
    })
  )
}

export function handleCloseTime(newTime, setBusinessHours, day) {
  setBusinessHours(prevHours =>
    prevHours.map(prevDayData => {
      if (prevDayData.day === day) {
        return {
          ...prevDayData,
          openBeforeClose: compareTimes(
            prevDayData.openTime.value,
            prevDayData.openMeridiem.value,
            newTime.value,
            prevDayData.closeMeridiem.value
          ),
          closeTime: newTime
        }
      }
      return prevDayData
    })
  )
}

export function handleCloseMeridiem(newMeridiem, setBusinessHours, day) {
  setBusinessHours(prevHours =>
    prevHours.map(prevDayData => {
      if (prevDayData.day === day) {
        return {
          ...prevDayData,
          openBeforeClose: compareTimes(
            prevDayData.openTime.value,
            prevDayData.openMeridiem.value,
            prevDayData.closeTime.value,
            newMeridiem.value
          ),
          closeMeridiem: newMeridiem
        }
      }
      return prevDayData
    })
  )
}
