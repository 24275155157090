import { useNavigate } from 'react-router-dom'

import constantContactLogo from '../../assets/constant_contact_logo.png'
import LandingPage from '../../components/landing-page/landing-page'
import { appIconStyle } from '../../style/app-icon-style'

const ConstantContact = () => {
  const appName = 'Constant Contact'
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/constant-contact-settings')
  }
  const constantContactSections = [
    {
      header: 'What will this application need access to?',
      statements: ['Your Constant Contact']
    },

    {
      header: 'What will this application do?',
      statements: [
        'Sync current contacts from Thryv to Constant Contact.',
        'Sync current contacts from Constant Contact to Thryv.',
        'Ongoing two-way sync of contacts between Thryv and Constant Contact.'
      ]
    }
  ]

  return (
    <LandingPage
      sections={constantContactSections}
      onSubmit={handleClick}
      appName={appName}
      appIconSource={constantContactLogo}
      appIconStyle={appIconStyle}
      isMobileStyling={true}
    />
  )
}

export default ConstantContact
