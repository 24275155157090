import styled from '@emotion/styled'

export const NavContainer = styled.div`
  display: flex;
  height: 150px;
  background: #f8f9fb;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    height: 100px;
  }

  & svg {
    width: 200px !important;
    height: 200px;
    margin-top: -30px;

    @media (max-width: 768px) {
      width: 22vw !important;
      margin-top: -50px;
    }
  }
  & h2 {
    margin-top: 55px;
    font-size: 30px;
    margin-left: -10px;

    @media (max-width: 768px) {
      font-size: ${props => props.fontSize || '25px'};
      margin-top: 33px;
    }
  }
`
export const NavButtonsContainer = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: center;
  align-items: center;

  & button {
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1vh;

    & button {
      margin-right: 10px;
    }
  }
`
export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`
