import styled from '@emotion/styled'

// integry-settings.js
export const WrapperContainer = styled.div`
  width: 100%;
`
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;

  .settings-footer {
    text-align: center !important;
  }
`
export const Col = styled.div`
  position: relative;
  width: 100%;
  padding-right: 10%;
  padding-left: 10%;
`
