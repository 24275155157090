import { ParagraphText } from '@thryvlabs/maverick'

import { MediumContainer } from '../style/containers-style'
import { Column, Row } from '../style/empty-page-style'

const EmptyPage = () => {
  return (
    <MediumContainer pb="10rem">
      <Row>
        <Column>
          <ParagraphText variant="lg">
            It looks like you&apos;ve navigated to the wrong place. Please
            double check the URL entered, and contact Thryv Support if the issue
            persists.
          </ParagraphText>
        </Column>
      </Row>
    </MediumContainer>
  )
}
export default EmptyPage
