import { TableRow, Radio, Icon } from '@thryvlabs/maverick'

import {
  EightColumnTable,
  TableCell,
  RadioButtonWrapper,
  EditFieldsWrapper
} from '../../../style/ringcentral-style'

function SpamFilterRow({
  spam,
  handleActionChange,
  handleRowFieldEdit,
  accessToken
}) {
  return (
    <TableRow key={spam.row_id} variant="static">
      <EightColumnTable>
        <TableCell>
          <EditFieldsWrapper>
            {spam.firstName || 'N/A'}{' '}
            <Icon
              type="regular"
              variant="edit"
              height="21"
              width="21"
              color="#FF5000"
              onClick={() => {
                handleRowFieldEdit(spam.uuid, 'firstName')
              }}
            />
          </EditFieldsWrapper>
        </TableCell>
        <TableCell>
          <EditFieldsWrapper>
            {spam.lastName || 'N/A'}{' '}
            <Icon
              type="regular"
              variant="edit"
              height="21"
              width="21"
              color="#FF5000"
              onClick={() => {
                handleRowFieldEdit(spam.uuid, 'lastName')
              }}
            />
          </EditFieldsWrapper>
        </TableCell>
        <TableCell>
          <EditFieldsWrapper>
            {spam.email || 'N/A'}{' '}
            <Icon
              type="regular"
              variant="edit"
              height="21"
              width="21"
              color="#FF5000"
              onClick={() => {
                handleRowFieldEdit(spam.uuid, 'email')
              }}
            />
          </EditFieldsWrapper>
        </TableCell>
        <TableCell>{spam.phoneNumber || 'N/A'}</TableCell>
        <TableCell>{spam.type || 'N/A'}</TableCell>
        <TableCell>
          {new Date(spam.timestamp).toLocaleString() || 'N/A'}
        </TableCell>
        <TableCell>
          {spam.vmLink && accessToken ? (
            <div>
              <a href={`${spam.vmLink}?access_token=${accessToken}`}>VM Link</a>
            </div>
          ) : (
            'N/A'
          )}
        </TableCell>
        <TableCell>
          <RadioButtonWrapper>
            <Radio
              hasLabel
              id={spam.uuid}
              value="delete"
              label="Delete"
              toggled={spam.status === 'delete'}
              handleOnChange={handleActionChange}
            />
            <Radio
              hasLabel
              id={spam.uuid}
              value="release"
              label="Release"
              toggled={spam.status === 'release'}
              handleOnChange={handleActionChange}
            />
          </RadioButtonWrapper>
        </TableCell>
      </EightColumnTable>
    </TableRow>
  )
}

export default SpamFilterRow
