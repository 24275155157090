import styled from '@emotion/styled'

// GBMInstall.js
export const GridContainer = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`
export const GridItem = styled.div`
  padding: 20px;
  text-align: justify;
  width: 100%;

  h5 {
    margin-bottom: -0.1rem;
  }

  & input {
    width: 500px;
    max-width: 100%;

    @media (max-width: 768px) {
      width: 90%;
      max-width: 90%;
    }
  }

  & input:placeholder-shown {
    border-color: #e22020;
  }
`
export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;

  button {
    padding: 12px 24px;
    width: 120px;
    margin-top: 24px;
  }
`
export const PermissionsContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`
export const GBMInstallButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const ErrorMessage = styled.p`
  color: #e22020;
  text-align: left;
  margin-top: 4px;
`
export const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 12px;
  border-radius: 50%;
  overflow: hidden;
`
export const GBMProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-bottom: -0.1rem;
  }

  & span {
    font-weight: 700;
  }
`
export const LoaderScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`

// GBMNoSelection.js
export const ContactPageGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`
export const ContactPageGridItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  border-right: 1px solid #ddd;

  &:nth-of-type(2n) {
    border-right: none;
  }

  @media (max-width: 600px) {
    border-right: none;
  }

  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .phone-number {
    color: #ff5000;
    font-size: 16px;
    margin-top: 1.15rem;
  }
`
export const IconStyle = styled.span`
  margin-bottom: 12px;
`
export const ContactSupportPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85vh;
  text-align: center;
  width: 100%;
  flex-direction: column;
`
export const ContactHeaderContainer = styled.div`
  padding-bottom: 1rem;
`

// GBMSelection.js
export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`
export const GBMSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50%;
  height: 200px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  @media (max-width: 600px) {
    width: 95%;
  }
`
export const GBMSelectionButtonContainer = styled.div`
  display: flex;
  padding-top: 40px;
  width: 50%;
  gap: 50px;
  justify-content: center;

  & button {
    height: 40px;
    font-size: 16px;
  }

  .small-button {
    width: 10%;
    min-width: 80px;
  }

  @media (max-width: 767px) {
    padding-top: 2vh;
    width: 95%;
    gap: 2vw;
    justify-content: center;
  }
`

// GBMError.js
export const GBMErrorThryvGuy = styled.img`
  width: 250px;
  height: 280px;
`
export const GBMInstructionImage = styled.img`
  width: 700px;
  height: 630px;

  @media (max-width: 767px) {
    display: none;
  }
`
export const GBMErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`
export const GBMErrorRowContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`
export const GBMErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 10%;
  padding-right: 50px;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;

  & button {
    color: #ff5000;
    font-size: 16px;
  }

  @media (max-width: 767px) {
    width: 95%;
    padding-top: 2vh;
    padding-right: 0px;
    margin-left: auto;
    margin-right: auto;
  }
`
export const GBMErrorButtonContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`

// GBMHoursSelection.js
export const HoursSelectionContainer = styled.div`
  padding: 20px;
`
export const HoursSelectionTable = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 2fr 4fr 1fr 4fr;
  gap: 8px;
`
export const HourAndMerideimSelection = styled.div`
  display: flex;
  gap: 10px;
  margin-top -.5rem;

  div:nth-of-type(2) {
    width: calc(40%);
  }
`

// Mobile Components
// CountryTz
export const CountryTzContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vh;
`

export const HeaderContainer = styled.div`
  text-align: center;
  padding: 2vh;
`

export const SelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3vw;
  gap: 1vh;
  width: 95%;
`
// Hours
export const HoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vh;
`

export const ButtonNavContainer = styled.div`
  display: flex;
  gap: 2vw;
  margin-bottom: 3vh;
`

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 93%;
`

export const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 2vw;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
`

export const CardHeader = styled.div`
  background-color: ${props => props.backgroundColor};
  padding: 2vh;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h2 {
    margin: 0;
    color: #333;
  }
`

export const CardContent = styled.div`
  padding: 2vh;
  display: flex;
  flex-direction: column;
`

export const MobileHoursContainer = styled.div`
  margin-top: 2vh;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  gap: 1vh;

  & p {
    font-weight: bold;
  }
`

export const MessagesContainer = styled.div`
  margin-top: 2vh;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
`

export const MessageInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2vw;
  gap: 1vh;
  width: 95%;
`
