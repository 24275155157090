import { useState } from 'react'

import { Button, Header, Icon } from '@thryvlabs/maverick'
import { LoginSocialFacebook } from 'reactjs-social-login'

import {
  Card,
  CardContent,
  CardField,
  CardHeader,
  TextBox
} from '../../style/instagram-style'

const { REACT_APP_FACEBOOK_ID } = process.env

export default function MetaMobileCard({
  id,
  name,
  category,
  scope,
  onResolve,
  onReject
}) {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <Card>
      <CardHeader onClick={toggleCollapse}>
        <Header fontWeight="normal" variant="h5">
          {name}
        </Header>
        <Icon
          type="solid"
          variant={isCollapsed ? 'chevronDown' : 'chevronUp'}
          color="#000000"
        />
      </CardHeader>
      {!isCollapsed && (
        <CardContent>
          <CardField>
            <TextBox>Page Name:</TextBox>
            <TextBox>{name}</TextBox>
          </CardField>
          <CardField>
            <TextBox>Category:</TextBox>
            <TextBox>{category}</TextBox>
          </CardField>
          <CardField>
            <TextBox>Page ID:</TextBox>
            <TextBox>{id}</TextBox>
          </CardField>
          <CardField>
            <LoginSocialFacebook
              appId={REACT_APP_FACEBOOK_ID}
              fields="name,email,picture"
              scope={scope}
              onResolve={onResolve}
              onReject={onReject}
            >
              <Button variant="text" level={1}>
                Edit
              </Button>
            </LoginSocialFacebook>
          </CardField>
        </CardContent>
      )}
    </Card>
  )
}
