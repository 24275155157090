import {
  ThryvLogo,
  Header,
  Modal,
  ParagraphText,
  ModalTitle,
  Button
} from '@thryvlabs/maverick'

import {
  ModalContainer,
  NavContainer,
  NavButtonsContainer
} from '../../style/nav-bar-style'

export default function NavBar({
  title,
  showButton,
  handleUninstall,
  handleSettingsClick,
  handleSpamFilterClick,
  showSettingsButton,
  showSpamFilterButton,
  customMessage,
  buttonText,
  mobileFontSize
}) {
  return (
    <NavContainer fontSize={mobileFontSize} data-testid="thryv-navbar">
      <ThryvLogo data-testid="thryv-logo" />
      <Header fontWeight="medium" variant="h1">
        | {title}
      </Header>
      <NavButtonsContainer>
        {showSettingsButton && (
          <Button variant="primary" onClick={handleSettingsClick}>
            Settings
          </Button>
        )}
        {showSpamFilterButton && (
          <Button variant="primary" onClick={handleSpamFilterClick}>
            {buttonText}
          </Button>
        )}
        {showButton && (
          <Modal
            data-testid="uninstall-modal"
            variant="default"
            btnText="Uninstall"
            btnType="primary"
            btnActionText="Uninstall"
            btnAction={handleUninstall}
            footer
            action
            footerCancel
          >
            <ModalContainer>
              <ModalTitle variant="subtitle" size="lg">
                Are you sure you want to uninstall this app?
              </ModalTitle>
              {!!customMessage && (
                <ParagraphText variant="lg">{customMessage}</ParagraphText>
              )}
            </ModalContainer>
          </Modal>
        )}
      </NavButtonsContainer>
    </NavContainer>
  )
}
