import {
  Button,
  Icon,
  Modal,
  ModalTitle,
  ParagraphText
} from '@thryvlabs/maverick/'

import { ModalContainer } from '../../style/install-success-modal-style'
import {
  IconContainer,
  ModalSectionContainer
} from '../../style/update-success-modal'

const { REACT_APP_THRYV_URL } = process.env

export default function InstallErrorModal({ shouldShow }) {
  return (
    <>
      {shouldShow && (
        <Modal variant="default" openOnLoad action altBtn={<></>} hideX>
          <ModalContainer>
            <ModalSectionContainer>
              <IconContainer>
                <Icon variant="circleExclamation" type="solid" color="red" />
              </IconContainer>
              <ModalTitle variant="subtitle" size="lg">
                Installation Failed
              </ModalTitle>
            </ModalSectionContainer>
            <ParagraphText variant="reg">
              Please verify your Google business listing{' '}
              <a
                target="_blank"
                rel="nofollow noreferrer"
                href={
                  'https://learn.thryv.com/hc/en-us/articles/15010506276621-Verify-Your-Business-on-Google'
                }
              >
                here
              </a>{' '}
              before installing Google Business Messenger.
            </ParagraphText>
            <ModalSectionContainer className="bottom-padding">
              <Button
                variant="primary"
                onClick={() => {
                  window.location.href = `${REACT_APP_THRYV_URL}/app`
                }}
              >
                Return to Thryv
              </Button>
            </ModalSectionContainer>
          </ModalContainer>
        </Modal>
      )}
    </>
  )
}
